import React, { useEffect } from "react";
import "./imgsection.css";
import icons from "../enrichImages/banner-img.png";
import { FaAngleDoubleRight } from "react-icons/fa";
import { BsBagCheck } from "react-icons/bs";
import icons1 from "../enrichImages/banner-image1.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALLORDERS_REQUEST } from "../../store/orders/ActionsTypes";
import {validateMessage} from '../../validationhelper/ValidationHelper'
import { formatDate } from "../../constants/ValidationConst";

export default function ImgSection() {
  const user=JSON.parse(localStorage.getItem("authUser"));
  const orderList = useSelector((state) => state.orders?.orderList);
  const dispatch = useDispatch();
  const navigate=useNavigate();

  useEffect(() => {
    if(validateMessage(user)){
    dispatch({ type: GET_ALLORDERS_REQUEST });
    }
  }, []);

  return (
    <div className="main">
      <div className="container">
        <div className="banner-content">
          <div className="row">
            <div className="left-content col-md">
              {!user && <h4 className="mb-3">Enrich your</h4>}
              {!user && <h2 className="highLight">Candidate Data</h2>}
              {user && (
                <h4>
                  <b>Welcome Back !</b>
                </h4>
              )}
              {user && (
                <h2 className="highLight">
                  {user.name.substring(0, 1).toUpperCase() + user.name.substring(1)}
                </h2>
              )}
              {!user && <h2>With Our Taxonomy</h2>}
              {!user && <p>Enhance your datasets with valuable information</p>}
              {user && validateMessage(orderList) && orderList.length>0 && (
                <div
                  className="order-card card mb-3"
                  onClick={() => (navigate("/Orders"))}
                >
                  <div className=" text-center">
                    <BsBagCheck className="check-bag" />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title m-0">
                      <b>Your Order Delivered On</b>
                    </h5>

                    <h5 className="card-title m-0 ">
                      <b>{formatDate(orderList[0].orderDate)}</b>
                    </h5>

                    <p className="order-card-text mt-2">
                      Order Id: {orderList[0].orderId}
                    </p>
                  </div>
                </div>
              )}

              {!user && (
                <Link className="btn btn-danger bt-lg" id="as-shop-now" to="/shop">
                  Shop Now <FaAngleDoubleRight />
                </Link>
              )}
            </div>

            <div className="right-content col-md">
              {!user && <img src={icons} className="img-fluid" alt="img" />}
              {user && (
                <img src={icons1} className="img-fluid img2" alt="img" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
