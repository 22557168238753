import {
  DELETE_DELIVERYDATA_REQUEST,
  DELETE_DELIVERYDATA_REQUEST_FAIL,
  DELETE_DELIVERYDATA_REQUEST_SUCCESS,
  DELETE_SNOWFLAKEDELIVERYDATA_REQUEST,
  DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
  DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
  GET_DELIVERYDETAILS_REQUEST,
  GET_DELIVERYDETAILS_REQUEST_FAIL,
  GET_DELIVERYDETAILS_REQUEST_SUCCESS,
  GET_HEADING_DETAILS_SUCCESS,
  GET_SERVERLIST_REQUEST,
  GET_SERVERLIST_REQUEST_FAIL,
  GET_SERVERLIST_REQUEST_SUCCESS,
  GET_SNOWFLAKEDELIVERYDETAILS_REQUEST,
  GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_FAIL,
  GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_SUCCESS,
  SAVE_DELIVERYDATA_REQUEST,
  SAVE_DELIVERYDATA_REQUEST_FAIL,
  SAVE_DELIVERYDATA_REQUEST_SUCCESS,
  SAVE_SNOWFLAKEDELIVERYDATA_REQUEST,
  SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
  SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
  UPDATE_DELIVERYDATA_REQUEST,
  UPDATE_DELIVERYDATA_REQUEST_FAIL,
  UPDATE_DELIVERYDATA_REQUEST_SUCCESS,
  UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST,
  UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
  UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
} from "./ActionTYpes";

const initialState = {
  airbyteMessage: null,
  snowFlakeMessage: null,
  error: null,
  serverList: null,
  loading: null,
  deliveriDetails: null,
  snowFlakeDeliveryDetails: null,
  deliveryLoader:null,
  headingList:[],
};

function method1(stateData,payloadData){
  return {
    ...stateData,
    message: payloadData,
    loading: false,
  };
}
function method2(stateData,payloadData){
  return {
    ...stateData,
    error: payloadData,
    loading: false,
  };
}
function method3(stateData){
  return {
    ...stateData,
    loading: true,
  };
}
function method4(stateData){
  return {
    ...stateData,
    loading: true,
    message: null,
  };
}

 const DeliveryReducer = (state = initialState, action=action) => {
  switch (action.type) {
    case SAVE_DELIVERYDATA_REQUEST:return method4(state);
    case SAVE_DELIVERYDATA_REQUEST_SUCCESS:return method1(state,action.payload);
    case SAVE_DELIVERYDATA_REQUEST_FAIL:return  method2(state,action.payload);
    case GET_SERVERLIST_REQUEST:return method3(state);
    case GET_SERVERLIST_REQUEST_SUCCESS:
      return {
        ...state,
        serverList: action.payload,
        loading: false,
      };
    case GET_SERVERLIST_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DELIVERYDETAILS_REQUEST:
      return {
        ...state,
        deliveryLoader: true,
      };
    case GET_DELIVERYDETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        deliveriDetails: action.payload,
        deliveryLoader: false,
      };
    case GET_DELIVERYDETAILS_REQUEST_FAIL:
      return {
        ...state,        
        error: action.payload,
        deliveryLoader: false,
      };
    case UPDATE_DELIVERYDATA_REQUEST:return method3(state);
    case UPDATE_DELIVERYDATA_REQUEST_SUCCESS:return method1(state,action.payload);
    case UPDATE_DELIVERYDATA_REQUEST_FAIL:return method2(state,action.payload)

    case DELETE_DELIVERYDATA_REQUEST:return method3(state);
    case DELETE_DELIVERYDATA_REQUEST_SUCCESS:return method1(state,action.payload);
    case DELETE_DELIVERYDATA_REQUEST_FAIL:return  method2(state,action.payload);
    case SAVE_SNOWFLAKEDELIVERYDATA_REQUEST:return method4(state);
    case SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS:return method1(state,action.payload);
    case SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL:return method2(state,action.payload);
    case GET_SNOWFLAKEDELIVERYDETAILS_REQUEST:return method3(state);
    case GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        snowFlakeDeliveryDetails: action.payload,
        loading: false,
      };
    case GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        snowFlakeDeliveryDetails: null,
        loading: false,
      };
    case UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST:return method3(state);
    case UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS:return method1(state,action.payload);
    case UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL:return method2(state,action.payload);

    case DELETE_SNOWFLAKEDELIVERYDATA_REQUEST:
      return {
        ...state,
        snowFlakeMessage: null,
        loading: true,
      };
    case DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS:
      return {
        ...state,
        snowFlakeMessage: action.payload,
        loading: false,
      };
    case DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL:return method2(state,action.payload);
    case GET_HEADING_DETAILS_SUCCESS: return {
      ...state,
      headingList:action.payload,
    };
    default:
      return {
        ...state,
      };
  }
};
export default DeliveryReducer;