import { put, takeEvery, call } from "redux-saga/effects";
import { LOGINFAILURE, LOGINSUCCESS, LOGINREQUEST } from "./ActionType";
import { postCall } from "../../../apihelper/ApiHelper";
import { LOGIN } from "../../../constants/ApiEndPoints";
import { validateMessage } from "../../../validationhelper/ValidationHelper";

export function* login(action) {
    try {
        const response = yield call(postCall, LOGIN, action.payload)
        yield put({ type: LOGINSUCCESS, payload: response });
        if(validateMessage(response) && validateMessage(response.accessToken) && validateMessage(response.name)){
            localStorage.setItem("authUser", JSON.stringify({ JWT: response.accessToken, name: response.name }));
            window.location.href='/home';            
        }
    } catch (error) {
        yield put({ type: LOGINFAILURE, payload: error });
    }
}
function* LoginSaga() {
    yield takeEvery(LOGINREQUEST, login);
}

export default LoginSaga;