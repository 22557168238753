export function validateMessage(message) {
  return (message !== undefined && message !== null && message !== "" && message!=='null');
}


export function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function validateTextIsEmpty(text) {
  const isTextEmpty = /^(?!\s*$).+/;
  return isTextEmpty.test(text);
}

export function validatePassword(password) {
  const lengthRegex = /^.{8,}$/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /\d/;
  const symbolRegex = /[@*$&%!]/;
  const spaceRegex = /\s/;
  const maxLengthRegex = /^.{1,16}$/;


  if (!lengthRegex.test(password)) {
    return "Password must be at least 8 characters long.";
  }

  if (!uppercaseRegex.test(password)) {
    return "Password must contain at least one uppercase letter.";
  }

  if (!lowercaseRegex.test(password)) {
    return "Password must contain at least one lowercase letter.";
  }

  if (!digitRegex.test(password)) {
    return "Password must contain at least one digit.";
  }

  if (!symbolRegex.test(password)) {
    return "Password must contain at least one of the following symbols: @*$&%!";
  }
  if (spaceRegex.test(password)) {
    return "Password cannot contain spaces.";
  }
  if(!maxLengthRegex.test(password)){
    return "Password exceeds maximum length. Please use a password with 16 characters or fewer."
  }
  return null;
}