export const SAVE_DELIVERYDATA_REQUEST = "SAVE_DELIVERYDATA_REQUEST";
export const SAVE_DELIVERYDATA_REQUEST_SUCCESS =
  "SAVE_DELIVERYDATA_REQUEST_SUCCESS";
export const SAVE_DELIVERYDATA_REQUEST_FAIL = "SAVE_DELIVERYDATA_REQUEST_FAIL";
export const GET_SERVERLIST_REQUEST = "GET_SERVERLIST_REQUEST";
export const GET_SERVERLIST_REQUEST_SUCCESS = "GET_SERVERLIST_REQUEST_SUCCESS";
export const GET_SERVERLIST_REQUEST_FAIL = "GET_SERVERLIST_REQUEST_FAIL";
export const GET_DELIVERYDETAILS_REQUEST = "GET_DELIVERYDETAILS_REQUEST";
export const GET_DELIVERYDETAILS_REQUEST_SUCCESS =
  "GET_DELIVERYDETAILS_REQUEST_SUCCESS";
export const GET_DELIVERYDETAILS_REQUEST_FAIL =
  "GET_DELIVERYDETAILS_REQUEST_FAIL";
export const UPDATE_DELIVERYDATA_REQUEST = "UPDATE_DELIVERYDATA_REQUEST";
export const UPDATE_DELIVERYDATA_REQUEST_SUCCESS =
  "UPDATE_DELIVERYDATA_REQUEST_SUCCESS";
export const UPDATE_DELIVERYDATA_REQUEST_FAIL =
  "UPDATE_DELIVERYDATA_REQUEST_FAIL";
export const DELETE_DELIVERYDATA_REQUEST = "DELETE_DELIVERYDATA_REQUEST";
export const DELETE_DELIVERYDATA_REQUEST_SUCCESS =
  "DELETE_DELIVERYDATA_REQUEST_SUCCESS";
export const DELETE_DELIVERYDATA_REQUEST_FAIL =
  "DELETE_DELIVERYDATA_REQUEST_FAIL";
export const SAVE_SNOWFLAKEDELIVERYDATA_REQUEST =
  "SAVE_SNOWFLAKEDELIVERYDATA_REQUEST";
export const SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS =
  "SAVE_DELIVERYDATA_REQUEST_SUCCESS";
export const SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL =
  "SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL";
export const GET_SNOWFLAKEDELIVERYDETAILS_REQUEST =
  "GET_SNOWFLAKEDELIVERYDETAILS_REQUEST";
export const GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_SUCCESS =
  "GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_SUCCESS";
export const GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_FAIL =
  "GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_FAIL";
export const UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST =
  "UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST";
export const UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS =
  "UPDATE_DELIVERYDATA_REQUEST_SUCCESS";
export const UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL =
  "UPDATE_DELIVERYDATA_REQUEST_FAIL";
export const DELETE_SNOWFLAKEDELIVERYDATA_REQUEST =
  "DELETE_SNOWFLAKEDELIVERYDATA_REQUEST";
export const DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS =
  "DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS";
export const DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL =
  "DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL";
export const GET_HEADING_DETAILS_REQUEST="GET_HEADING_DETAILS_REQUEST";
export const GET_HEADING_DETAILS_SUCCESS="GET_HEADING_DETAILS_SUCCESS";
export const GET_HEADING_DETAILS_FAIL="GET_HEADING_DETAILS_FAIL";
