import { configureStore } from "@reduxjs/toolkit";
import  productReducer  from "./product/ProductReducer";
import passwordSaga from "./account/setPassword/PasswordSaga";
import verificationCodeSaga from "./account/codeVerification/VerificationCodeSaga";
import loginSaga from "./account/login/LoginSaga";
import registerSaga from "./account/register/RegisterSaga";
import createSagaMiddleware from "redux-saga";
import mySaga from "./product/ProductSaga";
import { all } from "redux-saga/effects";
import wishlistcartSaga from "./wishlistAndCart/WishlistcartSaga";
import wishlistReducer  from "./wishlistAndCart/WishlistReducer";
import  registerReducer  from "./account/register/RegisterReducer";
import  loginReducer  from "./account/login/LoginReducer";
import  setPasswordReducer  from "./account/setPassword/SetPasswordReducer";
import  codeVerificationReducer  from "./account/codeVerification/CodeVerificationReducer";
import  googleCallBackReducer  from "./account/thirdPartyIntegration/google/GoogleCallBackReducer";
import GoogleExchangeTokenSaga from "./account/thirdPartyIntegration/google/GoogleExchangeTokenSaga";
import linkedInExchangeTokenSaga from "./account/thirdPartyIntegration/linkedIn/LinkedInExchangeTokenSaga";
import  linkedInCallBackReducer  from "./account/thirdPartyIntegration/linkedIn/LinkedInCallBackReducer";
import billingInforeducer  from "./payment/BillingInforeducer";
import billingInfoSaga from "./payment/BillingInfoSaga";
import deliverySaga from "./modeofdelivery/DeliverySaga";
import  deliveryReducer  from "./modeofdelivery/DeliveryReducer";
import  forgotPasswordReducer  from "./account/forgotPassword/ForgotPasswordReducer";
import forgotPasswordSaga from "./account/forgotPassword/ForgotPasswordSaga";
import contactUsSaga from "./contactUs/ContactUsSaga";
import  contactUsReducer  from "./contactUs/ContactUsReducer";
import  myAccountReducer  from "./myaccount/MyAccountReducer";
import myAccountSaga from "./myaccount/MyAccountSaga";
import orderSaga from "./orders/OrderSaga";
import  orderReducer  from "./orders/OrderReducer";

function* rootSaga() {
  yield all([
    contactUsSaga(),
    loginSaga(),
    mySaga(),    
    wishlistcartSaga(),
    registerSaga(),
    passwordSaga(),
    verificationCodeSaga(),
    GoogleExchangeTokenSaga(),
    linkedInExchangeTokenSaga(),
    billingInfoSaga(),
    deliverySaga(),
    forgotPasswordSaga(),
    myAccountSaga(),
    orderSaga(),
  ]);
}

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    contactUs: contactUsReducer,
    product: productReducer,
    wishlistcart: wishlistReducer,
    register: registerReducer,
    login: loginReducer,
    password: setPasswordReducer,
    codeVerification: codeVerificationReducer,
    authenticationCallback: googleCallBackReducer,
    linkedIn: linkedInCallBackReducer,
    google: googleCallBackReducer,
    paymentInfo: billingInforeducer,
    deliveryMode: deliveryReducer,
    forgotPassword: forgotPasswordReducer,
    myAccount: myAccountReducer,
    orders: orderReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
