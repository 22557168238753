import React from "react";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import "./payment.css";
import { Link } from "react-router-dom";

export default function PaymentFailed() {
  return (
    <div className="payment_failed">
      <div className="container">
        <div className="process-failed-content">
          <div className="payment-failed-header text-danger mb-4">
            <BsFillExclamationCircleFill className="failed-mdg-icon" />
            Payment Failed!
          </div>
          <div className="payment-failed-text mb-4">
          We couldn't process your payment. Error code: CSC_7500274
          </div>
          <div className="payment_failed-btn mb-4">
            <Link className="btn try-again-btn " id="as-try-again" to="/enrichCart">
              Please try again
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
