import { REGISTERFAILURE, REGISTERREQUEST, REGISTERSUCCESS } from "./ActionType"

const initialState = {
    error: false,
    loading: false,
    response: null
};

const RegisterReducer = (state = initialState, action=action) => {
    switch (action.type) {
        case REGISTERREQUEST:
            return { ...state, error: false, loading: true, response: null }
        case REGISTERSUCCESS:
            return { ...state, error: false, loading: false, response: action.payload };
        case REGISTERFAILURE:
            return { ...state, error: true, loading: false, response: action.payload };
        default: return { ...state };
    }
};
export default RegisterReducer;