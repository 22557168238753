import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ORDERS_BY_NAME,
  GET_ALLFITERORDERS_REQUEST,
} from "../../store/orders/ActionsTypes";
import { validateMessage } from "../../validationhelper/ValidationHelper";
import productImg from "../enrichImages/education-Degree.jpg";
import "./order.css";
import { FaDownload, FaFilter, FaSearch } from "react-icons/fa";
import EmptyOrders from "./EmptyOrders";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../PopUp/SuccessPopUp";

export default function Orders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderList = useSelector((state) => state.orders?.filterOrderList);
  const [isActive, setIsActive] = useState(false);
  const [orderBy, setOrderBy] = useState("M3");
  const [errorMessage, setErrorMessage] = useState("");
  const loading = useSelector((state) => state.orders.loading);
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [searchByName, setSearchByName] = useState("");

  useEffect(() => {
    if (!validateMessage(user)) {
      navigate("/login");
    }
  },[]);

  useEffect(() => {
    if(validateMessage(user)){
    dispatch({
      type: GET_ALLFITERORDERS_REQUEST,
      payload: { isActive: isActive, orderBy: orderBy },
    });
  }
  }, []);

  const handleApplyOnClick = () => {
    dispatch({
      type: GET_ALLFITERORDERS_REQUEST,
      payload: { isActive: isActive, orderBy: orderBy },
    });
  };
  const handleCallBack = () => {
    setErrorMessage("");
  };



  const handleClearOnClick = () => {
    setIsActive(false);
    setOrderBy("M3");
  };
  const formatDate = (date) => {
    const backendDate = new Date(date);
    const day = backendDate.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      backendDate
    );
    const year = backendDate.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const handleSearchByName = () => {    
    if (searchByName.length > 0){
      dispatch({ type: FETCH_ORDERS_BY_NAME, payload: searchByName });
    }else{
      dispatch({ type: FETCH_ORDERS_BY_NAME, payload: "" });
    }
  };

  return (
    <div className="my-orders-container My-Account-container">
      <PopUp
        loader={loading}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="container">
        <div className="account-heading">
          <div className="account-address-heading">My Orders</div>
          <div className="Search-filter">
            <div className="input-group search-orders">
              <input
                value={searchByName}
                id="as-search-bar"
                onChange={(e) => setSearchByName(e.target.value)}
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button
                  id="as-search-btn"
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleSearchByName}
                >
                  <FaSearch className="order-search-icon" />
                </button>
              </div>
              <button
                type="button mx-4"
                className="btn btn-outline-secondary filter-btn"
                data-bs-toggle="modal"
                data-bs-target="#ApllyFilters"
                id="as-filter"
              >
                <FaFilter /> Filter
              </button>              
            </div>
          </div>
        </div>

        <div className="subcription-orders">
          <div className="subscription-orders-container">
            {!validateMessage(orderList) ||
              (orderList.length <= 0 && <EmptyOrders />)}
            {validateMessage(orderList) &&
              orderList.map((orderItem, index) => (
                <div
                  className="wishlist-items OrderItem shadow mt-4 "
                  key={index}
                >
                  <div className="wishlist-products order-products">
                    <div className="wishlist-item-left order-products-left">
                      <img
                        className="wishlist-product-img"
                        src={productImg}
                        alt="img"
                      ></img>
                      <div className="order-product-details">
                        <div className="order-product-name">
                          {orderItem.productName}{" "}
                        </div>
                        <div className="order-product-deliveryDate">
                          <div className="order-highlight">Ordered On</div>
                          {formatDate(orderItem.orderDate)}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="wishlist-item-right order-products-right">
                      <div className="wishlist-product-totalprice order-price">
                        ${orderItem.price}
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="order-delivery-expiry-details">
                    <div className="order-dates">
                      <div className="order-Number text-truncate">
                        {" "}
                        Order ID # {orderItem.orderId}
                      </div>
                      {orderItem.deliveryStatus === "Completed" && (
                        <div className="delivery-date">
                          <span className="not-symbol">|</span>
                          Delivery Date: {formatDate(orderItem.deliveryDate)}
                        </div>
                      )}
                      {orderItem.deliveryStatus === "Completed" && (
                        <div className="expire-date">
                          <span className="not-symbol">|</span>
                          Expiry Date: {formatDate(orderItem.expireDate)}
                        </div>
                      )}
                    </div>
                    <div className="order-renew">
                      <Link
                        className="btn btn-link invoice-download-btn"
                        id="as-invoice"
                        to={orderItem.invoicePdf}
                      >
                        <FaDownload /> Download Invoice
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div class="modal fade" id="ApllyFilters" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-md  modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">            
              <button type="button" className="btn-close make-me-invisible"  data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                <h5>Filter Orders</h5>
                <div className="form-check mt-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="as-filterOrder1"
                    checked={isActive === false}
                    onClick={() => setIsActive(false)}
                  />
                  <label className="form-check-label" for="as-filterOrder1">
                    All Orders
                  </label>
                </div>
                <div className="form-check mt-2 mb-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="as-filterOrder2"
                    onClick={() => setIsActive(true)}
                  />
                  <label className="form-check-label" for="as-filterOrder2">
                    Active Orders
                  </label>
                </div>

                <h5>Filter By Order date</h5>
                <div className="form-check mt-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault2"
                    id="as-filterDate1"
                    onClick={() => setOrderBy("D30")}
                  />
                  <label className="form-check-label" for="as-filterDate1">
                    Last 30 Days
                  </label>
                </div>
                <div className="form-check mt-2 ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault2"
                    id="as-filterDate2"
                    checked={orderBy === "M3"}
                    onClick={() => setOrderBy("M3")}
                  />
                  <label className="form-check-label" for="as-filterDate2">
                    Last 3 Months
                  </label>
                </div>
                <div className="form-check mt-2 ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault2"
                    id="as-filterDate3"
                    onClick={() => setOrderBy("CY")}
                  />
                  <label className="form-check-label" for="as-filterDate3">
                    2023
                  </label>
                </div>
                <div className="form-check mt-2 ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault2"
                    id="as-filterDate4"
                    onClick={() => setOrderBy("PY")}
                  />
                  <label className="form-check-label" for="as-filterDate4">
                    2022
                  </label>
                </div>
              </div>
            <div className="modal-footer filter-btns">
                <button className="btn btn-dark" id="as-clear-btn" onClick={handleClearOnClick}>
                  Clear Filter
                </button>
                <button className="btn btn-info" id="as-apply-btn" data-bs-dismiss="modal" onClick={handleApplyOnClick}>
                  Apply
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
