
import { LOGINFAILURE, LOGINREQUEST, LOGINSUCCESS } from './ActionType'


const initialState = {
    error: false,
    loading: false,
    response: ""

};

 const LoginReducer = (state = initialState, action=action) => {
    switch (action.type) {
        case LOGINREQUEST: return { ...state, error: false, loading: true, }
        case LOGINSUCCESS:
            return { ...state, error: false, loading: false, response: action.payload };
        case LOGINFAILURE:
            return { ...state, error: true, loading: false, response: action.payload };
        default: return state;
    }
};
export default LoginReducer;