import React from 'react'
import './termsservice.css'

const termsService = () => {
    document.title = 'Terms Services - www.enrich.io'

    return (
        <div className='termsService'>
            <div className='container'>
                <div className='termsServiceContainer shadow'>
                    <div className='termsServiceHead'>
                        <h2>TERMS OF SERVICE</h2>
                        <p>Welcome to Enrich.io ("we," "us," or "our"). Please read these Terms of Service ("Terms") carefully before using our website and services. By accessing or using our website, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our website.</p>
                    </div>
                    <div className='listOfTerms'>
                        <div className='term1 terms'>
                            <div className='heading'>1. Acceptance of Terms</div>
                            <p>By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms, as well as our Privacy Policy. which is incorporated by reference. If you do not agree with any part of these Terms or our Privacy Policy, you should not use our website.</p>
                        </div>
                        <div className='term2 terms'>
                            <div className='heading'>2. Changes to Terms</div>
                            <p>We reserve the right to modify, update, or revise these Terms at any time, with or without notice. It is your responsibility to regularly review these Terms for any changes. Your continued use of our website after the posting of any changes constitutes your acceptance of those changes.</p>
                        </div>
                        <div className='term3 terms'>
                            <div className='heading'>3. Access to the Website</div>
                            <ul>
                                <li className='termslist'>
                                    <p><span>3.1. Eligibility: </span> You must be at least 18 years old to use our website. By using our website, you represent and warrant that you are of legal age to form a binding contract with us.</p>
                                </li>
                                <li className='termslist'><p><span>3.2. Account Registration: </span> To access certain features of our website, you may be required to create an account. You are responsible for providing accurate and up-to-date information during the registration process and maintaining the security of your account credentials.</p></li>
                            </ul>
                        </div>
                        <div className='term4 terms'>
                            <div className='heading'>4. Services</div>
                            <p>We offer taxonomies and related services through our website. These services may be subject to additional terms, conditions, or fees, which will be dis- closed to you when you use those services</p>
                        </div>
                        <div className='term5 terms'>
                            <div className='heading'>5. User Conduct</div>
                            <ul>
                                <li className='termslist'>
                                    <p><span>5.1. Prohibited Activities: </span> You agree not to use our website for any unlawful or prohibited purpose. This includes, but is not limited to, the following:</p>
                                </li>
                                <ul>
                                    <li>- Violating any applicable laws or regulations.</li>
                                    <li>- Transmitting any content that is unlawful, harmful, defamatory, or offensive.</li>
                                    <li>- Attempting to gain unauthorized access to our website or its underlying systems</li>
                                </ul>
                                <li className='termslist'>
                                    <p><span>5.2. User Content: </span> If you submit any content to our website, including reviews, comments, or suggestions, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute that content. You represent and warrant that you have the necessary rights to grant this license.</p>
                                </li>
                            </ul>
                        </div>
                        <div className='term6 terms'>
                            <div className='heading'>6. Intellectual Property</div>
                            <p>All content, trademarks, logos, and other intellectual property on our website are owned or licensed by us. You may not use, reproduce, or distribute any of our intellectual property without our prior written consent.</p>
                        </div>
                        <div className='term7 terms'>
                            <div className='heading'>7. Disclaimer of Warrantles</div>
                            <p>We provide our website and services "as is" and make no warranties or representations regarding their accuracy, availability, or suitability for any purpose. We do not guarantee that our website will be error-free or uninterrupted.</p>
                        </div>
                        <div className='term8 terms'>
                            <div className='heading'>8. Limitation of Liability</div>
                            <p>To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to your use of our website or services.</p>
                        </div>
                        <div className='term9 terms'>
                            <div className='heading'>9. Termination</div>
                            <p>To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to your use of our website or services.</p>
                        </div>
                        <div className='term10 terms'>
                            <div className='heading'>10. Governing Law</div>
                            <p>These Terms are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising from or relating to these Terms shall be sub- ject to the exclusive jurisdiction of the courts located in [Jurisdiction].</p>
                        </div>
                        <div className='term11 terms'>
                            <div className='heading'>11. Contact Us</div>
                            <p>If you have any questions or concerns about these Terms, please contact us at: <a href='mailto:support@enrich.io' className='mailTo'>support@enrich.io</a></p>
                            <p>By using our website, you agree to these Terms and acknowledge that they constitute a legally binding agreement between you and Enrich.io.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default termsService
