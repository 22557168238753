import React from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import EmptyImg from "../enrichImages/Screenshot 2023-10-04 115206.png";
import "./order.css";
export default function EmptyOrders() {
  return (
    <div className="EmptyOrers-Container">
      <div className="card text-center shadow-sm">
        <div className="card-body">
          <img
            src={EmptyImg}
            className="img-fluid rounded-start mb-4"
            alt="image for empty orders"
          />
          <p className="card-text">No Order Records Found</p>
          <Link to="/shop" id="as-shop" className="btn btn-danger emptyOrders-shop-btn">
            Shop Now <FaAngleDoubleRight />
          </Link>
        </div>
      </div>
    </div>
  );
}
