import React, { useEffect } from "react";
import "./payment.css";
import { useDispatch, useSelector } from "react-redux";
import { GET_STATUS_REQUEST } from "../../store/payment/ActionTypes";
import { useLocation, useNavigate } from "react-router-dom";

export default function RedirectPage() {
  const isLoading = true;
  const paymentStatus = useSelector((state) => state.paymentInfo?.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uId = searchParams.get("uId");

  useEffect(() => {
    if (paymentStatus !== null && paymentStatus === true) {
      navigate(`/modeofDelivery?orderId=${uId}`);
    } else {
      setTimeout(() => {
        dispatch({ type: GET_STATUS_REQUEST, payload: uId });
      }, 5000);
    }
    setTimeout(() => {
      if (paymentStatus !== true && window.location.pathname === "/redirect") {
        navigate("/paymentfailed");
      }
    }, 120000);
  }, [paymentStatus]);

  return (
    <div>
      {isLoading && (
        <div className="Mode-of-delivery-loader">
          <div className=" spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <h3>Transaction is in progress </h3>
          <p>Please do not refresh or close the window</p>
        </div>
      )}
    </div>
  );
}
