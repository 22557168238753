import { call, put, takeEvery } from "redux-saga/effects";
import { postCall } from "../../apihelper/ApiHelper";
import { CONTACTUSSUCCESS, CONTACTUSFAILURE, CONTACTUSREQUEST } from './ActionType'
import { CONTACTUS_ENDPOINT } from '../../constants/ApiEndPoints'

export function* contactUsApiCall(action) {
    const value = {
        name: action.payload.name,
        email: action.payload.email,
        message: action.payload.message
    }
    try {
        const response = yield call(postCall, CONTACTUS_ENDPOINT, value)
        yield put({ type: CONTACTUSSUCCESS, payload: response });
    } catch (error) {
        yield put({ type: CONTACTUSFAILURE, payload: error });
    }
}

function* ContactUsSaga() {
    yield takeEvery(CONTACTUSREQUEST, contactUsApiCall);
}

export default ContactUsSaga;