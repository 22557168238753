import React, { useEffect, useState } from "react";
import "./product.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaAngleRight,FaDownload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { LiaHandPointRightSolid } from "react-icons/lia";
import { VscHeart } from "react-icons/vsc";
import { FETCH_ONEPRODUCT, FETCH_SAMPLE_DATA_FAILURE, FETCH_SAMPLE_DATA_REQUEST } from "../../store/product/ActionTypes";
import {
  ADDTO_WISHLISTCART,
  ADDTO_WISHLISTCART_SUCCESS,
} from "../../store/wishlistAndCart/ActionType";
import PopUp from "../PopUp/SuccessPopUp";
import { validateMessage } from "../../validationhelper/ValidationHelper";
import { CREATE_SUBSCRIPTION_REQUEST } from "../../store/payment/ActionTypes";

export default function Product() {
  document.title = "Product - www.enrich.io";

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("id");
  const product = useSelector((state) => state.product?.products);
  const loading = useSelector((state) => state.product.loading);
  const error=useSelector((state)=>state.product?.errorSampleData);
  const dispatch = useDispatch();
  const [cartLoading, setCartLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [errorMessage,setErrorMessage]=useState("");

  useEffect(() => {
    dispatch({ type: FETCH_ONEPRODUCT, payload: { productId } });
  }, [dispatch, productId]);
  useEffect(()=>{
    setErrorMessage(error);
  },[error])

  const navigate = useNavigate();
  function handleShopOnClick() {
    setCartLoading(false);
    navigate("/shop");
  }

  const handleLoad = () => {
    setCartLoading(false);
  };

  const handleCartOnClick = () => {
    setCartLoading(false);
    navigate("/enrichCart");
  };

  function hanldeWishListOnClick() {
    if (!validateMessage(user)) {
      window.location.href = "/login";
    }
    setCartLoading(true);
    dispatch({
      type: ADDTO_WISHLISTCART,
      payload: { productId: [productId], type: "WISHLIST", quentity: 1 },
    });
    dispatch({ type: ADDTO_WISHLISTCART_SUCCESS, payload: null });
    navigate("/wishlist");
  }

  function hanldeAddCartOnClick() {
    if (!validateMessage(user)) {
      window.location.href = "/login";
    }
    setCartLoading(true);
    dispatch({
      type: ADDTO_WISHLISTCART,
      payload: { productId: [productId], type: "CART", quentity: 1 },
    });
    dispatch({ type: ADDTO_WISHLISTCART_SUCCESS, payload: null });
  }

  const hanldeAddCartOnClickBuy = () => {
    if (!validateMessage(user)) {
      window.location.href = "/login";
    }    
    setCartLoading(true);
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
      payload: { productIds: [productId] },
    });
  };

  function productFeatureData(productFeatureValue){
    if(productFeatureValue && Array.isArray(productFeatureValue)){
      return productFeatureValue.map((item, index) => (
        <li key={index} className="feature pb-2">
          <span className="icon">
            <LiaHandPointRightSolid />
          </span>{" "}
          {item}
        </li>))
    }
  }
  function isLoaderTrue(loader1,loader2){
    return loader1 || loader2;
  }
  function isProductFeatureValid(productData){
    return validateMessage(productData) && productData.length>0;
  }
  function handleSampleData(){
    if(!validateMessage(user)){
      navigate("/login");
      return;
    }
    dispatch({ type: FETCH_SAMPLE_DATA_REQUEST, product: product.productId, productName: product.name });
  }
  function correctProductName(nameOfProduct){
    return validateMessage(nameOfProduct)? nameOfProduct.toUpperCase(): "";
  }

  function handleCallBack(){
    setErrorMessage("");
    dispatch({type:FETCH_SAMPLE_DATA_FAILURE,payload:null});
  }
  return (
    <>
      <PopUp
        loader={isLoaderTrue(loading,cartLoading)}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="product-detail-container">
        <div className="container">
          <div className="shop-product-heading row pt-3">
            <div className="col-md-12 text-end mb-4 mt-3">
              <p>
                <Link
                  className="heading-link heading-link-prev home"
                  id="as-home"
                  to="/home"
                >
                  Home
                </Link>{" "}
                <FaAngleRight className="right-angle-icon" />{" "}
                <Link
                  className="heading-link heading-link-prev home"
                  id="as-shop"
                  to="/shop"
                >
                  Products
                </Link>
                <FaAngleRight className="right-angle-icon" />
                {product.name}
              </p>
            </div>
          </div>

          {validateMessage(product) ? (
            <div className=" product-detail-section row pb-5 ">
              <div className="col-md-4  img-section">
                <img
                  className="shadow-sm"
                  src={product.url}
                  alt="product_img"
                ></img>
              </div>
              <div className="col-md-8  details-section mb-5">
                <div className="card shadow-sm">
                  <div className="card-body pd-section-body">
                    <p className="name-heading">
                      {correctProductName(product.name)}
                    </p>
                    <h2 className="price-heading">
                      <b>${product.price} USD</b>
                    </h2>
                    <p className="description-text">{product.description}</p>
                    {isProductFeatureValid(product.addressFeature) &&(
                        <h5 className="feature-heading">
                          Get complete details of the postal code of a city that
                          includes
                        </h5>
                      )}

                    <ul className="feature-list p-0">
                      {productFeatureData(product.addressFeature)}
                    </ul>
                    {isProductFeatureValid(product.features) && (
                        <h5 className="feature-heading">This Includes</h5>
                      )}

                    <ul className="feature-list p-0">
                      {productFeatureData(product.features)}
                    </ul>
                    {isProductFeatureValid(product.paidVersionFeatures) &&(
                        <h5 className="feature-heading">
                          Buy paid version and get
                        </h5>
                      )}

                    <ul className="feature-list p-0">
                      {productFeatureData(product.paidVersionFeatures)}
                    </ul>

                    <hr></hr>
                    <div className="renewal-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="as-flexCheckIndeterminate"
                        checked={true}                        
                      />
                      Updates for renewal.
                    </div>
                    <div className="product-btn-group mt-2">
                      <button
                        type="button"
                        className="btn mb-2 btn-lg buy-btn"
                        id="as-policy"
                        onClick={hanldeAddCartOnClickBuy}
                      >
                        BUY IT NOW
                      </button>
                      <button
                        type="button "
                        className="btn btn-dark btn-lg mb-2 add-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#CartStatus"
                        id="as-cart"
                        onClick={hanldeAddCartOnClick}
                      >
                        ADD TO CART
                      </button>
                      <button
                        type="button"
                        className="btn mb-2 btn-lg wishlist-btn"
                        id="as-wishlist"
                        onClick={hanldeWishListOnClick}
                      >
                        <VscHeart /> WISHLIST
                      </button>
                      {validateMessage(product) && product.sampleData&&
                      <button
                        type="button"
                        className="btn mb-2 btn-lg wishlist-btn download-sample-data-btn"
                        id="as-wishlist"
                        onClick={handleSampleData}
                      >
                        <FaDownload className="download-icon"/>
                        SAMPLE DATA
                      </button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div
            className="modal fade"
            id="CartStatus"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-dialog-centered">
              <div className="modal-content">
                <button
                  type="button"
                  className="closebutton"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="as-close-modal"
                >
                  <span
                    className="red-btn position-absolute top-0 start-100 translate-middle"
                    onClick={handleLoad}
                    id="as-load"
                  >
                    &times;
                  </span>
                </button>

                <div
                  className={`modal-body popup-body p-4
                   `}
                >
                  <div className="popup-img-section">
                    <img src={product.url} alt="product_img"></img>
                  </div>
                  <div className="popup-details">
                    <div className="popup-name ">
                      <p className="mb-0">{product.name}</p>
                    </div>
                    <div className="popup-massage mb-2">
                      <img
                        className="popup-success-img"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7HkuQRlJYcn-5rsZn10eRUA1ECOWTVBeA2Q&usqp=CAU"
                        alt="popup-img                                                       "
                      ></img>
                      <span className="popup-success-message text-success">
                        Added to Cart Successfully!
                      </span>
                    </div>
                    <div className="popup-btn">
                      <button
                        className="shoping-btn btn btn-sm "
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="as-shop"
                        onClick={handleShopOnClick}
                      >
                        CONTINUE SHOPPING
                      </button>
                      <button
                        className="cart-btn btn btn-sm"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="as-cart"
                        onClick={handleCartOnClick}
                      >
                        GO TO CART
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
