import "./navbar.css";
import logo from "../enrichImages/logo.png";
import { AiOutlineMenu, AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import { MdOutlineShoppingBag } from "react-icons/md";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_CART,
  FETCH_WISHLISTCART_COUNT,
} from "../../store/wishlistAndCart/ActionType";
import { RiQuestionFill } from "react-icons/ri";
import EmptyImg from "../enrichImages/Screenshot 2023-10-04 115206.png";
import { CREATE_SUBSCRIPTION_REQUEST } from "../../store/payment/ActionTypes";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import { validateMessage } from "../../validationhelper/ValidationHelper";
import { handleNavigate } from "../../constants/ValidationConst";

export default function NavBar() {
  const [active, setActive] = useState();
  let cartCount = useSelector((state) => state.wishlistcart?.cartCount);
  let wishListCount = useSelector((state) => state.wishlistcart?.wishListCount);
  const dispatch = useDispatch();
  const auth=JSON.parse(localStorage.getItem("authUser"));  
  const cartProducts = useSelector((state) => state.wishlistcart?.cartItem);
  const [totalPrice, setTotalPrice] = useState(0);
  const [checkedProducts, setCheckProducts] = useState([]);
  const uId = useSelector((state) => state.paymentInfo?.uId);
  const navigate = useNavigate();
  const response = useSelector((state) => state.wishlistcart?.cartCountMessage);
  const message = useSelector((state) => state.wishlistcart?.message);

  const path = useLocation().pathname;
  useLayoutEffect(() => {
    if (path.includes("/home")) {
      setActive(1);
    } else if (path.includes("/shop")) {
      setActive(2);
    } else if (path.includes("/contact-us")) {
      setActive(3);
    }
  }, [path]);

  if (validateMessage(auth) && auth.name==null) {
    auth.name = "";
  }

  useEffect(() => {

    if (validateMessage(cartProducts)) {
      const totalNewPrice = cartProducts.reduce(
        (total, product) => total + product.price,
        0
      );
      const productIds = cartProducts.map((product) => product.productId);
      setCheckProducts(productIds);
      setTotalPrice(totalNewPrice);
    }
  }, [cartProducts]);

  useEffect(() => {
    if (!validateMessage(auth)) {
      return;
    }
    dispatch({ type: FETCH_WISHLISTCART_COUNT });
    dispatch({ type: FETCH_CART });
  }, [dispatch]);

  useEffect(() => {
    if (message === "ADDED" || message === "Removed") {
      dispatch({ type: FETCH_CART });
      dispatch({ type: FETCH_WISHLISTCART_COUNT });
    }
  }, [message]);
  useEffect(() => {
    if (uId !== null) {
      navigate(`/payment?uId=${uId}`);
    }
  }, [uId]);

  const handleCheckoutOnClick = () => {
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
      payload: { productIds: checkedProducts },
    });
  };
  const handleLogoutOnCLick = (e) => {
    e.preventDefault();
    localStorage.removeItem("authUser");
    window.location.href = "/login";
  };

  const handleLogoutImpersonate = () => {    
    const authUserBG = localStorage.getItem('authUserBG');
    let impersonate = localStorage.getItem('impersonate');
    if(authUserBG){
      localStorage.setItem('authUser', authUserBG);
    }
    if(!localStorage.getItem("authUserBG")){
      localStorage.removeItem("authUser");
    }    
    localStorage.setItem('impersonate', 'false');
    localStorage.removeItem("authUserBG");
    window.open(handleNavigate(window.location.origin));
    navigate(window.pathname);
  };  


  return (
    <header className="enrich-header" id="enrichheader">
      <SuccessPopUp loading={response} />
      <nav className="navbar navbar-expand-md bg-body-tertiary">
      {localStorage.getItem("impersonate")==="true"&&<div className="impersonate-header container-fluid"><div >You are in Impersonation Mode.</div><button className="btn btn-danger mx-2" onClick={handleLogoutImpersonate} >Click Here</button> to end this session</div>}
        <div className="container">        
          <div className="enrich-header-top">
            <div className="enrich-header-top-left">
              <Link className="navbar-brand" id="as-home" to="/home">
                <img src={logo} alt="enrich-logo"></img>
              </Link>
            </div>            
            <div className="enrich-header-top-right">
              <button
                className="navbar-toggler text-dark"
                type="button"
                id="as-toggle"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                placeholder="menu"
              >
                <span className="navbar-toggler-icon text-center">
                  <AiOutlineMenu className="menu" />
                </span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="right-items navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item google-tanslate-nav">
                    <div id="google_translate_element"></div>
                  </li>
                  <li className="nav-item">
                    {!validateMessage(auth) ? (
                      <Link
                        className="nav-link d-flex flex-column justify-content-center align-items-center"
                        id="as-login"
                        to="/login"
                      >
                        <AiOutlineUser className="navbar-user" translate="no" />
                        LOGIN
                      </Link>
                    ) : (
                      <div className="nav-link d-flex flex-column justify-content-center align-items-center btn-group">
                        <AiOutlineUser className="navbar-user" />
                        <button
                        id="as-dropdown-btn"
                          type="button"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          translate="no"
                        >
                          {validateMessage(auth) && validateMessage(auth.name) && auth.name.substring(0, 1).toUpperCase() +
                            auth.name.substring(1)}
                        </button>
                        <ul
                          className="drop-item account-drop-down dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <Link
                            className="nav-link account-link"
                            id="as-myaccount-link"
                            to="/MyAccount"
                          >
                            My Account
                          </Link>
                          <Link className="nav-link orders-link" to="/Orders" id="as-order-link">
                            Orders
                          </Link>
                          <hr></hr>
                          <Link
                            to="/logout"
                            onClick={handleLogoutOnCLick}
                            className="btn btn-sm btn-logout  btn-danger"
                            id="as-logout-link"
                          >
                            LOGOUT
                          </Link>
                        </ul>
                      </div>
                    )}
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link wishlist-link d-flex flex-column justify-content-center align-items-center position-relative"
                      href={auth == "" ? "/login" : "/wishlist"}
                      id="as-wishlist"
                    >
                      {auth == "" ? (
                        ""
                      ) : (
                        <span className="position-absolute p-1 translate-middle rounded-circle  text-white">
                          <p className="text-center">{wishListCount}</p>
                        </span>
                      )}
                      <AiOutlineHeart className="navbar-wishlist" />
                      WISHLIST
                    </a>
                  </li>
                  <li className="nav-item dropdown ">
                    <Link
                      type="button"
                      id="as-cart"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="nav-link  d-flex flex-column justify-content-center align-items-center position-relative"
                      to={auth == "" ? "/login" : ""}
                    >
                      {auth == "" ? (
                        ""
                      ) : (
                        <span className="position-absolute p-1 translate-middle rounded-circle  text-white">
                          <p className="text-center">{cartCount}</p>
                        </span>
                      )}
                      <MdOutlineShoppingBag className="navbar-cart" />
                      CART
                    </Link>
                    {cartCount === 0 ? (
                      <div className="dropdown-menu emptyCart-dropdown text-center">
                        <img src={EmptyImg} alt="image "></img>
                        <p>Your Cart is Empty</p>
                        <Link className="btn btn-link" id="as-shop" to="/shop">
                          Go to the shop
                        </Link>
                      </div>
                    ) : (
                      <div className="dropdown-menu cart-dropdown">
                        {validateMessage(cartProducts) &&
                          cartProducts.map((product, index) => (
                            <div key={index} className="cart-dropdown-list">
                              <div className="cart-dropdown-content">
                                <div className="cart-dropdown-img">
                                  <img src={product.url}></img>
                                </div>
                                <div className="cart-dropdown-text">
                                  <p>{product.name}</p>
                                  <p className="cart-dropdown-price">
                                    $ {product.price}
                                  </p>
                                </div>
                              </div>
                              <hr></hr>
                            </div>
                          ))}
                        <div className="cart-dropdown-total">
                          <div>
                            {" "}
                            <span>Total: </span>{" "}
                            <span className="total-price"> ${totalPrice}</span>
                          </div>
                        </div>
                        <div className="cart-dropdown-btn">
                          <button
                            className="btn btn-link btn-info view-cart-btn"
                            id="as-cart"
                            onClick={() =>
                              (window.location.href = "/enrichCart")
                            }
                          >
                            VIEW CART
                          </button>
                          <Link
                            id="as-checkout"
                            className="btn btn-dark btn-link drop-checkout-btn"
                            onClick={handleCheckoutOnClick}
                          >
                            CHECKOUT
                          </Link>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="enrich-header-btm">
          <div className="container">
            <ul className="header-btm-content navbar-nav me-auto text-white">
              <li className="nav-item">
                <Link
                  to="/home"
                  id="as-home"
                  className={`nav-link text-white ${
                    active === 1 ? "active" : ""
                  }`}
                  onClick={() => setActive(1)}
                  aria-current="page"
                >
                  HOME
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/shop"
                  id="as-home"
                  className={`nav-link text-white ${
                    active === 2 ? "active" : ""
                  }`}
                  onClick={() => setActive(2)}
                >
                  SHOP
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact-us"
                  id="as-conatct-us"
                  className={`nav-link text-white ${
                    active === 3 ? "active" : ""
                  }`}
                  onClick={() => setActive(3)}
                >
                  CONTACT US
                </Link>
              </li>
            </ul>

            <div className="enrich-header-btm-right">
              <a
                id="as-help-center"
                href="https://docs.rchilli.com/kc/c_RChilli_taxonomy_purchase_overview.html"
                target="_blank"
                className="btn  btn-help-center"
              >
                <RiQuestionFill className="question" /> HELP CENTER
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
