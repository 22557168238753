import { put, takeEvery, call } from "redux-saga/effects";
import { VERIFICATIONREQUEST, VERIFICATIONSUCCESS, VERIFICATIONFAILURE, IMPERSONATE_REQUEST, IMPERSONATE_FAILURE, IMPERSONATE_SUCCESS } from "./ActionType";
import { getCall, postCall } from "../../../apihelper/ApiHelper";
import { IMPERSONATE_API, VERIFYCODE } from "../../../constants/ApiEndPoints";
import { validateMessage } from "../../../validationhelper/ValidationHelper";

export function* checkVerificationCode(action) {
    try {
        const response = yield call(postCall, `${VERIFYCODE}/${action.payload}`);
        yield put({ type: VERIFICATIONSUCCESS, payload: response });
    } catch (error) {
        yield put({ type: VERIFICATIONFAILURE, payload: error });
    }
}
export function* impersonateCodeVerification(action) {
    try {
        const response = yield call(getCall, `${IMPERSONATE_API}${action.payload}`);
        const user=JSON.parse(localStorage.getItem("authUser"));
        const userBG=JSON.parse(localStorage.getItem("authUserBG"));
        if(!validateMessage(userBG) && validateMessage(user)){
            localStorage.setItem("authUserBG",JSON.stringify({ JWT: user.JWT, name: user.name }));
        } 
        localStorage.removeItem("authUser");
        localStorage.setItem("authUser", JSON.stringify({ JWT: response.accessToken, name: response.name }));
        localStorage.setItem("impersonate",true);
        yield put({ type: IMPERSONATE_SUCCESS, payload: response.accessToken });
        window.location.href="/home";
    } catch (error) {
        yield put({ type: IMPERSONATE_FAILURE, payload: error });
    }
}

function* VerificationCodeSaga() {
    yield takeEvery(VERIFICATIONREQUEST, checkVerificationCode);
    yield takeEvery(IMPERSONATE_REQUEST, impersonateCodeVerification);
}

export default VerificationCodeSaga;