import { SETPASSWORDFAILURE, SETPASSWORDREQUEST, SETPASSWORDSUCCESS } from "./ActionType";



const initialState = {
    loading: false,
    response: "",
    error: false
}

 const SetPasswordReducer = (state = initialState, action=action) => {
    switch (action.type) {
        case SETPASSWORDREQUEST: return { ...state, loading: true, error: false, }
        case SETPASSWORDSUCCESS: return { ...state, loading: false, error: false, response: action.payload }
        case SETPASSWORDFAILURE: return { ...state, loading: false, error: true, response: action.payload }
        default: return state
    }
};
export default SetPasswordReducer;