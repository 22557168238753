import React, { useEffect,useState } from "react";
import "./payment.css";
import { FaAngleRight,FaRegArrowAltCircleLeft  } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CHECKOUT_SESSION_CREATION,
  GET_BIILLINGDETAILS,
  GET_STATEREQUEST,
  SAVE_BILLINGINFO,
  SAVE_BILLINGINFO_SUCCESS,
} from "../../store/payment/ActionTypes";
import { validateEmail, validateMessage } from "../../validationhelper/ValidationHelper";
import { GET_COUNTRY } from "../../constants/ApiEndPoints";
import {
  emailRegex,
} from "../../constants/ValidationConst";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Payment() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.paymentInfo?.userDetails);
  const loading = useSelector((state) => state.paymentInfo?.loading);
  const sessionloading = useSelector((state) => state.paymentInfo?.sessionloading);
  const errorMessage = useSelector(
    (state) => state.paymentInfo?.emailDomainError
  );
  const message = useSelector((state) => state.paymentInfo?.message);
  const stateList = useSelector((state) => state.paymentInfo?.stateList);
  const countryList = useSelector((state) => state.paymentInfo?.countryList);
  const checkOutUrl = useSelector((state) => state.paymentInfo?.checkoutUrl);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uId = searchParams.get("uId");
  const [validEmail, setValidEmail] = useState(true);
  const [validNumber, setValidNumber] = useState(true);
  const [termsCheck,setTermsCheck]=useState(null);
  const [isSavePress, setIsSavePress] = useState(false);  
  const [country,setCountry]=useState("");
  const [userInfo, setUserInfo] = useState({
    id: null,
    organizationName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    defaultAddress: false,
  });
  
  window.onload=function(){
    setTermsCheck(false);    
  }

  useEffect(() => {
    setTermsCheck(false);
    if (validateMessage(checkOutUrl)) {
      window.location.href = checkOutUrl.message;
    }
  }, [checkOutUrl]);

  useEffect(() => {
    dispatch({ type: GET_COUNTRY });
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: GET_BIILLINGDETAILS });
    }, 1500);
  }, [dispatch]);

  useEffect(() => {
    if (
      isSavePress &&
      validateMessage(message) &&
      message === "Your details has been submitted successfully"
      ) {
      dispatch({ type: CHECKOUT_SESSION_CREATION, payload: uId });
      dispatch({type:SAVE_BILLINGINFO_SUCCESS,payload:null});
    }
  }, [message]);

  useEffect(() => {
    if (validateMessage(userDetails)) {
      setCountry(userDetails.country);      
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        id: userDetails.id,
        organizationName: userDetails.organizationName,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber,
        address: userDetails.address,
        city: userDetails.city,
        country:userDetails.country,
        state:userDetails.state,
        zipCode: userDetails.zipCode,
        defaultAddress: true,
      }));
    }
  }, [userDetails]);

  const changeHandler = (e) => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      country: e.target.value,
    }));
    setCountry(e.target.value);
    dispatch({ type: GET_STATEREQUEST, payload: e.target.value });
  };

  const handleContinuePaymentOnClick = () => {
    setIsSavePress(true);        
    if (
      validateMessage(userInfo.zipCode) &&
      validateMessage(userInfo.phoneNumber)
    ) {
      setUserInfo((prevInfo) => ({
        ...prevInfo,
        zipCode: (prevInfo.zipCode),
      }));
    }
    if (
      validateMessage(userInfo.organizationName) &&
      validateMessage(userInfo.firstName) &&
      validateMessage(userInfo.lastName) &&
      validateMessage(userInfo.address) &&
      validateMessage(userInfo.city) &&
      validateMessage(userInfo.country) &&
      validateMessage(userInfo.state) &&
      validateMessage(userInfo.phoneNumber) &&
      validNumber &&
      validateEmail(userInfo.email) &&
      validEmail &&
      validateMessage(userInfo.zipCode)
    ) {
      dispatch({ type: SAVE_BILLINGINFO, payload: userInfo });
    }
  };

useEffect(()=>{
  if(country){
    dispatch({ type: GET_STATEREQUEST, payload: country });
  }
},[country])

  const handleEmailOnChange = (event) => {
    const inputEmail = event.target.value;
    if (!emailRegex.test(inputEmail)) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      email: inputEmail,
    }));
  };

  const handlePhonenumberOnChange = (e) => {    
    const inputNumber = e;
    if (!validateMessage(inputNumber)) {
      setValidNumber(false);
    } else {
      setValidNumber(true);
    }
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      phoneNumber: inputNumber,
    }));
  };

  const handleCheckBox=()=>{
    if(termsCheck){
      setTermsCheck(false);
    }else if(!termsCheck){
      setTermsCheck(true);
    }
  }
  function isLoader(loader1,loader2){
    return loader1 || loader2;
  }
  function isInvalid(btnPress,data){
    return btnPress && !validateMessage(data)?"is-invalid":"";
  }
  function isInvalidEmail(btnPress,emailData){
    return btnPress && !validateEmail(emailData)?"is-invalid":"";
  }

  return (
    <>
      <SuccessPopUp loader={isLoader(loading,sessionloading)} errorMessage={errorMessage} />
      <div className="payment-container">
        <div className="container ">
          <div className="cart-product-heading   mb-4">
            <div className="cart-heading ">
              <h1>Billing Address Information</h1>
            </div>
            <div className="cart-hediing-right">
              <p>
                <Link className=" heading-link-prev" id="as-home" to="/home">
                  Home
                </Link>{" "}
                <FaAngleRight className="right-angle-icon" />
                <Link
                  className=" heading-link-prev"
                  id="as-cart"
                  onClick={() => (window.location.href = "/enrichCart")}
                >
                  Cart
                </Link>
                <FaAngleRight className="right-angle-icon" />
                <span className="address-information-span">Address Information</span>
              </p>
            </div>
          </div>

          <div className="payment-information shadow-sm">
            <div className="row  gy-5">
              <div className="organization-name col-md-4">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  Organization Name<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"                  
                  className={`form-control ${isInvalid(isSavePress,userInfo.organizationName)}`}
                  id="as-exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={userInfo.organizationName}
                  onChange={(e) =>
                    setUserInfo((prevUserInfo) => ({
                      ...prevUserInfo,
                      organizationName: e.target.value,
                    }))
                  }
                  disabled={
                    validateMessage(userDetails) &&
                    validateMessage(userDetails.id)
                  }
                />
                  <div className="invalid-feedback">
                    Please enter Organization field.
                  </div>
              </div>
              <div className="firstName col-md-4 col-sm-6">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  First Name<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${isInvalid(isSavePress,userInfo.firstName)}`}
                  id="as-exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={userInfo.firstName}
                  onChange={(e) =>
                    setUserInfo((prevUserInfo) => ({
                      ...prevUserInfo,
                      firstName: e.target.value,
                    }))
                  }
                />
                  <div className="invalid-feedback">
                    Please enter First Name field.
                  </div>
              </div>
              <div className="lastName col-md-4 col-sm-6">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  Last Name<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${isInvalid(isSavePress,userInfo.lastName)}`}
                  id="as-exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={userInfo.lastName}
                  onChange={(e) =>
                    setUserInfo((prevUserInfo) => ({
                      ...prevUserInfo,
                      lastName: e.target.value,
                    }))
                  }
                />
                  <div className="invalid-feedback">
                    Please enter Last Name field.
                  </div>
              </div>
              <div className="phone-number col-md-4 col-sm-6">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  Phone Number<span className="text-danger"> *</span>
                </label>
                <PhoneInput
                  className={`${isInvalid(isSavePress,userInfo.phoneNumber)}`}
                  country={"us"}
                  id="as-phone-number"
                  value={userInfo.phoneNumber}
                  onChange={(e) => handlePhonenumberOnChange(e)}
                />
                <div className="invalid-feedback">
                  Please enter Phone Number.
                </div>
              </div>
              <div className="email col-md-4 col-sm-6">
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  Email<span className="text-danger"> *</span>
                </label>
                <input
                  type="email"
                  className={`form-control ${isInvalidEmail(isSavePress,userInfo.email)}`}
                  id="as-exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={userInfo.email}
                  onChange={(e) => handleEmailOnChange(e)}
                />
                  <div className="invalid-feedback">
                    Please enter valid Email Address.
                  </div>
              </div>
              <div className="addressinfo col-md-8 col-sm-6">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  Address<span className="text-danger"> *</span>
                </label>
                <input
                  className={`form-control ${isInvalid(isSavePress,userInfo.address)}`}
                  value={userInfo.address}
                  id="as-address"
                  onChange={(e) =>
                    setUserInfo((prevUserInfo) => ({
                      ...prevUserInfo,
                      address: e.target.value,
                    }))
                  }
                />
                <div className="invalid-feedback">
                  Please enter Address field.
                </div>
              </div>
              <div className="country col-md-4 col-sm-6">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  Country<span className="text-danger"> *</span>
                </label>
                <select
                  className={`form-select  countrySelect ${isInvalid(isSavePress,userInfo.country)}`}
                  id="as-country"
                  value={userInfo.country}
                  onChange={(e) => changeHandler(e)}
                >
                  {!validateMessage(userInfo.country) && <option selected value="">
                    Please Select Country
                  </option>}
                  {validateMessage(countryList) &&
                    countryList.map((countrie, index) => (
                      <option value={countrie} selected={userInfo.country===countrie} key={index}>{countrie}</option>
                    ))}
                </select>
                  <div className="invalid-feedback">
                    Please select Country field
                  </div>
              </div>
              <div className="state col-md-4 col-sm-6">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  State<span className="text-danger"> *</span>
                </label>
                <select
                  className={`form-select selectpicker ${isInvalid(isSavePress,userInfo.state)}`}
                  id="as-state"
                  aria-label="Default select example"
                  value={userInfo.state}
                  data-live-search="true"
                  onChange={(e) =>
                    setUserInfo((prevUserInfo) => ({
                      ...prevUserInfo,
                      state: e.target.value,
                    }))
                  }
                >
                  {!validateMessage(userInfo.state) && <option selected value="">
                    Please Select state
                  </option>}          
                  {validateMessage(stateList) &&
                    stateList.map((state, index) => (
                      <option value={state} selected={userInfo.state===state} key={index}>{state}</option>
                    ))}
                </select>
                  <div className="invalid-feedback">
                    Please select State field
                  </div>
              </div>
              <div className="city col-md-4 col-sm-6">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  City<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${isInvalid(isSavePress,userInfo.city)}`}
                  id="as-exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={userInfo.city}
                  onChange={(e) =>
                    setUserInfo((prevUserInfo) => ({
                      ...prevUserInfo,
                      city: e.target.value,
                    }))
                  }
                />
                  <div className="invalid-feedback">
                    Please select City field
                  </div>
              </div>              
              <div className="zipcode col-md-4 col-sm-6">
                {" "}
                <label htmlFor="as-exampleInputEmail1" className="form-label">
                  Zip Code<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${isInvalid(isSavePress,userInfo.zipCode)}`}
                  id="as-exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={userInfo.zipCode}
                  onChange={(e) =>
                    setUserInfo((prevUserInfo) => ({
                      ...prevUserInfo,
                      zipCode: e.target.value,
                    }))
                  }
                />
                  <div className="invalid-feedback">
                    Please enter Zip Code field.
                  </div>
              </div>
            </div>
              <div className="condition-checkbox mt-5 text-end">
                      <input className="form-check-input" type="checkbox" checked={termsCheck} id="as-flexCheckIndeterminate" onClick={handleCheckBox} />
                      <span onClick={handleCheckBox} htmlFor="as-flexCheckIndeterminate">I agree with the</span> <a href="/terms-services" target="_blank"className="text-reset"> terms and conditions. </a>
                    </div>
            <hr></hr>
            <div className="cart-payment-btn mt-4">
              <Link
                className="btn back-to-cart"
                id="as-cart"
                onClick={() => (window.location.href = "/enrichCart")}
              >
                <div className="returncart-arrow">
                  <FaRegArrowAltCircleLeft className="roundArrow" />
                  Return to Cart
                </div>
              </Link>
              <button
                className="btn continue-payment"
                id="as-continue-payment"
                disabled={termsCheck===null || termsCheck===false}
                onClick={handleContinuePaymentOnClick}
              >
                Continue to Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}