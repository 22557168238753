import { put, takeEvery, call } from "redux-saga/effects";
import { FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS } from "./ActionType";
import { postCall } from "../../../apihelper/ApiHelper";
import { FORGOT_PASSWORD_CONSTANT } from "../../../constants/ApiEndPoints";

export function* forgotPassword(action) {
    try {
        const response = yield call(postCall, `${FORGOT_PASSWORD_CONSTANT}${action.payload}`);
        yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: FORGOT_PASSWORD_FAILURE, payload: error });
    }
}

function* ForgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword);
}

export default ForgotPasswordSaga;