import React from "react";
import EmptyImg from "../enrichImages/Screenshot 2023-10-04 110640.png";
import "./myaccount.css";

export default function EmptyMyAccount() {
  return (
    <div className="EmptyMyAccount-container">
      <div className="card mb-3">
        <div className=" Account-empty-details shadow">
          <div className=" text-end ">
            <img src={EmptyImg} className="img-fluid rounded-start" alt="empty-image" />
          </div>
          <div className=" text-start emptyMyAcount-right">
            <div className="card-body  myAccount-text">
              <p className="card-text">No Details Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
