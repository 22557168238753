const API_URL = process.env.REACT_APP_API;



export async function handleResponse(response) {
  if (response.status === 200) return response.json();
  throw await response.json();
}

export async function handleResponseSampleDownload(response) {  
  if (response.status === 200) return response;
  throw await response;
}

async function makeRequest(url, requestOptions) {
  return fetch(API_URL + url, requestOptions).then(handleResponse);
}

async function makeRequestSampleDownload(url, requestOptions) {
  return fetch(API_URL + url, requestOptions).then(handleResponseSampleDownload);
}

export async function getCall(url) {
  const requestOptions = {
    headers: getHeaders(url),
  };
  return makeRequest(url, requestOptions);
}
export async function getCallSampleDownload(url) {
  const requestOptions = {
    headers: getHeaders(url),
  };
  return makeRequestSampleDownload(url, requestOptions);
}

export async function postCall(url, body) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(url),
    body: JSON.stringify(body),
  };
  return makeRequest(url, requestOptions);
}

export async function deleteCall(url, body) {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(url),
    body: JSON.stringify(body),
  };
  return makeRequest(url, requestOptions);
}

export async function putCall(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: getHeaders(url),
    body: JSON.stringify(body),
  };
  return makeRequest(url, requestOptions);
}


export function getHeaders(url) {
  let headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "https://shop.enrich.io",
  };
  if (!url.includes("account/") || !url.includes("open/")) {
    try {
      headers.accessToken = JSON.parse(localStorage.getItem("authUser")).JWT;
    } catch (e) { }
  }

  return headers;
}
