import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADDTO_WISHLISTCART,
  ADDTO_WISHLISTCART_SUCCESS,
  DECREASE_CARTQUENTITY,
  DECREASE_CARTQUENTITY_FAIL,
  DECREASE_CARTQUENTITY_SUCCESS,
  DECREASE_WISHLISTQUENTITY,
  DECREASE_WISHLISTQUENTITY_FAIL,
  DECREASE_WISHLISTQUENTITY_SUCCESS,
  DELETE_WISHLISTCART,
  DELETE_WISHLISTCART_FAIL,
  DELETE_WISHLISTCART_SUCCESS,
  FETCH_CART,
  FETCH_CART_FAIL,
  FETCH_CART_SUCCESS,
  FETCH_WISHLIST,
  FETCH_WISHLISTCART_COUNT,
  FETCH_WISHLISTCART_COUNT_SUCCESS,
  FETCH_WISHLIST_FAIL,
  FETCH_WISHLIST_SUCCESS,
  INCREASE_CARTQUENTITY,
  INCREASE_CARTQUENTITY_FAIL,
  INCREASE_CARTQUENTITY_SUCCESS,
  INCREASE_WISHLISTQUENTITY,
  INCREASE_WISHLISTQUENTITY_FAIL,
  INCREASE_WISHLISTQUENTITY_SUCCESS,
} from "./ActionType";
import {
  deleteCall,
  getCall,
  postCall,
  putCall,
} from "../../apihelper/ApiHelper";
import {
  ADDTO_WISHLISTORCART,
  DELETE_WISHLISTORCART,
  GET_CARTPRODUCTS,
  GET_WISHLISTPRODUCTS,
  GET_WISHLIST_CARTCOUNT,
} from "../../constants/ApiEndPoints";

function* addToWishList(action) {
  try {
    const response = yield call(postCall, ADDTO_WISHLISTORCART, action.payload);
    yield put({ type: ADDTO_WISHLISTCART_SUCCESS, payload: response.message });
  } catch (error) {
    yield put({ type: ADDTO_WISHLISTCART_SUCCESS, payload: error.message });
  }
}

function* deleteFromWishListCart(action) {
  try {
    const response = yield call(
      deleteCall,
      DELETE_WISHLISTORCART,
      action.payload
    );
    yield put({ type: DELETE_WISHLISTCART_SUCCESS, payload: response.message });
  } catch (error) {
    put({ type: DELETE_WISHLISTCART_FAIL, payload: error.message });
  }
}

function* fetchWishlistItem() {
  try {
    const response = yield call(
      getCall,
      `${GET_WISHLISTPRODUCTS}`
    );
    yield put({ type: FETCH_WISHLIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: FETCH_WISHLIST_FAIL, payload: error.message });
  }
}

function* fetchWishListCountAndCartCount(action) {
  try {
    const response = yield call(
      getCall,
      `${GET_WISHLIST_CARTCOUNT}`
    );
    yield put({ type: FETCH_WISHLISTCART_COUNT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: FETCH_WISHLISTCART_COUNT_SUCCESS, error: error.message });
  }
}

function* fetchCartItems() {
  try {
    const response = yield call(getCall, GET_CARTPRODUCTS);

    yield put({ type: FETCH_CART_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: FETCH_CART_FAIL, error: error.message });
  }
}

function* increaseWishListQuentity(action) {
  try {
    const response = yield call(putCall, `wishlist/${action.payload}/increase`);
    yield put({
      type: INCREASE_WISHLISTQUENTITY_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    yield put({
      type: INCREASE_WISHLISTQUENTITY_FAIL,
      payload: error.message,
    });
  }
}

function* increaseCartQuentity(action) {
  try {
    const response = yield call(putCall, `cart/${action.payload}/increase`);
    yield put({
      type: INCREASE_CARTQUENTITY_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    yield put({
      type: INCREASE_CARTQUENTITY_FAIL,
      payload: error.message,
    });
  }
}

function* decreaseCartQuentity(action) {
  try {
    const response = yield call(putCall, `cart/${action.payload}/decrease`);
    yield put({
      type: DECREASE_CARTQUENTITY_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    yield put({ type: DECREASE_CARTQUENTITY_FAIL, payload: error.message });
  }
}

function* decreaseWishListQuentity(action) {
  try {
    const response = yield call(putCall, `wishlist/${action.payload}/decrease`);
    yield put({
      type: DECREASE_WISHLISTQUENTITY_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    yield put({ type: DECREASE_WISHLISTQUENTITY_FAIL, payload: error.message });
  }
}

function* WishlistcartSaga() {
  yield takeEvery(ADDTO_WISHLISTCART, addToWishList);
  yield takeEvery(FETCH_WISHLIST, fetchWishlistItem);
  yield takeEvery(DELETE_WISHLISTCART, deleteFromWishListCart);
  yield takeEvery(FETCH_WISHLISTCART_COUNT, fetchWishListCountAndCartCount);
  yield takeEvery(FETCH_CART, fetchCartItems);
  yield takeEvery(INCREASE_WISHLISTQUENTITY, increaseWishListQuentity);
  yield takeEvery(INCREASE_CARTQUENTITY, increaseCartQuentity);
  yield takeEvery(DECREASE_WISHLISTQUENTITY, decreaseWishListQuentity);
  yield takeEvery(DECREASE_CARTQUENTITY, decreaseCartQuentity);
}

export default WishlistcartSaga;
