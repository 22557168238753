import React, { useEffect, useState } from "react";
import "./wishlist.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ADDTO_WISHLISTCART,
  DELETE_WISHLISTCART,
  FETCH_WISHLIST,
  FETCH_WISHLISTCART_COUNT,
} from "../../store/wishlistAndCart/ActionType";
import { VscDash } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";
import PopUp from "../PopUp/SuccessPopUp";
import { CREATE_SUBSCRIPTION_REQUEST } from "../../store/payment/ActionTypes";
import { RxCrossCircled } from "react-icons/rx";
import { validateMessage } from "../../validationhelper/ValidationHelper";

export default function WishList() {
  document.title = "Wishlist - www.enrich.io";
  const [checkedProducts, setCheckProducts] = useState([]);
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.wishlistcart?.wishlisItem);
  const [finalProducts, setFinalProducts] = useState([]);
  const error = useSelector((state) => state.wishlistcart?.error);
  const response = useSelector((state) => state.wishlistcart?.message);
  const loading = useSelector((state) => state.wishlistcart?.loading);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const uId = useSelector((state) => state.paymentInfo?.uId);
  const paymentLoader = useSelector((state) => state.paymentInfo?.loading);
  const [isDeleteSelect, setIsDeleteSelect] = useState(false);
  const [proudctIdToDelete, setProudctIdToDelete] = useState(0);
  const user = localStorage.getItem("JWT");
  const [isAddedToCart, setIsAddedToCart] = useState(false);

  useEffect(() => {
    dispatch({ type: FETCH_WISHLIST });
    dispatch({ type: FETCH_WISHLISTCART_COUNT });
  }, [dispatch]);

  useEffect(() => {
    if (uId !== null) {
      navigate(`/payment?uId=${uId}`);
    }
  }, [uId]);

  useEffect(() => {
    if (validateMessage(cartProducts) && cartProducts.length > 0) {
      let products = cartProducts.map((item) => {
        return { ...item, TotalProductPrice: item.price * item.quentity };
      });
      setFinalProducts(products);
    } else if (!validateMessage(user) || validateMessage(cartProducts) && cartProducts.length < 1) {
      navigate("/wishlist!");
    }
  }, [cartProducts]);

  useEffect(() => {
    if (isAddedToCart && response !== null && response === "ADDED") {
      navigate("/enrichCart");
    }
  }, [response]);

  useEffect(() => {
    if (error && response && validateMessage(response.message)) {
      setErrorMessage(response.message);
    }
    dispatch({ type: FETCH_WISHLIST });
  }, [error, response, dispatch]);

  const onChangeCheckedProducts = (productId) => {
    if (checkedProducts.includes(productId)) {
      let updatedProduct = checkedProducts.filter(
        (eachProduct) => eachProduct !== productId
      );

      setCheckProducts(updatedProduct);
    } else {
      setCheckProducts([...checkedProducts, productId]);
    }
  };

  const handleSelectDeleteOnClick = () => {
    setIsDeleteSelect(false);
    dispatch({
      type: DELETE_WISHLISTCART,
      payload: { productId: checkedProducts, type: "WISHLIST" },
    });
    setCheckProducts([]);
  };

  const handleWishlistToCartOnClick = () => {
    setIsAddedToCart(true);
    dispatch({
      type: ADDTO_WISHLISTCART,
      payload: { productId: checkedProducts, type: "CART", quentity: 1 },
    });
  };

  const handleDeleteOnClick = () => {
    if (checkedProducts.includes(proudctIdToDelete)) {
      let updatedProduct = checkedProducts.filter(
        (eachProduct) => eachProduct !== proudctIdToDelete
      );

      setCheckProducts(updatedProduct);
    }
    dispatch({
      type: DELETE_WISHLISTCART,
      payload: { productId: [proudctIdToDelete], type: "WISHLIST" },
    });
    dispatch({ type: FETCH_WISHLIST });
  };

  const handleAddCartOnClick = (productId) => {
    dispatch({
      type: ADDTO_WISHLISTCART,
      payload: { productId: [productId], type: "CART", quentity: 1 },
    });
  };

  const handleBuyNowOnClick = (productId) => {
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
      payload: { productIds: [productId] },
    });
  };

  const hanldeSelectBuyNowOnClick = () => {
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
      payload: { productIds: checkedProducts },
    });
  };
  return (
    <>
      <PopUp
        loader={loading || paymentLoader}
        successMessage={""}
        errorMessage={errorMessage}
      />
      <div className="wishlist-container pt-4">
        <div className="container">
          <h3 className="mb-4 mt-5">WISHLIST</h3>
          <div className="wishlist-selected-product">
            {checkedProducts.length > 0 && (
              <div className="cart-visible-content">
                <div className="cart-selected-Order">
                  <div className="select-products-details">
                    <VscDash className="remove-item" /> {checkedProducts.length}
                    /{finalProducts.length} ITEMS SELECTED
                  </div>
                  <div className="delete-cart-buy">
                    <Link className="deleteFromCart" id="as-delete-from-cart">
                      <MdDelete
                        className="mycart-delete"
                        onClick={() => setIsDeleteSelect(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#modelForDelete"
                      />
                    </Link>
                    <button
                      className="wishlist-add-to-cart btn btn-sm mx-2"
                      id="as-add-to-cart"
                      onClick={handleWishlistToCartOnClick}
                    >
                      ADD TO CART
                    </button>
                    <Link
                      id="as-buy-it-now"
                      className="wishlist-Buy btn btn-sm"
                      onClick={hanldeSelectBuyNowOnClick}
                    >
                      BUY IT NOW
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <div
              className="modal fade modal-for-delete"
              id="modelForDelete"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"              
              data-bs-backdrop="static"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close make-me-invisible"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="as-close-modal-btn"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body text-center">
                    <RxCrossCircled className="react-cross-icon " />
                    <h2>Delete</h2>
                    <p>Are you sure you want to delete this item?</p>
                  </div>
                  <div className="modal-footer buttons-for-delete-confirm">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="as-close-modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      id="as-confirm-btn"
                      className="btn btn-success"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={
                        isDeleteSelect === true
                          ? handleSelectDeleteOnClick
                          : handleDeleteOnClick
                      }
                    >
                      Yes, Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="wishlist-items mt-4 pb-5">
              {finalProducts.map((product, index) => (
                <div className="wishlist-products mb-3 shadow">
                  <div className="wishlist-item-left">
                    <div className=" wishlist-product-select product-select form-check mx-2">
                      <input
                        className="form-check-input m-0"
                        type="checkbox"
                        onChange={() => {
                          onChangeCheckedProducts(product.productId);
                        }}
                        value={checkedProducts.includes(product.productId)}
                        id="as-flexCheckIndeterminate"
                        checked={checkedProducts.includes(product.productId)}
                      />
                    </div>
                    <img
                      className="wishlist-product-img mx-2"
                      src={product.url}
                      alt="product-img"
                      id="as-product-details"
                      onClick={() =>
                        navigate(`/product?id=${product.productId}`)
                      }
                    ></img>
                    <div className="wishlist-product-and-links mx-4">
                      <h6 className="wishlist-product-name">{product.name}</h6>
                      <div>
                        <button
                          className=" btn btn-link product-action delete-action"
                          id="as-delete-porduct-btn"
                          onClick={() =>
                            setProudctIdToDelete(product.productId)
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#modelForDelete"
                        >
                          Delete{" "}
                        </button>
                        <span className="product-action-span">|</span>
                        <button
                          className=" btn btn-link product-action"
                          id="as-add-to-cart"
                          onClick={() =>
                            handleAddCartOnClick(product.productId)
                          }
                        >
                          Add to Cart
                        </button>
                        <span className="product-action-span">|</span>
                        <button
                          className=" btn btn-link product-action"
                          id="as-but-it-now-btn"
                          onClick={() => handleBuyNowOnClick(product.productId)}
                        >
                          Buy it Now
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="wishlist-item-right">
                    <div className="wishlist-product-totalprice">
                      ${product.price}                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
