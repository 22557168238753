import { GOOGLEAUTHENTICATIONFAILURE, GOOGLEAUTHENTICATIONREQUEST, GOOGLEAUTHENTICATIONSUCCESS, } from "./ActionType";



const initialState = {
    error: false,
    loading: false,
    response: "",
    callType: ""
};

 const GoogleCallBackReducer = (state = initialState, action=action) => {
    switch (action.type) {
        case GOOGLEAUTHENTICATIONREQUEST:
            return { ...state, error: false, loading: true }
        case GOOGLEAUTHENTICATIONSUCCESS:
            return { ...state, error: false, loading: false, response: action.payload };
        case GOOGLEAUTHENTICATIONFAILURE:
            return { ...state, error: true, loading: false, response: action.payload };
        default: return state;
    }
};
export default GoogleCallBackReducer;