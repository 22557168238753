import React, { useEffect, useState } from "react";
import "./footer.css";
import logo from "../enrichImages/logo.png";
import { FaPhone } from "react-icons/fa";
import { AiOutlineArrowUp } from "react-icons/ai";

export default function Footer() {
  const [isTermsPage, setIsTermsPage] = useState(false);
  const [isPrivacyPage, setIsPrivacyPage] = useState(false);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("privacy-page")) {
      setIsPrivacyPage(true);
    }
    if (currentUrl.includes("terms-services")) {
      setIsTermsPage(true);
    }
  });

  return (
    <div className="footer">
      <div className="footer-btm">
        <div className="container">
          <div className="btm-content">
            <div className=" row text-white">
              <div className="logo col-md-4 ">
                <div className="footer-logo mb-4 mt-2">
                  <img src={logo} alt="enrich-logo"></img>
                </div>
                <div className="my-4 enrich-information">
                  We help ATS/CRM/Enterprises find the right talent with our
                  comprehensive taxonomy
                </div>

                <div className="contact-info">
                  <div className="phone-icon">
                    <FaPhone className="phone" />
                  </div>
                  <div className="contact">
                    <h5>CALL US FREE</h5>
                    <h5>(888) 345 6789</h5>
                  </div>
                </div>
              </div>

              <div className="legal-info col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase  mb-4">
                  <b>Legal information</b>
                </h6>
                <div className="mb-1">
                  <a
                    href="/terms-services"
                    target={`${!isTermsPage ? "_blank" : ""}`}
                    className="text-reset"
                    id="as-terms-page-link"
                  >
                    Terms of Service
                  </a>
                </div>
                <div>
                  <a
                    href="/privacy-page"
                    target={`${!isPrivacyPage ? "_blank" : ""}`}
                    className="text-reset"
                    id="as-privacy-page-link"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>

              <div className="address col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase mb-4">
                  <b>Address</b>
                </h6>
                <p className="mb-4">
                  325 Front St W, Suite 300, Toronto, ON M5V 2Y1 Canada
                </p>
                <h6 className="text-uppercase  ">
                  <b>Email Us</b>
                </h6>
                <a
                  href="mailto:support@enrich.io"
                  className="email-link text-reset"
                  id="as-email-id"
                >
                  support@enrich.io
                </a>
              </div>
            </div>
            <div className="up-arrow">
              <a href="#enrichheader">
                <AiOutlineArrowUp className="arrow" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-rights mt-2">
          <div className="container">
            <div className=" row">
              <div className="footer-enrich-rights col-sm-12 py-3">
                ©2024 | enrich.io. All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
