import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./shop.css";
import { MdShoppingBag } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { TfiMenuAlt } from "react-icons/tfi";
import { BiShoppingBag, BiShowAlt } from "react-icons/bi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { FETCH_PRODUCT, RESET_PRODUCT } from "../../store/product/ActionTypes";
import { VscDash } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
import {
  ADDTO_WISHLISTCART,
  ADDTO_WISHLISTCART_SUCCESS,
  DELETE_WISHLISTCART,
  DELETE_WISHLISTCART_SUCCESS,
} from "../../store/wishlistAndCart/ActionType";
import PopUp from "../PopUp/SuccessPopUp";
import { validateMessage } from "../../validationhelper/ValidationHelper";
import no_products from "../enrichImages/no-product-found.png"

export default function Shop() {
  document.title = "Shop - www.enrich.io";

  const dispatch = useDispatch();
  const products = useSelector((state) => state.product?.products);
  const [active, setActive] = useState(1);
  const [checkedProducts, setCheckProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [sortBy, setSortBy] = useState("name");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [wishitems, setWishItems] = useState([]);
  const [cartitems, setCartItems] = useState([]);
  const [addwishListActive, setAddWishListActive] = useState([]);
  const [addCartActive, setAddCartActive] = useState([]);
  const [type, setType] = useState();
  const navigate = useNavigate();
  const [isClick, setIsClick] = useState(false);
  const name = JSON.parse(localStorage.getItem("authUser"));
  const [errorMessage, setErrorMessage] = useState("");
  const message = useSelector((state) => state.wishlistcart.message);
  const error = useSelector((state) => state.wishlistcart.error);
  const autoLoading = useSelector((state) => state.product.loading);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(validateMessage(products) && products.length>0){
      const wishlistedProductIds = products
      .filter((product) => product.wishlisted === true)
      .map((product) => product.productId);
    setAddWishListActive(wishlistedProductIds);

    const CartProductIds = products
      .filter((product) => product.inCart === true)
      .map((product) => product.productId);
    setAddCartActive(CartProductIds);
    }
  }, [products]);

  useEffect(() => {
    if (message === "ADDED") {
      if (type === "WISHLIST" && isClick === true) {
        navigate("/wishlist");
      } else if (type === "CART" && isClick === true) {
        navigate("/enrichCart");
      }
    }
  }, [dispatch, message, type]);

  useEffect(() => {
    dispatch({
      type: FETCH_PRODUCT,
      payload: {
        sortBy: sortBy,
        sortOrder: sortOrder,
      },
    });
  }, []);

  useEffect(() => {
    if (error && validateMessage(message) && validateMessage(message.message)) {
      setLoading(false);
      setErrorMessage(message.message);
    }
    if (!error && validateMessage(message)) {
      setLoading(false);
    }
  }, [message]);

  const handleOnChangeSort = (event) => {
    dispatch({ type: RESET_PRODUCT });
    const value = event.target.value.split(",");
    setSortBy(value[0]);
    setSortOrder(value[1]);
    dispatch({
      type: FETCH_PRODUCT,
      payload: {
        sortBy: value[0],
        sortOrder: value[1],
      },
    });
  };

  const onChangeCheckedProducts = (productId, price) => {
    if (checkedProducts.includes(productId)) {
      setTotalPrice(totalPrice - price);
      let updatedProduct = checkedProducts.filter(
        (eachProduct) => eachProduct !== productId
      );
      const removeItem1 = wishitems.filter((id) => id !== productId);
      const removeItem2 = cartitems.filter((id) => id !== productId);
      setWishItems(removeItem1);
      setCartItems(removeItem2);
      setCheckProducts(updatedProduct);
    } else {
      setTotalPrice(totalPrice + price);
      setCheckProducts([...checkedProducts, productId]);
      setWishItems([...wishitems, productId]);
      setCartItems([...cartitems, productId]);
    }
  };

  function isLoggedIn(){
    if (!validateMessage(name)) {
      window.location.href = "/login";
    }
  }
 

function elseConditionForIsWishlictOrCart(productId, typeOf) {
  isLoggedIn();
  setType(typeOf);
  typeOf === "WISHLIST"
    ? setAddWishListActive([...addwishListActive, productId])
    : setAddCartActive([...addCartActive, productId]);

  const cartCount =
    typeOf === "CART" ? addCartActive.length + 1 : addCartActive.length;
  const wishListCount =
    typeOf === "WISHLIST"
      ? addwishListActive.length + 1
      : addwishListActive.length;

  dispatch({
    type: ADDTO_WISHLISTCART,
    payload: {
      productId: [productId],
      type: typeOf,
      cartCount: cartCount,
      wishListCount: wishListCount,
      message: "",
    },
  });
}
function isWishlistOrCart(productId, typeOf) {
  isLoggedIn();
  setType(typeOf);
  const updateActive =
    typeOf === "WISHLIST"
      ? addwishListActive.filter((id) => id !== productId)
      : addCartActive.filter((id) => id !== productId);

  typeOf === "WISHLIST"
    ? setAddWishListActive(updateActive)
    : setAddCartActive(updateActive);

  const cartCount =
    typeOf === "CART" ? addCartActive.length - 1 : addCartActive.length;
  const wishListCount =
    typeOf === "WISHLIST"
      ? addwishListActive.length - 1
      : addwishListActive.length;

  dispatch({
    type: DELETE_WISHLISTCART,
    payload: {
      productId: [productId],
      type: typeOf,
      cartCount: cartCount,
      wishListCount: wishListCount,
      message: "",
    },
  });

  dispatch({ type: DELETE_WISHLISTCART_SUCCESS, payload: null });
}

function handleSingleWishlistCartOnlcick(productId, typeOf) {
  isLoggedIn();
  setLoading(true);

  if (
    (typeOf === "WISHLIST" ? addwishListActive : addCartActive).includes(
      productId
    )
  ) {
    isWishlistOrCart(productId, typeOf);
  } else {
    elseConditionForIsWishlictOrCart(productId, typeOf);
  }

  setTimeout(() => {
    dispatch({
      type: FETCH_PRODUCT,
      payload: { sortBy: sortBy, sortOrder: sortOrder },
    });
  }, 1000);
}

const handleWishlistCartOnClick = (typeOf) => {
  isLoggedIn();
  setIsClick(true);
  setType(typeOf);
  if (typeOf === "WISHLIST") {
    let updatedItems = wishitems.filter(
      (id) => !addwishListActive.includes(id)
    );
    setWishItems(updatedItems);
  } else if (typeOf === "CART") {
    let updatedItems = cartitems.filter((id) => !addCartActive.includes(id));
    setCartItems(updatedItems);
  }
  dispatch({
    type: ADDTO_WISHLISTCART,
    payload: {
      productId: checkedProducts,
      type: typeOf,
      quentity: 1,
    },
  });
  dispatch({ type: ADDTO_WISHLISTCART_SUCCESS, payload: null });
};

  const handleCallBack = () => {
    setErrorMessage("");
  };
  return (
    <div className="shopcontainer">
      <PopUp
        loader={autoLoading || loading}
        successMessage={""}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="shopproducts container-lg">
        <div className="shop-product-heading row pt-4">
          <div className="col-md col-sm-6 col-6 product-heading ">
            <h1>Products</h1>
          </div>
          <div className="col-md col-sm-6 col-6  shift-heading mt-3">
            <p>
              <Link className="heading-link heading-link-prev" id="as-home" to="/home">
                Home
              </Link>
              <FaAngleRight className="right-angle-icon" />
              Products
            </p>
          </div>
        </div>
        <div className="shop-product-customize ">
          <div className="left">
            <select
              className="sort-select form-select-md "
              value={sortBy + "," + sortOrder}
              onChange={handleOnChangeSort}
              aria-label="Default select example"
            >
              <option value="featurePriority,DESC">Featured</option>
              <option value="sellCount,DESC">Best Selling</option>
              <option value="name,ASC">Alphabetically, A-Z</option>
              <option value="name,DESC">Alphabetically, Z-A</option>
              <option value="price,DESC">Price high-low</option>
              <option value="price,ASC">Price low-high</option>
              <option value="uploadDate,DESC">Date, new to old</option>
              <option value="uploadDate,ASC">Date, old to new</option>
            </select>
          </div>
          {checkedProducts.length > 0 && (
            <div className="visible-content mid">
              <div className="total-Order">
                <div className="select-products-details">
                  <VscDash className="remove-item" /> {checkedProducts.length}{" "}
                  ITEMS SELECTED <b>($ {totalPrice})</b>
                </div>
                <div className="cart-wishlist">
                  <Link id="as-add-to-cart" className="addtocart">
                    <BiShoppingBag
                      onClick={() => handleWishlistCartOnClick("CART")}
                      className="shop-cart"
                    />
                  </Link>
                  <Link className="addtowishlist" id="as-add-to-wishlist">
                    <AiOutlineHeart
                      onClick={() => handleWishlistCartOnClick("WISHLIST")}
                      className="shop-wishlist"
                    />
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="right">
            <Link>
              <BsFillGrid3X3GapFill
                className={`grid-align ${active === 1 ? "active" : ""}`}
                onClick={() => setActive(1)}
              />
              <TfiMenuAlt
                className={`full-grid-align ${active === 2 ? "active" : ""}`}
                onClick={() => setActive(2)}
              />
            </Link>
          </div>
        </div>

        {validateMessage(products) && autoLoading && loading && products.length<1 && <div className="empty-hot-product pt-4">
          <img src={no_products} alt=""/>
        </div>}

        <div className="product-list row mt-4">
          {active === 1 &&validateMessage(products) && products.length>0 &&
            products.map((product, index) => (
              <div
                className="shop-product col-lg-3 col-md-6 col-sm-6"
                key={index}
              >
                <div className="products-card card shadow-sm ">
                  <Link
                    id="as-product-details"
                    className="product-detail-show-link"
                    to={`/product?id=${product.productId}`}
                  >
                    <div className="product-img">
                      <img
                        src={product.url}
                        className="card-img-top"
                        alt="product image"
                      />

                      <div className="product-icons">
                        <Link className={`shop-img-wishlist wishlist`} id="as-wishlist" title="Add to wishlist">
                          <AiFillHeart
                            className={`heart shop-heart ${
                              product.wishlisted === true ? "active" : ""
                            }`}
                            onClick={() =>handleSingleWishlistCartOnlcick(product.productId,"WISHLIST")
                            }
                          />
                        </Link>
                        <Link 
                          title="Overview"
                          id="as-product-overview"
                          className="shop-img-search search"
                          to={`/product?id=${product.productId}`}
                        >
                          <BiShowAlt className="search" />
                        </Link>
                        <Link className="shop-img-cart cart" id="as-add-to-cart" title="Add to cart">
                          <MdShoppingBag
                            onClick={() =>handleSingleWishlistCartOnlcick(product.productId,"CART")
                            }
                            className={`bag ${
                              product.inCart === true ? "active" : ""
                            }`}
                          />
                        </Link>
                      </div>
                    </div>
                  </Link>
                  <div className="card-body">
                    <Link
                    id="as-cart"
                      className="card-title"
                      to={`/product?id=${product.productId}`}
                    >
                      {product.name}
                    </Link>
                    <div className="product-select form-check">
                      <h5 className="card-text">
                        <b>${product.price}</b>
                      </h5>
                      <input
                        className="form-check-input"
                        type="checkbox"                        
                        onChange={() => {
                          onChangeCheckedProducts(
                            product.productId,
                            product.price
                          );
                        }}
                        value={checkedProducts.includes(product.productId)}
                        id="as-flexCheckIndeterminate"
                        checked={checkedProducts.includes(product.productId)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {active === 2 && validateMessage(products) && products.length>0 &&
          products.map((product, index) => (
            <div className="full-card card shadow-sm p-0 mb-5" key={index}>
              <div className=" row g-0">
                <div className="grid-product col-4">
                  <Link
                    id="as-product-details"
                    className="product-detail-show-link"
                    to={`/product?id=${product.productId}`}
                  >
                    <div className="grid-product-img">
                      <img
                        src={product.url}
                        className="img-fluid rounded-start"
                        alt="product image"
                      />
                      <div className="grid-product-icons">
                        <Link className={`shop-img-wishlist wishlist`} id="as-add-to-wishlist">
                          <AiFillHeart
                            onClick={() =>handleSingleWishlistCartOnlcick(product.productId,"WISHLIST")}
                            className={`heart grid-icon ${
                              addwishListActive.includes(product.productId) ===
                              true
                                ? "active"
                                : ""
                            }`}
                          />
                        </Link>
                        <Link
                          id="as-search"
                          className="shop-img-search search"
                          to={`/product?id=${product.productId}`}
                        >
                          <BiShowAlt className="search  grid-icon" />
                        </Link>
                        <Link className="shop-img-cart cart" id="as-cart">
                          <MdShoppingBag
                            onClick={() =>
                              handleSingleWishlistCartOnlcick(
                                product.productId,
                                "CART"
                              )
                            }
                            className={`bag  grid-icon ${
                              addCartActive.includes(product.productId)
                                ? "active"
                                : ""
                            }`}
                          />
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-8 ">
                  <div className="full-card-content card-body">
                    <div className="select-title card-title">
                      <div>
                      <input
                        className="check form-check-input"
                        type="checkbox"
                        onChange={() => {
                          onChangeCheckedProducts(product.productId,product.price);
                        }}
                        value={checkedProducts.includes(product.productId)}
                        id="as-flexCheckIndeterminate"
                        checked={checkedProducts.includes(product.productId)}
                      ></input>
                      </div>
                      <div className="grid-card-title-price">
                        <Link
                          id="as-product-details"
                          className="card-title"
                          to={`/product?id=${product.productId}`}
                        >
                          {product.name}
                        </Link>
                        <div className="card-text">
                          <b>$ {product.price}</b>
                        </div>
                      </div>
                    </div>
                    <div className="card-body card-content">
                      <p className="card-text product-description">
                        {product.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
