import {
  CHECKOUT_SESSION_CREATION,
  CHECKOUT_SESSION_CREATION_FAIL,
  CHECKOUT_SESSION_CREATION_SUCCESS,
  CREATE_SUBSCRIPTION_FAIL,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESSFULL,
  GET_BIILLINGDETAILS,
  GET_BIILLINGDETAILS_FAIL,
  GET_BIILLINGDETAILS_SUCCESS,
  GET_COUNTRYREQUEST,
  GET_COUNTRYREQUEST_FAIL,
  GET_COUNTRYREQUEST_SUCCESS,
  GET_MODEOFDELIVERYSTATUS,
  GET_MODEOFDELIVERYSTATUS_SUCCESS,
  GET_STATEREQUEST,
  GET_STATEREQUEST_FAIL,
  GET_STATEREQUEST_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
  GET_USERDETAIL,
  GET_USERDETAIL_FAIL,
  GET_USERDETAIL_SUCCESS,
  SAVE_BILLINGINFO,
  SAVE_BILLINGINFO_FAIL,
  SAVE_BILLINGINFO_SUCCESS,
} from "./ActionTypes";

const initialState = {
  userDetails: null,
  error: null,
  errorMessage: null,
  message: null,
  stateList: null,
  countryList: null,
  loading: false,
  checkoutUrl: null,
  status: null,
  uId: null,
  emailDomainError: null,
  response: null,
  sessionloading:false,
};

function method1(stateData){
  return {
    ...stateData,
    loading: true,
  };
}
function method2(stateData,payloadData){
  return {
    ...stateData,
    error: payloadData,
    loading: false,
  };
}
function method3(stateData){
  return {
    ...stateData,
  };
}

const BillingInforeducer = (state = initialState, action=action) => {
  switch (action.type) {
    case GET_BIILLINGDETAILS:return method1(state);
    case GET_BIILLINGDETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case GET_BIILLINGDETAILS_FAIL:return method2(state,action.payload);
    case GET_USERDETAIL:return method1(state);
    case GET_USERDETAIL_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        errorMessage: false,
        loading: false,
      };
    case GET_USERDETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        errorMessage: true,
        loading: false,
      };

    case SAVE_BILLINGINFO:
      return {
        ...state,
        loading: true,
        emailDomainError: null,
      };
    case SAVE_BILLINGINFO_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case SAVE_BILLINGINFO_FAIL:
      return {
        ...state,
        emailDomainError: action.payload,
        message: null,
        loading: false,
      };
    case GET_STATEREQUEST:return method1(state);
    case GET_STATEREQUEST_SUCCESS:
      return {
        ...state,
        stateList: action.payload,
        loading: false,
      };
    case GET_STATEREQUEST_FAIL:return method2(state,action.payload);
    case GET_COUNTRYREQUEST:return method1(state);
    case GET_COUNTRYREQUEST_SUCCESS:
      return {
        ...state,
        countryList: action.payload,
        loading: false,
      };
    case GET_COUNTRYREQUEST_FAIL:
      return {
        ...state,
        erroe: action.payload,
        loading: false,
      };

    case CHECKOUT_SESSION_CREATION:
      return {
        ...state,
        sessionloading: true,
      };
    case CHECKOUT_SESSION_CREATION_SUCCESS:
      return {
        ...state,
        checkoutUrl: action.payload,
        sessionloading: false,
      };
    case CHECKOUT_SESSION_CREATION_FAIL:
      return {
        ...state,
        error: action.payload,
        sessionloading: false,
      };

    case GET_STATUS_REQUEST:
      return {
        ...state,
        status: null,
        loading: true,
      };
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        status: true,
        loading: false,
      };
    case GET_STATUS_FAIL:
      return {
        ...state,
        status: false,
        error: action.payload,
        loading: false,
      };

    case CREATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        uId: null,
      };
    case CREATE_SUBSCRIPTION_SUCCESSFULL:
      return {
        ...state,
        error: false,
        uId: action.payload,
        loading: false,
      };

    case CREATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: true,
        message: action.payload,
        loading: false,
        uId: null,
      };

    case GET_MODEOFDELIVERYSTATUS:return method3(state);
    case GET_MODEOFDELIVERYSTATUS_SUCCESS:
      return {
        ...state,
        response: action.payload,
      };

    default:return state;
  }
};
export default BillingInforeducer;