import { takeEvery, call, put } from 'redux-saga/effects';
import { FETCH_HOTPRODUCT, FETCH_HOTPRODUCT_FAIL, FETCH_HOTPRODUCT_SUCCESS, FETCH_ONEPRODUCT, FETCH_ONEPRODUCT_FAIL, FETCH_ONEPRODUCT_SUCCESS, FETCH_PRODUCT, FETCH_PRODUCT_FAIL, FETCH_PRODUCT_SUCCESS, FETCH_SAMPLE_DATA_FAILURE, FETCH_SAMPLE_DATA_REQUEST, FETCH_SAMPLE_DATA_SUCCESS } from './ActionTypes';
import { getCall, getCallSampleDownload } from "../../apihelper/ApiHelper";
import {
  DOWNLOAD_SAMPLE_DATA,
  GET_HOTPTODUCT,
  GET_ONEPRODUCT,
  GET_PRODUCT,
} from "../../constants/ApiEndPoints";

export function* fetchHotProduct(action) {
  try {
    const response = yield call(
      getCall,
      `${GET_HOTPTODUCT}`
    );
    yield put({ type: FETCH_HOTPRODUCT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: FETCH_HOTPRODUCT_FAIL, payload: error.message });
  }
}

export function* fetchProduct(action) {
  const sortOrder = action.payload.sortOrder;
  const sortBy = action.payload.sortBy;
  try {
    const response = yield call(
      getCall,
      `${GET_PRODUCT}?sortBy=${sortBy}&sortOrder=${sortOrder}`
    );
    yield put({ type: FETCH_PRODUCT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: FETCH_PRODUCT_FAIL, payload: error.message });
  }
}

export function* fetchOneProduct(action) {
  try {
    const response = yield call(
      getCall,
      `${GET_ONEPRODUCT}?productId=${action.payload.productId}`
    );
    yield put({ type: FETCH_ONEPRODUCT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: FETCH_ONEPRODUCT_FAIL, payload: error });
  }
}
export function* downloadSampleDataOfOneProduct(action) {
  try {
    const response = yield call(getCallSampleDownload,`${DOWNLOAD_SAMPLE_DATA}${action.product}`);
    if (response.Unauthorized ) {
      window.location.href="/login"
    }
    else if (!response.ok) {      
      yield put({type:FETCH_SAMPLE_DATA_FAILURE,payload:"There is currently no sample data available for the selected product"})
    }else{
      yield put({type:FETCH_SAMPLE_DATA_SUCCESS,payload:null})
      const blob = yield call([response, 'blob']);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', action.productName+".zip");
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    yield put({type:FETCH_SAMPLE_DATA_FAILURE,payload:"There is currently no sample data available for the selected product"})
  }
}

function* ProductSaga() {
  yield takeEvery(FETCH_HOTPRODUCT, fetchHotProduct);
  yield takeEvery(FETCH_PRODUCT, fetchProduct);
  yield takeEvery(FETCH_ONEPRODUCT, fetchOneProduct)
  yield takeEvery(FETCH_SAMPLE_DATA_REQUEST, downloadSampleDataOfOneProduct)
}

export default ProductSaga;
