import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import './registercomponent.css'
import { LoginSocialGoogle } from "reactjs-social-login";
import {
  GOOGLEAUTHENTICATIONFAILURE,
  GOOGLEAUTHENTICATIONREQUEST,
  GOOGLECLIENTID,
} from "../../store/account/thirdPartyIntegration/google/ActionType";
import {
  LINKEDINAUTHENTICATIONFAILURE,
  LINKEDINAUTHENTICATIONREQUEST,
  LINKEDINCLIENTID,
  LINKEDIN_REDIRECTURL_REGISTER,
} from "../../store/account/thirdPartyIntegration/linkedIn/ActionType";
import { Link } from "react-router-dom";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import { FaAngleRight } from "react-icons/fa6";
import {
  REGISTERFAILURE,
  REGISTERREQUEST,
  REGISTERSUCCESS,
} from "../../store/account/register/ActionType";
import RegisterPopUp from "./RegisterPopUp";
import {
  validateEmail,
  validateMessage,
} from "../../validationhelper/ValidationHelper";

const RegisterComponent = () => {
  document.title = "Register - www.enrich.io";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [checkbox, setCheckBox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [isSavePress, setIsSavePress] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState("");
  const [isPrivacyPage, setIsPrivacyPage] = useState(false);
  const dispatch = useDispatch();

  const registerError = useSelector((state) => state.register.error);
  const registerLoading = useSelector((state) => state.register.loading);
  const registerResponse = useSelector((state) => state.register.response);

  const googleRegisterResponse = useSelector((state) => state.google.response);
  const googleRegisterError = useSelector((state) => state.google.error);
  const googleRegisterLoading = useSelector((state) => state.google.loading);

  const linkedInRegisterResponse = useSelector((state) => state.linkedIn.response);
  const linkedInRegisterError = useSelector((state) => state.linkedIn.error);
  const linkedInRegisterLoading = useSelector((state) => state.linkedIn.loading);

  let code = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDINCLIENTID}&redirect_uri=${LINKEDIN_REDIRECTURL_REGISTER}&scope=email%20profile%20openid`;
  let urlCode = new URL(window.location.href).searchParams.get("code");

  const handleRegisterResponse = () => {
    if (registerResponse) {
      if (registerError) {
        setErrorMessage(registerResponse.message);
        dispatch({ type: REGISTERFAILURE, payload: null });
      } else {
        dispatch({ type: REGISTERSUCCESS, payload: null });
        setShowRegisterPopup(true);
      }
    }
  };
  
  useEffect(() => {
    handleRegisterResponse();
  }, [registerError, registerResponse]);

  const handleGoogleRegisterError = (error, response) => {
    if (error && response) {
      setErrorMessage(response.message);
      dispatch({ type: GOOGLEAUTHENTICATIONFAILURE, payload: null });
    }
  };
  
  useEffect(() => {
    handleGoogleRegisterError(googleRegisterError, googleRegisterResponse);
  }, [googleRegisterError, googleRegisterResponse]);

  const handleLinkedInRegisterError = () => {
    if (linkedInRegisterError && linkedInRegisterResponse) {
      setErrorMessage(linkedInRegisterResponse.message);
      urlCode = null;
      dispatch({ type: LINKEDINAUTHENTICATIONFAILURE, payload: null });
    }
  };
  
  useEffect(() => {
    handleLinkedInRegisterError();
  }, [linkedInRegisterError, linkedInRegisterResponse]);

  const registerButton = (event) => {
    event.preventDefault();
    setIsSavePress(true);
    if (validateEmail(email) && validateMessage(name)) {
      dispatch({
        type: REGISTERREQUEST,
        payload: { name: name, email: email},
      });
    }
  };

  const checkPrivacyPage = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("privacy-page")) {
      setIsPrivacyPage(true);
    }
  };
  
  useEffect(() => {
    checkPrivacyPage();
  }, []);

  const handleCallBack = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setShowRegisterPopup(false);
  };
  const handlePopUpCallBack = () => {
    setSuccessMessage("");
    setShowRegisterPopup(false);
  };

  const handleLinkedInCallback = (codeData) => {
    dispatch({type:LINKEDINAUTHENTICATIONREQUEST,payload:{code: codeData,callType: "register",redirectUrl: LINKEDIN_REDIRECTURL_REGISTER}});
  };
  
  useEffect(() => {
    if (urlCode !== null) {
      handleLinkedInCallback(urlCode);
      urlCode = null;
    }
  }, [urlCode]);
  

  const handleGoogleRegister = (provider, data) => {
    dispatch({type:GOOGLEAUTHENTICATIONREQUEST,payload:{accessToken:data.access_token,callType:"register"}}
    );
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    if (validateEmail(inputEmail)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  function validateData(btnPress,data){
    return btnPress && !validateMessage(data)?"is-invalid":"";
  }

  function handleCheckBox(){
    checkbox ? setCheckBox(false) : setCheckBox(true);
  }
  function handlePrivacyPage(){
    return !isPrivacyPage ? "_blank" : "";
  }

return (
    <div className="registerLoginContainer">
      <RegisterPopUp
        successMessage={showRegisterPopup}
        callback={handlePopUpCallBack}
      />
      <SuccessPopUp
        loader={
          googleRegisterLoading ||
          linkedInRegisterLoading ||
          registerLoading
        }
        successMessage={successMessage}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="container">
        <div className="loginRegisterContainer">
          <div className="register-navigator-line shift-heading">
            <Link className="home" to="/home">
              Home
            </Link>
            <FaAngleRight className="right-angle-icon" />
            Register
          </div>
          <form>
            <div>
              <p className="loginRegisterHead">REGISTER</p>
            </div>
            <div className="mb-3">
              <label
                className="form-label emailNameLabel"
              >
                {" "}
                Name<span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                placeholder="Enter Your Name"
                className={`form-control ${validateData(isSavePress,name)}`}
                aria-label="Username"
              />
              <div className="invalid-feedback">Name field is required.</div>
            </div>
            <div className="mb-3 ">
              <label
                className="form-label emailNameLabel"
              >
                Email Address<span className="text-danger"> *</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className={`form-control ${validateData(isSavePress,isValidEmail)}`}
                id="exampleInputEmail1"
                placeholder="Enter Corporate Email"
                aria-describedby="emailHelp"
              />
              <div className="invalid-feedback">Please enter valid email.</div>
            </div>
            <button
              type="submit"
              onClick={registerButton}
              className="btn custom-button"
            >
              Register
            </button>
            <hr />
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                onClick={handleCheckBox}
                checked={checkbox}
                id="privacyPolicyCheckbox"
              />
              <label
                className="form-check-label terms"
                htmlFor="privacyPolicyCheckbox"
              >
                By clicking any of the social login buttons you agree to the
                terms of <a
                  href="/privacy-page"
                  target={`${handlePrivacyPage}`}
                  className="privacyPolicy"
                >
                   Privacy Policy
                </a>
              </label>
            </div>
            <div className="buttonsContainer">              
              <button
                disabled={!checkbox}
                onClick={(event) => event.preventDefault()}
                className="socialBtn"
              >
                <LoginSocialGoogle
                  className="reactBtn"
                  client_id={GOOGLECLIENTID}
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  onResolve={({ provider, data }) => {
                    handleGoogleRegister(provider, data);
                  }}
                  onReject={(error) => {
                    if(validateMessage(error) && !validateMessage(error.data)){
                      setErrorMessage("Something went wrong, Please try again later");
                    }
                  }}
                >                  
                  <button
                    className="btn ggl-btn"
                    disabled={!checkbox}
                    type="button"
                  >
                    <span className="google-btn">
                      <i className="fab fa-google"></i>
                    </span>
                    Google
                  </button>
                </LoginSocialGoogle>
              </button>
              {checkbox ? (
                <a href={code} className="socialBtn" target="_blank">
                  <button className="btn li-btn" type="button">
                    <span className="linkedIn-btn">
                      <i className="fab fa-linkedin-in"></i>
                    </span>
                    LinkedIn
                  </button>
                </a>
              ) : (
                <a className="socialBtn socialBtn-li" target="_blank">
                  <button className="btn li-btn" type="button">
                    <span className="linkedIn-btn">
                      <i className="fab fa-linkedin-in"></i>
                    </span>
                    LinkedIn
                  </button>
                </a>
              )}
            </div>            
          </form>
        </div>
        <div className="account-exists">
          <h5 className="bottomLine mt-5">
            Already have an account ? {" "}
            <Link to="/login" className="registerLoginNow">
              Login Now
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
};
export default RegisterComponent;
