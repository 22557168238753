import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  validateEmail,
  validateMessage,
} from "../../validationhelper/ValidationHelper";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import "./contactus.css";
import { CONTACTUSFAILURE, CONTACTUSREQUEST, CONTACTUSSUCCESS } from "../../store/contactUs/ActionType";

const ContactUs = () => {
  document.title = "Contact Us - www.enrich.io";
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [isSavePress, setIsSavePress] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const error = useSelector((state) => state.contactUs.error);
  const loading = useSelector((state) => state.contactUs.loading);
  const response = useSelector((state) => state.contactUs.response);

  useEffect(() => {
    if (!error && response !== null && response !== undefined) {
      setSuccessMessage(response.message);
      dispatch({type:CONTACTUSSUCCESS,payload:null});
    } else if (error) {
      setErrorMessage(response.message);
      dispatch({type:CONTACTUSFAILURE,payload:null});
    }
  }, [response, error]);

  const handleCallBack = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleContactUs = () => {
    setIsSavePress(true);
    if (
      validateEmail(email) &&
      validateMessage(name) &&
      validateMessage(message)
    ) {
      dispatch({type:CONTACTUSREQUEST,payload:{name, email, message}})
    }
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    if (validateEmail(inputEmail)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  return (
    <div className="contactUsPage">
      <SuccessPopUp
        loader={loading}
        successMessage={successMessage}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="container">
        <p className="mb-4 contact-us-page-heading">Contact Us</p>
        <div className="card">
          <div className="addressDiv shadow-sm mb-4">
            <h3>Enrich Technologies Inc.</h3>
            <div className="address">
              325 Front St W, Toronto, ON, M5V 2Y1 Canada.
            </div>
            <div className="contactInfo">
              <a href="mailto:support@enrich.io" id="as-mail">support@enrich.io</a>
            </div>
          </div>
          <div className="row">
            <div className="nameAndEmail mb-4">
              <div className="mb-3 col-md-4 nameDiv">
                <label className="form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    isSavePress && !validateMessage(name) ? "is-invalid" : ""
                  }`}
                  id="as-user-name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Enter Your Name"
                  aria-label="Username"
                />
                <div className="invalid-feedback">Name field is required.</div>
              </div>
              <div className="col-md-4 emailDiv">
                <label className="form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className={`form-control ${
                    isSavePress && !isValidEmail ? 'is-invalid' : ""
                  }`}
                  value={email}
                  onChange={handleEmailChange}
                  id="as-exampleFormControlInput1"
                  placeholder="Enter Email"
                />
                <div className="invalid-feedback">
                  Please enter valid email.
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Message <span className="text-danger">*</span>
            </label>
            <textarea
              className={`form-control ${
                isSavePress && !validateMessage(message) ? 'is-invalid' : ""
              }`}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              id="as-exampleFormControlTextarea1"
              placeholder="Description"
              rows="3"
            ></textarea>
            <div className="invalid-feedback">
              Message field cannot be empty.
            </div>
          </div>
          <div className="sendButton">
            <button
              className="btn btn-info"
              onClick={handleContactUs}
              type="button"
              id="as-send"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
