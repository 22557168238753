import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import registerSuccessImg from "../enrichImages/registerSuccessImg.png";
import "./registercomponent.css";

const RegisterPopUp = (props) => {
  const verifyData = (data) => {
    return data !== undefined && data !== null && data !== "";
  };

  const [success, setSuccess] = useState(false);
  const [successMessage, setSucessMessage] = useState(null);

  useEffect(() => {
    if (props.loader) {      
      setSuccess(false);
    }    
  }, [props.loader]);

  useEffect(() => {
    if (verifyData(props.errorMessage)) {      
      setSuccess(false);      
    }
  }, [props.errorMessage]);

  useEffect(() => {
    if (verifyData(props.successMessage)) {      
      setSuccess(true);
      setSucessMessage(props.successMessage);
    }
  }, [props.successMessage]);

  const hideSuccessModel = () => {
    setSucessMessage(null);
    if (verifyData(props.callback)) {
      props.callback();
    }
    setSuccess(false);
  };
  return successMessage === false && successMessage !== null ? null : (
    <Modal
      backdrop="static"
      className="register-popup-container"
      keyboard={false}
      size="md modal-dialog-centered"
      show={success}
      onHide={() => hideSuccessModel()}
    >
      <Modal.Header className="border-bottom-0">
        <button
          className="closeButton btn-close"
          id="as-close-btn"
          onClick={() => hideSuccessModel()}
        >
          <i className="fa fa-close"></i>
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center modal-mid-text">
          <div className="imageContainer">
            <img
              src={registerSuccessImg}
              alt="Congratulations"
              className="popup-image"
            />
          </div>
          <p className="popup-message">
            <b>Congratulations! </b>Your account has been successfully created.
            We have sent an email to the address you provided. To verify your
            account and proceed further, please click on the link provided in
            the email. Thank you for joining us, and we look forward to serving
            you!
          </p>
        </p>
        <div
          className="text-center modal-btns"
          id="as-ok-btn"
          onClick={() => hideSuccessModel()}
        >
          <button className="btn btn-green" id="as-ok-btn" onClick={() => hideSuccessModel()}>
            OK
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterPopUp;
