export const VERIFICATIONFAILURE = "VERIFICATIONFAILURE"
export const VERIFICATIONSUCCESS = "VERIFICATIONSUCCESS"
export const VERIFICATIONREQUEST = "VERIFICATIONREQUEST"




export const IMPERSONATE_REQUEST = "IMPERSONATE_REQUEST"
export const IMPERSONATE_SUCCESS = "IMPERSONATE_SUCCESS"
export const IMPERSONATE_FAILURE = "IMPERSONATE_FAILURE"


