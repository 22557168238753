import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_HOTPRODUCT } from "../../store/product/ActionTypes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./hotproduct.css";
import { MdShoppingBag } from "react-icons/md";
import { AiFillHeart } from "react-icons/ai";
import { BiShowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { FaAngleDoubleRight } from "react-icons/fa";
import {
  ADDTO_WISHLISTCART,
  DELETE_WISHLISTCART,
  DELETE_WISHLISTCART_SUCCESS,
} from "../../store/wishlistAndCart/ActionType";
import { sliderConfig } from "../../constants/SliderConst";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import { validateMessage } from "../../validationhelper/ValidationHelper";
import no_products from "../enrichImages/no-product-found.png"

export default function HotProduct() {
  document.title = "Data Enrichment Made Simple - www.enrich.io";

  const dispatch = useDispatch();
  const products = useSelector((state) => state.product?.products);
  const [addwishListActive, setAddWishListActive] = useState([]);
  const [addCartActive, setAddCartActive] = useState([]);
  const error = useSelector((state) => state.wishlistcart.error);
  const message = useSelector((state) => state.wishlistcart.message);
  const [type, setType] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const autoloading = useSelector((state) => state.product.loading);
  const name=localStorage.getItem("authUser");
  const settings = sliderConfig;

  useEffect(() => {
    dispatch({ type: FETCH_HOTPRODUCT });        
  }, [dispatch]);

  useEffect(() => {
    if(validateMessage(products) && products.length>0){
    const wishlistedProductIds1 = products
      .filter((product) => product.wishlisted === true)
      .map((product) => product.productId);
    setAddWishListActive(wishlistedProductIds1);

    const wishlistedProductIds2 = products
      .filter((product) => product.inCart === true)
      .map((product) => product.productId);
    setAddCartActive(wishlistedProductIds2);
    }
  }, [products]);

  useEffect(() => {
    if (error && validateMessage(message) && (message!="ADDED" || message!="Removed")) {
      setLoading(false);
      setErrorMessage(message);
    }
    if (!error && validateMessage(message)) {
      setLoading(false);
    }
  }, [message]);

function isLoggedIn(){
  if (!validateMessage(name)) {
    window.location.href = "/login";
  }
}
function handleSingleWishlistCartOnlcick(productId, typeOf) {
  isLoggedIn();
  setLoading(true);

  const isAlreadyAdded = typeOf === "WISHLIST" ? addwishListActive.includes(productId) : addCartActive.includes(productId);

  if (isAlreadyAdded) {
    isWishlistOrCart(productId, typeOf);
  } else {
    elseConditionForIsWishlictOrCart(productId, typeOf);
  }

  setTimeout(() => {
    dispatch({ type: FETCH_HOTPRODUCT });
  }, 1000);
}

function isWishlistOrCart(productId, typeOf) {
  setType(typeOf);
  const updateActive = typeOf === "WISHLIST" ? addwishListActive.filter((id) => id !== productId) : addCartActive.filter((id) => id !== productId);
  typeOf === "WISHLIST" ? setAddWishListActive(updateActive) : setAddCartActive(updateActive);

  const cartCount = typeOf === "CART" ? addCartActive.length - 1 : addCartActive.length;
  const wishListCount = typeOf === "WISHLIST" ? addwishListActive.length - 1 : addwishListActive.length;

  dispatch({
    type: DELETE_WISHLISTCART,
    payload: {
      productId: [productId],
      type: typeOf,
      cartCount: cartCount,
      wishListCount: wishListCount,
      message: "",
    },
  });

  dispatch({ type: DELETE_WISHLISTCART_SUCCESS, payload: null });
}

function elseConditionForIsWishlictOrCart(productId, typeOf) {
  setType(typeOf);
  typeOf === "WISHLIST" ? setAddWishListActive([...addwishListActive, productId]) : setAddCartActive([...addCartActive, productId]);

  const cartCount = typeOf === "CART" ? addCartActive.length + 1 : addCartActive.length;
  const wishListCount = typeOf === "WISHLIST" ? addwishListActive.length + 1 : addwishListActive.length;

  dispatch({
    type: ADDTO_WISHLISTCART,
    payload: {
      productId: [productId],
      type: typeOf,
      cartCount: cartCount,
      wishListCount: wishListCount,
      message: "",
    },
  });
}


  const handleCallBack = () => {
    setErrorMessage("");
  };

  return (
    <div className="hotproduct">
      <div className="container">
        <SuccessPopUp
          loader={loading || autoloading}          
          errorMessage={errorMessage}
          callback={handleCallBack}
        />
        <div className="product-heading ">
          <div className="product-left">
            <span>Hot</span> Products
          </div>
          <div className="product-right">
            <Link className="shop-link product-right-text" id="as-shop-link" to="/shop">
              See More
              <FaAngleDoubleRight />
            </Link>
          </div>
        </div>
        {validateMessage(products)&& !loading && !autoloading && products.length<1 && <div className="empty-hot-product">
          <img src={no_products} alt=""/>
        </div>
}
        <div className="hotproduct-container">
          <div className="products">
            <Slider {...settings}>
              {validateMessage(products) && products.length>0 && products.map((product, index) => (
                <div className="product-container" key={index}>
                  <div className="products-card card">
                    <div className="product-img">
                      <Link
                        className="product-detail-show-link"
                        id="as-product-details"
                        to={`/product?id=${product.productId}`}
                        data-testid="product-card"
                      >
                        <img
                          src={product.url}
                          className="card-img-top"
                          alt="Product Image"
                        />
                      </Link>
                      <div className="product-icons">
                        <Link className="wishlist" id="as-wishlist" title="Add to wishlist">
                          <AiFillHeart
                            onClick={() =>
                              handleSingleWishlistCartOnlcick(
                                product.productId,
                                "WISHLIST"
                              )
                            }
                            data-testid="wishlist-icon"
                            className={`heart ${
                              product.wishlisted === true ? "active" : ""
                            }`}
                          />
                        </Link>
                        <Link
                          className="search" id="as-overview" title="Overview"
                          to={`/product?id=${product.productId}`}
                        >
                          <BiShowAlt className="search" />
                        </Link>
                        <Link className="cart" id="as-cart" title="Add to cart">
                          <MdShoppingBag
                            onClick={() =>
                              handleSingleWishlistCartOnlcick(
                                product.productId,
                                "CART"
                              )
                            }
                            data-testid="cart-icon"
                            className={`bag ${
                              product.inCart === true ? "active" : ""
                            }`}
                          />
                        </Link>
                      </div>
                    </div>

                    <div className="card-body truncate-text hot-product-card-body">
                      <Link
                        className="card-title"
                        id="as-card-title"
                        to={`/product?id=${product.productId}`}
                      >
                        {product.name}
                      </Link>
                      <h5 className="card-text">${product.price}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <div
              className="modal fade"
              id="wishlistCartStatusSuccess"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                  <button
                    type="button"
                    className="closebutton"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="as-modal-close"
                  >
                    <span className="red-btn position-absolute top-0 start-100 translate-middle ">
                      &times;
                    </span>
                  </button>

                  <div className={`modal-body text-success text-center`}>
                    <div className="m-3">
                      <img
                        className="success-img"
                        src="https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png"
                        alt="success image"
                      ></img>
                    </div>
                    <p>ADD TO YOUR {type}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="wishlistCartStatusFail"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                  <button
                    type="button"
                    id="as-close-btn"
                    className="closebutton"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="red-btn position-absolute top-0 start-100 translate-middle ">
                      &times;
                    </span>
                  </button>

                  <div className={`modal-body text-danger text-center`}>
                    <div className="m-3">
                      <img
                        className="success-img"
                        src="https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png"
                        alt="success image"
                      ></img>
                    </div>
                    <p>REMOVED FROM YOUR {type}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
