
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { validateMessage } from "../../validationhelper/ValidationHelper";
import {GET_MODEOFDELIVERYSTATUS} from '../../store/payment/ActionTypes'


const AuthMiddleware = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch();
  const modeOfDelStatus = useSelector((state) => state.paymentInfo.response);
  const session = JSON.parse(localStorage.getItem("authUser"));
  const openUrls = ["/login", "/register","/set-password"];
  const restrictedUrls = [
    "/wishlist",
    "/enrichCart",
    "/payment",
    "modeofDelivery",
  ];

  const enrichURL=["/login","/register","/set-password","/forgot-password","/home","/privacy-page","/terms-services","/logout","/wishlist","/enrichCart","/shop","/contact-us","/product","/payment","/wishlist!","/cart!","/modeOfDelivery","/redirect","/paymentfailed","/MyAccount","/Orders","/EmptyMyAccount","/EmptyOrders","/impersonate"];
  
  useEffect(() => {
    let flag=enrichURL.some(endpoint => location.pathname==(endpoint));    
    if(flag===false){      
      navigate('/home')
    }
    if (
      openUrls.includes(location.pathname) &&
      session !== null &&
      session !== undefined
    ) {
      navigate("/home");
    }
    if (
      restrictedUrls.includes(location.pathname) &&
      (session == null || session == undefined)
    ) {
      navigate("/login");
    }
  }, [session]);

  useEffect(()=>{
    if (validateMessage(modeOfDelStatus) && validateMessage(modeOfDelStatus.deliveryStatus) && modeOfDelStatus.deliveryStatus==="Failed" && validateMessage(modeOfDelStatus.orderId) 
    ) {
      window.location.href = `modeOfDelivery?orderId=${modeOfDelStatus.orderId}`;
    }  
  },[navigate])


  useEffect(()=>{
    if(location.pathname.includes("/impersonate")){      
      navigate(window.location.pathname+window.location.search);
    }
    if(validateMessage(session) && validateMessage(session.JWT)){
      const decodedToken = jwtDecode(session.JWT);
      const currentTime = Date.now() / 1000;      
      if(decodedToken.exp <= currentTime){
        localStorage.removeItem("authUser");
        window.location.href='/login'
      }else if(decodedToken.exp > currentTime){
        dispatch({ type: GET_MODEOFDELIVERYSTATUS });
      }
    }
  },[navigate])
  return props.children;
};

export default AuthMiddleware;
