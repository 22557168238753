import {
  ADDTO_WISHLISTCART,
  ADDTO_WISHLISTCART_SUCCESS,
  DELETE_WISHLISTCART,
  DELETE_WISHLISTCART_FAIL,
  DELETE_WISHLISTCART_SUCCESS,
  FETCH_WISHLISTCART_COUNT,
  FETCH_WISHLISTCART_COUNT_SUCCESS,
  FETCH_WISHLIST_SUCCESS,
  FETCH_WISHLIST,
  FETCH_CART,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAIL,
  ADDTO_WISHLISTCART_FAILURE,
  DELETE_WISHLISTCART_FAILURE,
} from "./ActionType";

const initialstate = {
  userId: null,
  wishlisItem: null,
  cartItem: null,
  message: null,
  error: null,
  loading: false,
  cartCount: 0,
  wishListCount: 0,
  cartCountMessage: false,
};

function method1(stateData,payloadData){
  return {
    ...stateData,
    message: payloadData,
    loading: false,
    error: false,
  };
}

const WishlistReducer = (state = initialstate, action=action) => {
  switch (action.type) {
    case ADDTO_WISHLISTCART:
      return {
        ...state,
        loading: true,
        userId: action.payload.userId,
        cartCount: action.payload.cartCount,
        wishListCount: action.payload.wishListCount,
      };

    case ADDTO_WISHLISTCART_SUCCESS:return method1(state,action.payload);
    case ADDTO_WISHLISTCART_FAILURE:
      return { ...state, error: true, loading: false, message: action.payload };

    case FETCH_WISHLIST:
      return { ...state, loading: true, message: null };

    case FETCH_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlisItem: action.payload,
        error: false,
        loading: false,
      };

    case FETCH_CART:
      return { ...state, loading: true, message: null };

    case FETCH_CART_SUCCESS:
      return {
        ...state,
        cartItem: action.payload,
        message: true,
        error: false,
        loading: false,
      };

    case FETCH_CART_FAIL:
      return { ...state, error: true, message: null, loading: false };

    case DELETE_WISHLISTCART:
      return {
        ...state,
        cartCount: action.payload.cartCount,
        wishListCount: action.payload.wishListCount,
        message: null,
        loading: true,
      };

    case DELETE_WISHLISTCART_SUCCESS:return method1(state,action.payload);
    case DELETE_WISHLISTCART_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        message: action.payload,
      };

    case DELETE_WISHLISTCART_FAIL:
      return { ...state, message: action.payload, error: true };

    case FETCH_WISHLISTCART_COUNT:
      return {
        ...state,
        loading: true,
        cartCountMessage: true,
      };

    case FETCH_WISHLISTCART_COUNT_SUCCESS:
      return {
        ...state,
        cartCount: action.payload.cartCount,
        wishListCount: action.payload.wishlistCount,
        loading: false,
        error: false,
        cartCountMessage: false,
      };

    default:
      return { ...state };
  }
};

export default WishlistReducer;