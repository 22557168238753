import React, { useState, useEffect } from "react";
import "./setpassword.css";
import { useDispatch, useSelector } from "react-redux";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import {
  SETPASSWORDFAILURE,
  SETPASSWORDREQUEST,
  SETPASSWORDSCORRECTRESPONSETOMOVEFORWARD,
  SETPASSWORDSUCCESS,
} from "../../store/account/setPassword/ActionType";
import { useNavigate } from "react-router-dom";
import { VERIFICATIONFAILURE, VERIFICATIONREQUEST } from "../../store/account/codeVerification/ActionType";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { validateMessage, validatePassword } from "../../validationhelper/ValidationHelper";

export default function SetPassword() {
  document.title = "Set Password - www.enrich.io";

  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [btnPressed,setBtnPressed]=useState(false);

  const dispatch = useDispatch();

  const error = useSelector((state) => state.password.error);
  const response = useSelector((state) => state.password.response);
  const loading=useSelector(state=>state.password.loading);

  const codeVerifyError = useSelector((state) => state.codeVerification.error);
  const codeVerifyResponse = useSelector((state) => state.codeVerification.response);

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const navigate = useNavigate();  

  
  useEffect(() => {
    verifyCode(code);
  }, [code]);

  useEffect(() => {
    codeVerificationMethod();
  }, [codeVerifyError]);

  useEffect(() => {
    responseError();
  }, [response, error]);


  const verifyCode = (codeData) => {
    if (codeData) {
      dispatch({type: VERIFICATIONREQUEST,payload:codeData});
    }
  };

  function responseError(){
    if (error && response !== null) {      
      setErrorMessage(response.message);
      dispatch({ type: SETPASSWORDFAILURE, payload: null });
    }
    if (!error && response !== null && validateMessage(response) && validateMessage(response.message)) {      
      setSuccessMessage(response.message);      
    }
  }

 

  function codeVerificationMethod(){
    if (codeVerifyError && validateMessage(codeVerifyResponse)) {
      if (codeVerifyResponse) {
        setErrorMessage(codeVerifyResponse.message);
        dispatch({ type: VERIFICATIONFAILURE, payload: null });
      }
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConPassword = () => {
    setShowConPassword(!showConPassword);
  };

  function passwordShowOrHide(passcode,data1,data2){
    return !passcode ? data1 : data2
  }
  function checkIsValid(btnData,passcode){
    return validatePassword(passcode)!==null && btnData ?"is-invalid":""
  }
  
  const handleCallBack = () => {
    setErrorMessage("");
    setSuccessMessage("");
    if (codeVerifyError === true) {
      if(code.startsWith('forgot-password'))
        navigate("/forgot-password");
      else if(code.startsWith('register'))
        navigate("/register")
    }    
    if (validateMessage(response) && response.message === SETPASSWORDSCORRECTRESPONSETOMOVEFORWARD) {        
        dispatch({type:SETPASSWORDSUCCESS,payload:null});  
        navigate("/login");
      }
  };



  const passwordHandler = () => {
    setBtnPressed(true);
    if (validatePassword(password) === null && validatePassword(conPassword)===null) {
      if(password !== conPassword) {
        setErrorMessage("Passwords do not match. Please try again");
        return component;
      }
      else if (password === conPassword) {
        dispatch({type: SETPASSWORDREQUEST,payload:{ code, password }});
      }
    } 
  };  

  const component = (
    <div className="setPasswordParent">
      <SuccessPopUp
        loader={loading}
        successMessage={successMessage}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="container">
        <div className="setPaswordDiv">
          <form className="setPasswordFrom">
            <div className="setPasswordDivHeader">
              <p className="passwordPara">SET PASSWORD</p>
              <p>
                Password must be a minimum of 8 characters with a mix of
                uppercase letter, lowercase letter, digit, and symbols (allowed
                symbols are @*$%&!).
              </p>
            </div>
            <div className="form-group mb-3">
              <label className="form-label setPasswordLabel">
                Password <span className="text-danger">*</span>
              </label>              
              <input
                type={passwordShowOrHide(showPassword,"password","text")}
                className={`form-control form-control-lg ${checkIsValid(btnPressed,password)}`}
                required
                placeholder="Enter New Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <span onClick={handleShowPassword} className="show-password">
                {passwordShowOrHide(showPassword,<BsEyeSlashFill />,<BsEyeFill />)}
              </span>
            <div className="invalid-feedback">{validatePassword(password)}</div>
            </div>
            <div className="form-group mb-3">
              <label
                className="form-label setPasswordLabel"
              >
                Confirm Password <span className="text-danger">*</span>
              </label>
              <input
                type={passwordShowOrHide(showConPassword,"password","text")}
                className={`form-control form-control-lg ${checkIsValid(btnPressed,conPassword)}`}
                required
                placeholder="Confirm Password"
                value={conPassword}
                onChange={(event) => setConPassword(event.target.value)}
              />
              <span onClick={handleShowConPassword} className="show-password">
              {passwordShowOrHide(showConPassword,<BsEyeSlashFill />,<BsEyeFill />)}
              </span>
              <div className="invalid-feedback">{validatePassword(conPassword)}</div>
            </div>
            <button
              onClick={passwordHandler}
              type="button"
              className="btn btn-info"
            >
              Set Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
  return component;
}
