import React, { useEffect, useState } from "react";
import "./enrichcart.css";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa";
import { VscDash } from "react-icons/vsc";
import { FiHeart } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  ADDTO_WISHLISTCART,
  DELETE_WISHLISTCART,
  FETCH_CART,
} from "../../store/wishlistAndCart/ActionType";
import { CREATE_SUBSCRIPTION_REQUEST } from "../../store/payment/ActionTypes";
import PopUp from "../PopUp/SuccessPopUp";
import { RxCrossCircled } from "react-icons/rx";

export default function EnrichShopingCart() {

  const [checkedProducts, setCheckProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.wishlistcart?.cartItem);
  const [finalProducts, setFinalProducts] = useState([]);
  const navigate = useNavigate();
  const uId = useSelector((state) => state.paymentInfo?.uId);      

  const [errorMessage, setErrorMessage] = useState("");
  const error = useSelector((state) => state.wishlistcart.error);
  const response = useSelector((state) => state.wishlistcart.message);
  const loading = useSelector((state) => state.wishlistcart.loading);
  const checkoutLoading = useSelector((state) => state.paymentInfo.loading);
  const [isDeleteSelect, setIsDeleteSelect] = useState(false);
  const [proudctIdToDelete, setProudctIdToDelete] = useState(0);
  const [priceToDelete, setPriceToDelete] = useState(0);
  const user = localStorage.getItem("authUser");
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    dispatch({ type: FETCH_CART });
  }, [dispatch]);

  useEffect(() => {
    if (uId !== null) {
      navigate(`/payment?uId=${uId}`);
    }
  }, [uId]);

  useEffect(() => {
    if (error && response) {
      setErrorMessage(response.message);
    }
  }, [error, response]);

  useEffect(() => {
    if (
      cartProducts !== undefined &&
      cartProducts !== null &&
      cartProducts.length > 0
    ) {
      let products = cartProducts.map((item) => {
        return { ...item, TotalProductPrice: item.price * item.quentity };
      });
      setFinalProducts(products);
    } else {      
      navigate("/cart!");
    } 
    }, [cartProducts]);

  const onChangeCheckedProducts = (productId, TotalPrice) => {
    if (checkedProducts.includes(productId)) {
      setTotalPrice(totalPrice - TotalPrice);
      let updatedProduct = checkedProducts.filter(
        (eachProduct) => eachProduct !== productId
      );
      setCheckProducts(updatedProduct);
    } else {
      setTotalPrice(totalPrice + TotalPrice);
      setCheckProducts([...checkedProducts, productId]);
    }
  };

  const handleDeleteOnClick = () => {
    if (checkedProducts.includes(proudctIdToDelete)) {
      setTotalPrice(totalPrice - priceToDelete);
      let updatedProduct = checkedProducts.filter(
        (eachProduct) => eachProduct !== proudctIdToDelete
      );

      setCheckProducts(updatedProduct);
    }
    dispatch({
      type: DELETE_WISHLISTCART,
      payload: { productId: [proudctIdToDelete], type: "CART" },
    });
    setTimeout(() => {
      dispatch({ type: FETCH_CART });
    }, 1000);
  };

  const handleSelectDeleteOnClick = () => {
    setIsDeleteSelect(false);

    dispatch({
      type: DELETE_WISHLISTCART,
      payload: { productId: checkedProducts, type: "CART" },
    });
    setCheckProducts([]);
    setTotalPrice(0);
    setTimeout(() => {
      dispatch({ type: FETCH_CART });
    }, 1000);
  };

  function handleAddToWishList() {
    dispatch({
      type: ADDTO_WISHLISTCART,
      payload: {
        productId: checkedProducts,
        type: "WISHLIST",
      },
    });
  }

  const handleProceedCheckoutOnClick = () => {
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
      payload: { productIds: checkedProducts },
    });
  };
  return (
    <div className="enrichShopingCart">
      <PopUp
        loader={loading || checkoutLoading}        
        errorMessage={errorMessage}
      />
      <div className="container">
        <div className="shop-container">
          <div className="cart-product-heading mb-4">
            <div className="cart-heading ">
              <h3>MY CART</h3>
            </div>
            <div className="cart-hediing-right shift-heading">
              <p>
                <Link className="heading-link heading-link-prev" id="as-home" to="/">
                  Home
                </Link>
                <FaAngleRight className="right-angle-icon" />
                <Link className="heading-link heading-link-prev" to="/shop" id="as-product">
                  Products
                </Link>
                <FaAngleRight className="right-angle-icon" />
                Your Shopping Cart
              </p>
            </div>
          </div>

          <div className="cart-selected-product">
            {checkedProducts.length > 0 && (
              <div className="cart-visible-content">
                <div className="cart-selected-Order">
                  <div className="select-products-details">
                    <VscDash className="remove-item" /> {checkedProducts.length}
                    /{finalProducts.length} ITEMS SELECTED{" "}
                    <b>($ {totalPrice})</b>
                  </div>
                  <div className="delete-wishlist">
                    <Link className="deleteFromCart" to="/enrichCart">
                      <MdDelete
                        className="mycart-delete"
                        id="as-cart-delete"
                        data-bs-toggle="modal"
                        data-bs-target="#modelForDelete"
                        onClick={() => setIsDeleteSelect(true)}
                      />
                    </Link>
                    <Link onClick={handleAddToWishList} id="as-wishlist">
                      <FiHeart className="mycart-wishlist" />
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="cart-items  mt-3 mb-5 shadow-sm ">
            {finalProducts.map((product) => (
              <>
                <div className="cart-products row">
                  <div className="cart-items-left col-md-8">
                    <div className=" cart-product-select product-select form-check ">
                      <input
                        className="form-check-input m-0"
                        type="checkbox"                        
                        onChange={() => {
                          onChangeCheckedProducts(
                            product.productId,
                            product.price
                          );
                        }}
                        value={checkedProducts.includes(product.productId)}
                        id="as-flexCheckIndeterminate"
                        checked={checkedProducts.includes(product.productId)}
                      />
                    </div>
                    <img
                      className="cart-product-img "
                      id="as-cart-product"
                      src={product.url}
                      alt="product-img"
                      onClick={()=>navigate(`/product?id=${product.productId}`)}
                    ></img>
                    <div className="cart-product-name ">{product.name}</div>
                  </div>
                  <div className="cart-items-right col-md-4">
                    <div className="cart-total-selected-price">
                      $ {product.price}
                    </div>
                    <div className="mycart-product-delete">
                      <Link>
                        <MdDelete
                          className="deleted-icon"
                          id="as-delete-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#modelForDelete"
                          onClick={() => {
                            setPriceToDelete(product.price);
                            setProudctIdToDelete(product.productId);
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <hr
                  className="mt-3 product-hr
              "
                ></hr>
              </>
            ))}

            <div
              className="modal fade modal-for-delete"
              id="modelForDelete"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              data-bs-backdrop="static"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close make-me-invisible"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body text-center">
                    <RxCrossCircled className="react-cross-icon " />
                    <h2>Delete</h2>
                    <p>Are you sure you want to delete this item?</p>
                  </div>
                  <div className="modal-footer buttons-for-delete-confirm">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="as-close-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      data-bs-dismiss="modal"
                      id="as-condirm-btn"
                      aria-label="Close"
                      onClick={
                        isDeleteSelect === true
                          ? handleSelectDeleteOnClick
                          : handleDeleteOnClick
                      }
                    >
                      Yes, Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mycart-product-mid mt-3"
              style={{ display: checkedProducts.length > 0 ? "block" : "none" }}
            >
              <h3 className="total-amount">
                <span className="span-price">Total Amount: </span>{" "}
                <b> ${totalPrice}.00</b>
              </h3>
            </div>

            <div className="check-out-btn col-md-12 mt-2 text-end">
              <button
                disabled={checkedProducts.length <= 0}
                className="proceed-btn btn btn-lg "
                id="as-proceed-btn"
                onClick={handleProceedCheckoutOnClick}
              >
                PROCEED TO CHECKOUT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
