import React, { useEffect, useState } from "react";
import img from "../enrichImages/Screenshot 2023-09-18 182219.png";
import "./wishlist.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_WISHLIST, FETCH_WISHLISTCART_COUNT } from "../../store/wishlistAndCart/ActionType";
import PopUp from '../PopUp/SuccessPopUp'

export default function EmptyWishList() {
  document.title = "Wishlist - www.enrich.io";
  const wishlistProduct = useSelector(
    (state) => state.wishlistcart?.wishlisItem
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(true);
  
  useEffect(() => {    
    dispatch({ type: FETCH_WISHLISTCART_COUNT }); 
    dispatch({type:FETCH_WISHLIST});
  }, []);
  

  useEffect(() => {
    if (wishlistProduct && wishlistProduct.length !== 0) {
      setLoading(false);
      navigate("/wishlist");
    }
    if (wishlistProduct && wishlistProduct.length === 0) {
      setLoading(false);
    }
  },[wishlistProduct]);

  return (
    <div className="empty-wishlistcart">
      <PopUp loader={loading}/>
      <div className="container">
      <div className={`empty-wishlistcart-content row ${loading?"invisible":""}`}>
          <div className="empty-wishlistcart-img col-md-6">
            <img src={img} alt="empty wishlist image"></img>
          </div>
          <div className="empty-wishlistcart-text text-start col-md-6">
            <div className="text-empty-wishlist">
              Your wishlist is currently empty. Start adding products to buy
              later!
            </div>
            <Link className="empty-wishlistcart-link btn btn-link" id="as-shop" to="/shop">
              Shopping now!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
