import { validateMessage } from "../validationhelper/ValidationHelper";

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;



export const validatePhoneNumber=(number)=>{
    const phoneRegex = /^(\+(\d{1,2})[-.]?)?(\d{2,3}[-.]?){1,2}\d{4,10}$/;
    return phoneRegex.test(number);
}


export const formatDate=(date)=> {
    const backendDate = new Date(date);
    const day = backendDate.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      backendDate
    );
    const year = backendDate.getFullYear();
    return `${day} ${month} ${year}`;
  }

  export const encoderToBase64=(data)=>{
    return validateMessage(data) ? btoa(data):"";
  }
  export const decodeBase64=(data)=>{
    return validateMessage(data) ? atob(data):"";
  }

  export const handleNavigate = (url) => {
    if ((url.includes("-dev") || url.includes("-qa"))&& !url.includes("shopadmin")) {
      return url.replace("shop", "shopadmin");
    }
    return url;
  };
  