import { put, takeEvery, call } from "redux-saga/effects";
import { SETPASSWORDFAILURE, SETPASSWORDREQUEST, SETPASSWORDSUCCESS } from "./ActionType";
import { SET_PASSWORD } from "../../../constants/ApiEndPoints";
import { postCall } from '../../../apihelper/ApiHelper'

export function* setPassword(action) {
    try {
        let values = {
            password: action.payload.password,
            code: action.payload.code
        };
        const response = yield call(postCall, SET_PASSWORD, values)
        yield put({ type: SETPASSWORDSUCCESS, payload: response });
    } catch (error) {
        yield put({ type: SETPASSWORDFAILURE, payload: error });
    }
}

function* PasswordSaga() {
    yield takeEvery(SETPASSWORDREQUEST, setPassword);
}

export default PasswordSaga;