import { useEffect } from 'react'


const Logout = () => {
    document.title = 'Logout - www.enrich.io'
    

    useEffect(() => {
        localStorage.removeItem('authUser');
        window.location.href = "/login"
    }, [])

}

export default Logout
