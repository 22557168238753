
import {
  FETCH_HOTPRODUCT,
  FETCH_HOTPRODUCT_SUCCESS,
  FETCH_ONEPRODUCT,
  FETCH_ONEPRODUCT_FAIL,
  FETCH_ONEPRODUCT_SUCCESS,
  FETCH_PRODUCT,
  FETCH_PRODUCT_FAIL,
  FETCH_PRODUCT_SUCCESS,
  FETCH_SAMPLE_DATA_FAILURE,
  FETCH_SAMPLE_DATA_REQUEST,
  FETCH_SAMPLE_DATA_SUCCESS,
  RESET_PRODUCT,
} from "./ActionTypes";

const initialState = {
  products: [],
  error: [],
  product: [],
  loading: false,
  errorSampleData:null,
};

function method1(stateData){
  return {
    ...stateData, loading: true
  };
}
function method2(stateData,payloadData){
  return {
    ...stateData,
    products: payloadData,
    loading: false
  };
}

const ProductReducer = (state = initialState, action=action) => {
  switch (action.type) {
    case FETCH_HOTPRODUCT:return method1(state);
    case FETCH_HOTPRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload, loading: false
      };
    case FETCH_PRODUCT:return method1(state);
    case FETCH_PRODUCT_SUCCESS: return method2(state,action.payload);
    case FETCH_PRODUCT_FAIL:
      return {
        ...state,
        products: [],
        error: action.payload,
        loading: false
      };
    case RESET_PRODUCT: return {
      ...state,
      products: [],
      loading: true
    }
    case FETCH_ONEPRODUCT: return method1(state);
    case FETCH_ONEPRODUCT_SUCCESS:return method2(state,action.payload);
    case FETCH_ONEPRODUCT_FAIL: return {
      ...state,
      error: action.payload,
      loading: false
    }
    case FETCH_SAMPLE_DATA_REQUEST: return method1(state);
    case FETCH_SAMPLE_DATA_SUCCESS:return {
      ...state,loading:false,
    }
    case FETCH_SAMPLE_DATA_FAILURE : return {
      ...state,errorSampleData:action.payload,loading:false
    }
    default:
      return {
        ...state,
      };
  }
};

export default ProductReducer;