import React, { useEffect, useState } from "react";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./forgotpassword.css";
import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
} from "../../store/account/forgotPassword/ActionType";
import { validateEmail, validateMessage } from "../../validationhelper/ValidationHelper";

const ForgotPassword = () => {
  document.title = "Forgot Password-enrich";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState("");
  const [isSavePress, setIsSavePress] = useState(false);

  const loading = useSelector((state) => state.forgotPassword.loading);
  const error = useSelector((state) => state.forgotPassword.error);
  const response = useSelector((state) => state.forgotPassword.response);


  useEffect(() => {
    if (error && response !== null) {
      setErrorMessage(response.message);
      dispatch({ type: FORGOT_PASSWORD_FAILURE, payload: null });
    } else if (!error && validateMessage(response) && validateMessage(response.message) ) {
      setSuccessMessage(response.message);
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: null });
    }
  }, [response, error]);

  const handleforgotPassword = (event) => {
    event.preventDefault();
    setIsSavePress(true);
    if (isValidEmail === true) {
      
      dispatch({type:FORGOT_PASSWORD_REQUEST,payload:email});
    }
  };
  const handleCallBack = () => {
    if (!error) {
      navigate("/login");
    }
    setErrorMessage("");    
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    if (validateEmail(inputEmail)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };
  return (
    <div className="forgotPasswordParent">
      <SuccessPopUp
        loader={loading}
        successMessage={successMessage}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="container">
        <div className="setPasswordContainer">
          <form className="shadow rounded setPasswordFrom">            
              <p className="forgotPasswordHead">FORGOT PASSWORD</p>            
              <p className="forgotPasswordPara mb-4">
                Please enter your email address below. You will be sent an email
                with instructions on how to change your password.
              </p>
            <div className="mb-3 set-email">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email Id <span className="text-danger">*</span>
              </label>
              <input
                type="email"                
                placeholder="Enter Email"
                value={email}
                onChange={handleEmailChange}
                className={`form-control form-control-lg ${
                  isSavePress && !isValidEmail ? "is-invalid" : ""
                }`}
                id="as-input-email"
              />
              <div className="invalid-feedback">Please enter valid email.</div>
            </div>
            <div className="buttonAndLoginLink">
              <button
                type="button"
                id="as-submit-btn"
                className="btn btn-info"
                onClick={handleforgotPassword}
              >
                Submit
              </button>
              <Link to="/login" id="as-login-link" className="loginHerePara">
                <p className="my-3">Login Here</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
