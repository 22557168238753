export const GET_USERDETAIL = "GET_USERDETAIL";
export const GET_USERDETAIL_SUCCESS = "GET_USERDETAIL_SUCCESS";
export const GET_USERDETAIL_FAIL = "GET_USERDETAIL_FAIL";
export const SAVE_BILLINGINFO = " SAVE_BILLINGINFO";
export const SAVE_BILLINGINFO_SUCCESS = "SAVE_BILLINGINFO_SUCCESS";
export const SAVE_BILLINGINFO_FAIL = "SAVE_BILLINGINFO_FAIL";
export const GET_BIILLINGDETAILS = "GET_BIILLINGDETAILS";
export const GET_BIILLINGDETAILS_SUCCESS = "GET_BIILLINGDETAILS_SUCCESS";
export const GET_BIILLINGDETAILS_FAIL = "GET_BIILLINGDETAILS_FAIL";
export const GET_STATEREQUEST = "GET_STATEREQUEST";
export const GET_STATEREQUEST_SUCCESS = "GET_STATEREQUEST_SUCCESS";
export const GET_STATEREQUEST_FAIL = "GET_STATEREQUEST_FAIL";
export const GET_COUNTRYREQUEST = "GET_COUNTRYREQUEST";
export const GET_COUNTRYREQUEST_SUCCESS = "GET_COUNTRYREQUEST_SUCCESS";
export const GET_COUNTRYREQUEST_FAIL = "GET_COUNTRYREQUEST_FAIL";
export const CHECKOUT_SESSION_CREATION = "CHECKOUT_SESSION_CREATION";
export const CHECKOUT_SESSION_CREATION_SUCCESS =
  "CHECKOUT_SESSION_CREATION_SUCCESS";
export const CHECKOUT_SESSION_CREATION_FAIL = "CHECKOUT_SESSION_CREATION_FAIL";
export const GET_STATUS_REQUEST = "GET_STATUS_REQUEST";
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS";
export const GET_STATUS_FAIL = "GET_STATUS_FAIL";
export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESSFULL =
  "CREATE_SUBSCRIPTION_SUCCESSFULL";
export const CREATE_SUBSCRIPTION_FAIL = "CREATE_SUBSCRIPTION_FAIL";
export const GET_MODEOFDELIVERYSTATUS = "GET_MODEOFDELIVERYSTATUS";
export const GET_MODEOFDELIVERYSTATUS_SUCCESS =
  "GET_MODEOFDELIVERYSTATUS_SUCCESS";
