
import { Routes, Route } from "react-router-dom";
import NavBar from "./pages/navbar/NavBar";
import ImgSection from "./pages/imgsection/ImgSection";
import HotProduct from "./pages/hotproduct/HotProduct";
import Footer from "./pages/footer/Footer";
import WishList from "./pages/wishlist/WishList";
import EnrichShopingCart from "./pages/cart/EnrichShopingCart";
import Shop from "./pages/productshop/Shop";
import Product from "./pages/product/Product";
import ScrollToTop from "./pages/misc/ScrollToTop";
import AuthMiddleware from "./pages/misc/AuthMiddleware";
import LoginComponent from "./pages/loginComponent/LoginComponent";
import RegisterComponent from "./pages/RegistrationComponent/RegisterComponent";
import SetPassword from "./pages/SetPasswordComponent/SetPassword";
import Logout from "./pages/logout/Logout";
import Payment from "./pages/payment/Payment";
import EmptyWishList from "./pages/wishlist/EmptyWishList";
import EmptyCart from "./pages/cart/EmptyCart";
import ModeOfDelivery from "./pages/modeofdelivery/ModeOfDelivery";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import PrivacyPage from "./pages/privacyPage/PrivacyPage";
import TermsService from "./pages/termsService/TermsService";
import ContactUs from "./pages/contactus/ContactUs";
import RedirectPage from "./pages/payment/RedirectPage";
import MyAccount from "./pages/myaccount/MyAccount";
import Orders from "./pages/orders/Orders";
import PaymentFailed from "./pages/payment/PaymentFailed";
import EmptyMyAccount from "./pages/myaccount/EmptyMyAccount";
import EmptyOrders from "./pages/orders/EmptyOrders";
import { Impersonate } from "./pages/impersonate/Impersonate";
function App() {
  return (
    <div>
      <NavBar />
      <ScrollToTop />
      <AuthMiddleware>
        <Routes>
          <Route exact path="/login" element={<LoginComponent />} />
          <Route exact path="/register" element={<RegisterComponent />} />
          <Route exact path="/set-password" element={<SetPassword />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route
            exact
            path="/home"
            element={
              <div>
                <ImgSection />
                <HotProduct />
              </div>
            }
          />
          <Route exact path="/privacy-page" element={<PrivacyPage />} />
          <Route exact path="/terms-services" element={<TermsService />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route exact path="/wishlist" element={<WishList />} />
          <Route exact path="/enrichCart" element={<EnrichShopingCart />} />
          <Route exact path="/shop" element={<Shop />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="wishlist!" element={<EmptyWishList />} />
          <Route exact path="/cart!" element={<EmptyCart />} />
          <Route exact path="/modeofDelivery" element={<ModeOfDelivery />} />
          <Route exact path="/redirect" element={<RedirectPage />} />
          <Route exact path="/paymentfailed" element={<PaymentFailed />} />
          <Route exact path="/myAccount" element={<MyAccount />} />
          <Route exact path="/Orders" element={<Orders />} />
          <Route exact path="/EmptyMyAccount" element={<EmptyMyAccount />} />
          <Route exact path="/EmptyOrders" element={<EmptyOrders />} />
          <Route exact path="/impersonate" element={<Impersonate />} />
        </Routes>
      </AuthMiddleware>
      <Footer />
    </div>
  );
}
export default App;



