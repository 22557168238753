import {
  FETCH_ORDERS_BY_NAME,
  FETCH_ORDERS_BY_NAME_FAILURE,
  FETCH_ORDERS_BY_NAME_SUCCESS,
  GET_ALLFILTERORDERS_FAIL,
  GET_ALLFILTERORDERS_SUCCESS,
  GET_ALLFITERORDERS_REQUEST,
  GET_ALLORDERS_FAIL,
  GET_ALLORDERS_REQUEST,
  GET_ALLORDERS_SUCCESS,
} from "./ActionsTypes";

const initialState = {
  orderList: null,
  message: null,
  error: null,
  filterOrderList: null,
  loading: false,
};

function method1(stateData){
  return {
    ...stateData,
    loading: true
  };
}
function method2(stateData,payloadData){
  return {
    ...stateData,
    error: payloadData,
    loading: false
  };
}

 const OrderReducer = (state = initialState, action=action) => {
  switch (action.type) {
    case GET_ALLORDERS_REQUEST:return method1(state);
    case GET_ALLORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload,
        loading: false
      };
    case GET_ALLORDERS_FAIL:return method2(state,action.payload);
    case GET_ALLFITERORDERS_REQUEST:return method1(state);
    case GET_ALLFILTERORDERS_SUCCESS:
      return {
        ...state,
        filterOrderList: action.payload,
        loading: false
      };
    case GET_ALLFILTERORDERS_FAIL:return method2(state,action.payload)
      case FETCH_ORDERS_BY_NAME:
        return{
        ...state,
        loading:true,
      }
      case FETCH_ORDERS_BY_NAME_SUCCESS:
        return {
          ...state,
          filterOrderList:action.payload,
          loading:false,
        }
      case FETCH_ORDERS_BY_NAME_FAILURE:
        return {
          ...state,
          error:action.payload,          
          loading:false,
        }
    default:
      return {
        ...state,
      };
  }
};

export default OrderReducer;