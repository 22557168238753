import React, { useEffect, useState } from 'react';
import './impersonate.css';
import { useDispatch, useSelector } from 'react-redux';
import { IMPERSONATE_FAILURE, IMPERSONATE_REQUEST } from '../../store/account/codeVerification/ActionType';
import SuccessPopUp from '../PopUp/SuccessPopUp';
import { useNavigate } from 'react-router-dom';


export const Impersonate=()=>{

    const dispatch=useDispatch();    
    const [errorMessage,setErrorMessage]=useState("");
    const navigate=useNavigate();

    const error=useSelector(state=>state.codeVerification.impersonateError)
    const loading=useSelector(state=>state.codeVerification.loading);

    useEffect(()=>{
        if(window.location.search.includes('?code')){            
            dispatch({type:IMPERSONATE_REQUEST,payload:window.location.search});
        }
    },[])

    useEffect(()=>{        
        if(error){
            setErrorMessage(error.message);
            dispatch({type:IMPERSONATE_FAILURE,payload:null});
        }
    },[error]);
    
    const handleCallBack=()=>{
        navigate("/home");
        setErrorMessage("");
    }

    return <div className='impersonate-component' data-testid="impersonate-component">
        <SuccessPopUp loader={loading} errorMessage={errorMessage} callback={handleCallBack}/>
    </div>
}

