import { put, takeEvery, call } from "redux-saga/effects";
import { REGISTERFAILURE, REGISTERREQUEST, REGISTERSUCCESS } from "./ActionType";
import { postCall } from "../../../apihelper/ApiHelper";
import { REGISTER_ENDPOINT } from "../../../constants/ApiEndPoints";

export function* register(action) {
    try {
        const response = yield call(postCall, REGISTER_ENDPOINT, action.payload)
        yield put({ type: REGISTERSUCCESS, payload: response });        
    } catch (error) {
        yield put({ type: REGISTERFAILURE, payload: error });
    }
}

function* RegisterSaga() {
    yield takeEvery(REGISTERREQUEST, register);
}

export default RegisterSaga;
