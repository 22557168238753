import { put, call, takeEvery } from "redux-saga/effects";
import {
  FETCH_ORDERS_BY_NAME,
  FETCH_ORDERS_BY_NAME_FAILURE,
  FETCH_ORDERS_BY_NAME_SUCCESS,
  GET_ALLFILTERORDERS_FAIL,
  GET_ALLFILTERORDERS_SUCCESS,
  GET_ALLFITERORDERS_REQUEST,
  GET_ALLORDERS_FAIL,
  GET_ALLORDERS_REQUEST,
  GET_ALLORDERS_SUCCESS,
} from "./ActionsTypes";
import { getCall } from "../../apihelper/ApiHelper";
import { GET_FILTERORDER, GET_ORDERS, GET_ORDER_BY_NAME_API } from "../../constants/ApiEndPoints";

function* getAllOrders() {
  try {
    const response = yield call(getCall, GET_ORDERS);
    yield put({ type: GET_ALLORDERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_ALLORDERS_FAIL, payload: error });
  }
}
function* getOrdersByName(action) {
  try {
    const response = yield call(getCall,`${GET_ORDER_BY_NAME_API}?name=${action.payload}`);
    yield put({ type: FETCH_ORDERS_BY_NAME_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: FETCH_ORDERS_BY_NAME_FAILURE, payload: error });
  }
}

function* getFilterOrders(action) {
  const isActive = action.payload.isActive;
  const orderBy = action.payload.orderBy;
  try {
    const response = yield call(
      getCall,
      `${GET_FILTERORDER}?isActive=${isActive}&orderDate=${orderBy}`
    );
    yield put({ type: GET_ALLFILTERORDERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: GET_ALLFILTERORDERS_FAIL,
      error: action.payload,
    });
  }
}
function* OrderSaga() {
  yield takeEvery(GET_ALLORDERS_REQUEST, getAllOrders);
  yield takeEvery(GET_ALLFITERORDERS_REQUEST, getFilterOrders)
  yield takeEvery(FETCH_ORDERS_BY_NAME, getOrdersByName)
}

export default OrderSaga;
