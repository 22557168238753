export const GET_PRODUCT = "open/getProduct"; //for get all the products from the database
export const GET_ONEPRODUCT = "open/getOneProduct"; //for gett only one product
export const GET_HOTPTODUCT = "open/getHotProducts"; // get hotproducts
export const SUBSCRIBE_NEWSLETTER = "open/subscribe"; // subcribe to newsletter
export const ADDTO_WISHLISTORCART = "addToWishListOrCart"; // add items ti wishlist or cart
export const DELETE_WISHLISTORCART = "deleteWishlistOrCart"; //delete items from wishlist or cart
export const GET_WISHLISTPRODUCTS = "getWishListproducts"; //get wishlist products
export const GET_CARTPRODUCTS = "getCartProducts"; //get cart products
export const GET_WISHLIST_CARTCOUNT = "getWishListCartCount"; // get the count of wishlist and cart
export const VERIFYCODE = "account/check-verification-code"; //checks the verification code when the user open the set password page
export const LOGIN = "account/login"; // login end point
export const REGISTER_ENDPOINT = "account/register"; // login end point
export const SET_PASSWORD = "account/set-password"; // set password end point this endpoint gets called after sending mail
export const GOOGLE_CALLBACK_REQUST = "account/google-authentication-callback-";
export const LINKEDIN_CALLBACK_REQUST =
  "account/linkedin-authentication-callback-";
export const GETUSERDETAILS = "getUserInfoForBilling";
export const SAVE_BILLINGINFORMATION = "savebillingInformation";
export const GET_STATE = "getStateByCountry";
export const GET_BILLINGINFO = "getDefaultBillingAddress";
export const SAVE_DELIVERYDATA = "saveDeliveryData";
export const GET_SERVERLIST = "getServerList";
export const GET_DELIVERYDETAILS = "getDeliveryDetails";
export const UPDATE_DELIVERYDETAILS = "updateDeliveryDetails";
export const DELETE_DELIVERYDETAILS = "deleteDeliveryAddress";
export const GET_COUNTRY = "getCountry";
export const SAVE_SNOWFLAKEDELIVERYDATA = "saveSnowFLakeDeliveryData";
export const GET_SNOWFLAKEDELIVERYDETAILS = "getSnowFlakeDeliveryDetails";
export const UPDATE_SNOWFLAKEDELIVERYDETAILS = "updateSnowFlakeDeliveryDetails";
export const DELETE_SNOWFLAKEDELIVERYDETAILS = "deleteSnowFlakeDeliveryAddress";
export const FACEBOOK_CALLBACK_REQUEST =
  "account/facebook-authentication-callback-";
export const FORGOT_PASSWORD_CONSTANT = "account/forgot-password/";
export const CONTACTUS_ENDPOINT = "account/contact-us";
export const CHECKOUT_ENDPOINT = "api/create-checkout-session";
export const GET_STATUS = "getStatus";
export const CREATE_SUBSCRIPTION = "createSubscription";
export const UPDATE_ADDRESS = "updateBillingInfo";
export const SETDEFAULT_ADDRESS = "updateDefaultAddress";
export const GETALL_BILLINGADDRESS = "getAllBillingInformation";
export const DELETE_ADDRESS = "deleteAddress";
export const GET_ORDERS = "getAllOrders";
export const GET_FILTERORDER = "getFilterOrders";
export const MODEOFDELIVERYSTATUS = "getModeOfDeliveryStatus";
export const GET_ORDER_BY_NAME_API = "orders-by-name";
export const GET_HEADING_AND_INPUTS="get-input-headings";
export const DOWNLOAD_SAMPLE_DATA="download/";
export const IMPERSONATE_API="impersonate";