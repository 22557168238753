import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginSocialGoogle } from "reactjs-social-login";
import {
  LINKEDINAUTHENTICATIONFAILURE,
  LINKEDINAUTHENTICATIONREQUEST,
  LINKEDINCLIENTID,
  LINKEDIN_REDIRECTURL_LOGIN,
} from "../../store/account/thirdPartyIntegration/linkedIn/ActionType";
import { Link, useNavigate } from "react-router-dom";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import { FaAngleRight } from "react-icons/fa6";
import {
  GOOGLEAUTHENTICATIONFAILURE,
  GOOGLEAUTHENTICATIONREQUEST,
  GOOGLECLIENTID,
} from "../../store/account/thirdPartyIntegration/google/ActionType";
import {
  LOGINFAILURE, LOGINREQUEST,
} from "../../store/account/login/ActionType";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import { validateMessage,validateEmail, } from "../../validationhelper/ValidationHelper";

const LoginComponent = () => {
  document.title = "Login - www.enrich.io";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [isSavePress, setIsSavePress] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginResponse = useSelector((state) => state.login.response);
  const loginLoading = useSelector((state) => state.login.loading);
  const loginError = useSelector((state) => state.login.error);

  const googleLoginResponse = useSelector((state) => state.google.response);
  const googleLoginError = useSelector((state) => state.google.error);
  const googleLoginLoading = useSelector((state) => state.google.loading);

  const linkedInLoginResponse = useSelector((state) => state.linkedIn.response);
  const linkedInLoginError = useSelector((state) => state.linkedIn.error);
  const linkedInLoginLoading = useSelector((state) => state.linkedIn.loading);

  let urlCode = new URL(window.location.href).searchParams.get("code");
  let code = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDINCLIENTID}&redirect_uri=${LINKEDIN_REDIRECTURL_LOGIN}&scope=email%20profile%20openid`;

  useEffect(() => {
    if (urlCode !== null) {
      dispatch({type:LINKEDINAUTHENTICATIONREQUEST,payload:{code: urlCode,callType: "register",redirectUrl: LINKEDIN_REDIRECTURL_LOGIN}});        
      urlCode = null;
    }
  }, [urlCode]);
  
  useEffect(() => {
    if (loginError && loginResponse !== null) {
      setErrorMessage(loginResponse.message);
      dispatch({ type: LOGINFAILURE, payload: null });
    }
  }, [loginError, loginResponse]);

  useEffect(() => {
    if (googleLoginError && googleLoginResponse !== null) {
      setErrorMessage(googleLoginResponse.message);
      dispatch({ type: GOOGLEAUTHENTICATIONFAILURE, payload: null });
    }
  }, [googleLoginError, googleLoginResponse]);


  useEffect(() => {
    if (linkedInLoginError && linkedInLoginResponse !== null && validateMessage(linkedInLoginResponse.message)) {
      setErrorMessage(linkedInLoginResponse.message);
      urlCode = null;
      navigate("/login");
      dispatch({ type: LINKEDINAUTHENTICATIONFAILURE, payload: null });
    }
  }, [linkedInLoginError, linkedInLoginResponse]);

  const handleLoginApi = (event) => {
    event.preventDefault();
    setIsSavePress(true);    
     if (validateMessage(password) && validateEmail(email)) {
      dispatch({type:LOGINREQUEST,payload:{email,password}})
    }
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleGoogleLogin = (provider, data) => {    
    dispatch({type:GOOGLEAUTHENTICATIONREQUEST,payload:{accessToken: data.access_token,callType: "login",source: "Google"}});
  }


  const handleCallBack = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };
  const handlePasswordChange = (e) => {
    const inputEmail = e.target.value;
    setPassword(inputEmail);    
  };

  return (
    <div className="registerLoginContainer">
      <SuccessPopUp
        loader={
          googleLoginLoading ||
          linkedInLoginLoading ||
          loginLoading
        }
        successMessage={successMessage}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="container">
        <div className="loginRegisterContainer">
          <div className="register-navigator-line">
            <Link className="home" to="/home">
              Home
            </Link>
            <FaAngleRight className="right-angle-icon" />
            Login
          </div>
          <form>
            <div>
              <p className="loginRegisterHead">LOGIN</p>
            </div>
            <div className="mb-3">
              <label className="form-label emailNameLabel">
                Email Address <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                className={`form-control emailNameInputContainer ${
                  isSavePress && !validateEmail(email) ? "is-invalid" : ""
                }`}
                id="email"
                placeholder="Enter Corporate Email"
                required
              />
              <div className="invalid-feedback">Please enter valid email.</div>
            </div>
            <div className="mb-3 show-password-parent login-remove-bg">
              <label className="form-label emailNameLabel">
                Password <span className="text-danger">*</span>
              </label>
              <input
                value={password}
                onChange={handlePasswordChange}
                type={!showPassword ? "password" : "text"}
                className={`form-control login-password emailNameInputContainer ${
                  isSavePress && !validateMessage(password) ? "is-invalid" : ""
                }`}
                id="password"
                required
                placeholder="Enter Password"
              />
              <span onClick={handleShowPassword} className={`show-password`}>
                {!showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
              </span>
                <div
                  className= "invalid-feedback"
                >
                  Please enter valid password.
                </div>
            </div>
            <div className="forgotPassword">
              <button
                type="submit"
                onClick={handleLoginApi}
                className="btn custom-button"
              >
                Login
              </button>
              <Link to="/forgot-password">
                <p className="my-2 forgotPaswordPara">Forgot Password ?</p>
              </Link>
            </div>
            <hr />
            
            <div className="buttonsContainer">              
              <button
                onClick={(event) => event.preventDefault()}
                className="socialBtn-ggl socialBtn"
              >
                <LoginSocialGoogle
                  className="reactBtn"
                  client_id={GOOGLECLIENTID}
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  onResolve={({ provider, data }) => {
                    handleGoogleLogin(provider, data);
                  }}
                  onReject={(error) => {
                    if(validateMessage(error) && !validateMessage(error.data)){
                      setErrorMessage("Something went wrong, Please try again later");
                    }
                  }}
                >
                  <button
                    className="btn ggl-btn"
                    type="button"
                  >
                    <span className="google-btn">
                      <i className="fab fa-google"></i>
                    </span>
                    Google
                  </button>
                </LoginSocialGoogle>
              </button>              
                <a href={code} className="socialBtn" target="_blank">
                  <button className="btn li-btn" type="button">
                    <span className="linkedIn-btn">
                      <i className="fab fa-linkedin-in"></i>
                    </span>
                    LinkedIn
                  </button>
                </a>                 
            </div>         
          </form>
          <h5 className="account-exists my-4">
            Don't have an account ? {" "}
            <Link to="/register" className="registerLoginNow">
              Register Now
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
};
export default LoginComponent;
