export const GET_ALLORDERS_REQUEST = "GET_ALLORDERS_REQUEST";
export const GET_ALLORDERS_SUCCESS = "GET_ALLORDERS_SUCCESS";
export const GET_ALLORDERS_FAIL = "GET_ALLORDERS_FAIL";
export const GET_ALLFITERORDERS_REQUEST = "GET_ALLFITERORDERS_REQUEST";
export const GET_ALLFILTERORDERS_SUCCESS = "GET_ALLFILTERORDERS_SUCCESS";
export const GET_ALLFILTERORDERS_FAIL = "GET_ALLFILTERORDERS_FAIL";
export const FETCH_ORDERS_BY_NAME = "FETCH_ORDERS_BY_NAME";
export const FETCH_ORDERS_BY_NAME_SUCCESS = "FETCH_ORDERS_BY_NAME_SUCCESS";
export const FETCH_ORDERS_BY_NAME_FAILURE = "FETCH_ORDERS_BY_NAME_FAILURE";

