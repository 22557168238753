import { call, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_ADDRESS_FAIL,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  GETALL_ADDRESSES_FAIL,
  GETALL_ADDRESSES_REQUEST,
  GETALL_ADDRESSES_SUCCESS,
  SETDEFAULT_REQUEST,
  SET_DEFAULT_FAIL,
  SET_DEFAULT_SUCCESS,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
} from "./ActionTypes";
import { deleteCall, getCall, putCall } from "../../apihelper/ApiHelper";
import {
  DELETE_ADDRESS,
  GETALL_BILLINGADDRESS,
  SETDEFAULT_ADDRESS,
  UPDATE_ADDRESS,
} from "../../constants/ApiEndPoints";

export function* updateAddress(action) {
  try {
    const response = yield call(putCall, UPDATE_ADDRESS, action.payload);

    yield put({ type: UPDATE_ADDRESS_SUCCESS, payload: response.message });
  } catch (error) {
    yield put({ type: UPDATE_ADDRESS_FAIL, payload: error });
  }
}

export function* setDefautAddress(action) {
  try {
    const response = yield call(
      putCall,
      `${SETDEFAULT_ADDRESS}?id=${action.payload}`
    );
    yield put({ type: SET_DEFAULT_SUCCESS, payload: response.message });
  } catch (error) {
    yield put({ type: SET_DEFAULT_FAIL, payload: error });
  }
}

export function* getAllAddresses() {
  try {
    const response = yield call(getCall, GETALL_BILLINGADDRESS);
    yield put({ type: GETALL_ADDRESSES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GETALL_ADDRESSES_FAIL, payload: error });
  }
}

export function* deleteAddress(action) {
  try {
    const response = yield call(
      deleteCall,
      `${DELETE_ADDRESS}?id=${action.payload}`
    );
    yield put({ type: DELETE_ADDRESS_SUCCESS, payload: response.message });
  } catch (error) {
    yield put({ type: DELETE_ADDRESS_FAIL, payload: error });
  }
}
function* MyAccountSaga() {
  yield takeEvery(UPDATE_ADDRESS_REQUEST, updateAddress);
  yield takeEvery(SETDEFAULT_REQUEST, setDefautAddress);
  yield takeEvery(GETALL_ADDRESSES_REQUEST, getAllAddresses);
  yield takeEvery(DELETE_ADDRESS_REQUEST, deleteAddress);
}

export default MyAccountSaga;
