import { call, put, takeEvery } from "redux-saga/effects";
import {
  CHECKOUT_SESSION_CREATION,
  CHECKOUT_SESSION_CREATION_FAIL,
  CHECKOUT_SESSION_CREATION_SUCCESS,
  CREATE_SUBSCRIPTION_FAIL,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESSFULL,
  GET_BIILLINGDETAILS,
  GET_BIILLINGDETAILS_FAIL,
  GET_BIILLINGDETAILS_SUCCESS,
  GET_COUNTRYREQUEST_FAIL,
  GET_COUNTRYREQUEST_SUCCESS,
  GET_MODEOFDELIVERYSTATUS,
  GET_MODEOFDELIVERYSTATUS_SUCCESS,
  GET_STATEREQUEST,
  GET_STATEREQUEST_FAIL,
  GET_STATEREQUEST_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
  GET_USERDETAIL,
  GET_USERDETAIL_FAIL,
  GET_USERDETAIL_SUCCESS,
  SAVE_BILLINGINFO,
  SAVE_BILLINGINFO_FAIL,
  SAVE_BILLINGINFO_SUCCESS,
} from "./ActionTypes";
import { getCall, postCall } from "../../apihelper/ApiHelper";
import {
  CHECKOUT_ENDPOINT,
  CREATE_SUBSCRIPTION,
  GETUSERDETAILS,
  GET_BILLINGINFO,
  GET_COUNTRY,
  GET_STATE,
  GET_STATUS,
  MODEOFDELIVERYSTATUS,
  SAVE_BILLINGINFORMATION,
} from "../../constants/ApiEndPoints";

function* getUserDetails() {
  try {
    const response = yield call(getCall, GETUSERDETAILS);
    yield put({ type: GET_USERDETAIL_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_USERDETAIL_FAIL, payload: error.message });
  }
}

function* saveBillingInfo(action) {
  try {
    const response = yield call(
      postCall,
      SAVE_BILLINGINFORMATION,
      action.payload
    );
    yield put({ type: SAVE_BILLINGINFO_SUCCESS, payload: response.message });
  } catch (error) {
    yield put({ type: SAVE_BILLINGINFO_FAIL, payload: error });
  }
}

function* getstateList(action) {
  try {
    const response = yield call(
      getCall,
      `${GET_STATE}?country=${action.payload}`
    );
    yield put({ type: GET_STATEREQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_STATEREQUEST_FAIL, payload: error.message });
  }
}

function* getBillingDetails() {
  try {
    const response = yield call(getCall, GET_BILLINGINFO);
    yield put({ type: GET_BIILLINGDETAILS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_BIILLINGDETAILS_FAIL, payload: error });
  }
}

function* getCountryList() {
  try {
    const response = yield call(getCall, GET_COUNTRY);
    yield put({ type: GET_COUNTRYREQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_COUNTRYREQUEST_FAIL, payload: error.message });
  }
}

function* createCheckoutSession(action) {
  try {
    const response = yield call(
      postCall,
      `${CHECKOUT_ENDPOINT}?uId=${action.payload}`
    );
    yield put({ type: CHECKOUT_SESSION_CREATION_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: CHECKOUT_SESSION_CREATION_FAIL, error: error });
  }
}

function* getStatus(action) {
  try {
    const response = yield call(
      getCall,
      `${GET_STATUS}?uId=${action.payload}`,
      action.payload
    );
    yield put({ type: GET_STATUS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_STATUS_FAIL, payload: error });
  }
}

function* createSubscription(action) {
  try {
    const response = yield call(postCall, CREATE_SUBSCRIPTION, action.payload);
    yield put({
      type: CREATE_SUBSCRIPTION_SUCCESSFULL,
      payload: response.message,
    });
  } catch (error) {
    yield put({ type: CREATE_SUBSCRIPTION_FAIL, payload: error });
  }
}

function* getModeOfDeliveryStatus() {
  try {
    const response = yield call(getCall, MODEOFDELIVERYSTATUS);
    yield put({ type: GET_MODEOFDELIVERYSTATUS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_MODEOFDELIVERYSTATUS_SUCCESS, payload: error });
  }
}

function* BillingInfoSaga() {
  yield takeEvery(GET_USERDETAIL, getUserDetails);
  yield takeEvery(SAVE_BILLINGINFO, saveBillingInfo);
  yield takeEvery(GET_STATEREQUEST, getstateList);
  yield takeEvery(GET_COUNTRY, getCountryList);
  yield takeEvery(GET_BIILLINGDETAILS, getBillingDetails);
  yield takeEvery(CHECKOUT_SESSION_CREATION, createCheckoutSession);
  yield takeEvery(GET_STATUS_REQUEST, getStatus);
  yield takeEvery(CREATE_SUBSCRIPTION_REQUEST, createSubscription);
  yield takeEvery(GET_MODEOFDELIVERYSTATUS, getModeOfDeliveryStatus);
}

export default BillingInfoSaga;
