export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAIL = "UPDATE_ADDRESS_FAIL";
export const SETDEFAULT_REQUEST = "SETDEFAULT_REQUEST";
export const SET_DEFAULT_SUCCESS = "SET_DEFAULT_SUCCESS";
export const SET_DEFAULT_FAIL = "SET_DEFAULT_FAIL";
export const GETALL_ADDRESSES_REQUEST = "GETALL_ADDRESSES_REQUEST";
export const GETALL_ADDRESSES_SUCCESS = "GETALL_ADDRESSES_SUCCESS";
export const GETALL_ADDRESSES_FAIL = "GETALL_ADDRESSES_FAIL";
export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAIL = "DELETE_ADDRESS_FAIL";
