import {
  CONTACTUSFAILURE,
  CONTACTUSREQUEST,
  CONTACTUSSUCCESS,
} from "./ActionType";

const initialState = {
  error: null,
  loading: false,
  response: "",
};

 const ContactUsReducer = (state = initialState, action=action) => {
  switch (action.type) {
    case CONTACTUSREQUEST:
      return { ...state, loading: true,error:null };
    case CONTACTUSSUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        response: action.payload,
      };
    case CONTACTUSFAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        response: action.payload,
      };
    default:
      return state ;
  }
};
export default ContactUsReducer;