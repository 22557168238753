import React, { useEffect, useState } from "react";
import "./modeofdelivery.css";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_DELIVERYDATA_REQUEST,
  DELETE_DELIVERYDATA_REQUEST_FAIL,
  DELETE_DELIVERYDATA_REQUEST_SUCCESS,
  GET_DELIVERYDETAILS_REQUEST,
  GET_DELIVERYDETAILS_REQUEST_SUCCESS,
  GET_HEADING_DETAILS_REQUEST,
  GET_SERVERLIST_REQUEST,
  SAVE_DELIVERYDATA_REQUEST,
  SAVE_DELIVERYDATA_REQUEST_FAIL,
  SAVE_DELIVERYDATA_REQUEST_SUCCESS,
  UPDATE_DELIVERYDATA_REQUEST,
  UPDATE_DELIVERYDATA_REQUEST_FAIL,
  UPDATE_DELIVERYDATA_REQUEST_SUCCESS,  
} from "../../store/modeofdelivery/ActionTYpes";
import { validateMessage } from "../../validationhelper/ValidationHelper";
import { VscPassFilled } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

export default function ModeOfDelivery() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const serverList = useSelector((state) => state.deliveryMode?.serverList);
  const [showPassword, setShowPassword] = useState(false);
  const deliveryDetails = useSelector((state) => state.deliveryMode?.deliveriDetails);
  const airbyteMessage = useSelector((state) => state.deliveryMode?.airbyteMessage);
  const error = useSelector((state) => state.deliveryMode?.error);
  const [visibleTime, setVisibleTime] = useState(true);
  const location = useLocation();
  const message = useSelector((state) => state.deliveryMode?.message);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.deliveryMode?.loading);  
  const deliveryLoader = useSelector((state) => state.deliveryMode?.deliveryLoader);  
  const headingList = useSelector((state) => state.deliveryMode?.headingList);  
  const [isAitbyteBtn, setIsAirbyteBtn] = useState(false);
  const [isUpdatePress, setIsUpdatePress] = useState(false);
  const [similarInputs,setSimilarInputs]=useState("");
  const [prevSimilarData,setPrevSimilarData]=useState("");
  const [currDB,setCurrDB]=useState("");
  const [files, setFiles] = useState([]);  
  const navigate=useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const uId = searchParams.get("orderId");

  useEffect(() => {
    setTimeout(() => {
      setVisibleTime(false);
    }, 4000);
  });
  useEffect(()=>{
    dispatch({type:GET_HEADING_DETAILS_REQUEST})
  },[])
  
  useEffect(() => {
    dispatch({ type: GET_DELIVERYDETAILS_REQUEST, payload:uId });
  }, [ airbyteMessage,uId]);


  useEffect(()=>{
    if(validateMessage(deliveryDetails)){
      setDatabaseInputs(deliveryDetails);
    }
  },[deliveryDetails]);


  useEffect(() => {
    dispatch({ type: GET_SERVERLIST_REQUEST });
  },[]);

  useEffect(()=>{
    if(message && message.message){
      setSuccessMessage(message.message);
    }
    if(message && !message.message){
      if(message==="Data saved on clicking proceed button"){
        dispatch({type:SAVE_DELIVERYDATA_REQUEST_SUCCESS,payload:null})
        window.location.href='/home';
      }else{
        setSuccessMessage(message);
      }
    }
    if(error && error.message==='false'){  
      dispatch({type:GET_DELIVERYDETAILS_REQUEST_SUCCESS,payload:null})
    }
    if(error && !error.message.startsWith("Unexpected end of JSON input") && error.message!=="false"){
      setErrorMessage(error.message);
    }
  },[message,error]);

  useEffect(()=>{
    handleSimilarNameOfDB(currDB,true);
  },[currDB]);

  useEffect(()=>{
    handleSimilarNameOfDB(prevSimilarData,false);
  },[prevSimilarData]);

  const initialInputModal = {
    accesskey: null,
    secretkey: null,
    bucketname: null,
    bucketregion: null,
    lakeformationdatabase: null,
    host: null,
    port: null,
    dbname: null,
    username: null,
    password: null,
    projectid: null,
    datasetlocation: null,
    jsonkeyfile: null,
    datasetid: null,
    warehousename: null,
    role: null,
    schema: null,
    apikey: null,
    readername: null,
    sid:null,
    clusterurl:null,
    protocol:null,
  };
  
  const resetValues = {
    databaseName: "",
    inputs: [],
    orderId: null,
    additionalData: "",
    filesData: [],
    inputsModal: initialInputModal,
  };
  
const [databaseInputs,setDatabaseInputs]=useState({
    databaseName: "",
    orderId: null,
    subscriptionId:"",
    additionalData: null,
    filesData:[],
    inputsModal: initialInputModal,
})


const inputDetailsValidation = (inputsToVerify,details) => {
  let val = true;
  for (const field in details) {
    if (inputsToVerify.includes(field) && field !== 'password') {
      const isValidField = validateMessage(details[field]);
      if (!isValidField) {
        val = false;
        break;
      }
    }
  }
  return val;
};


async function processFiles(updatedFilesData) {
  return Promise.all(
    updatedFilesData.map(async (file) => {
      if (file instanceof Blob) {
        const base64 = await convertToBase64(file);
        return { name: file.name, base64 };
      }      
    })
  );
}



const handleFileInputChange = async (e, addOrUpload) => {
  const newFiles = e.target.files;
  const newFilesArray = Array.from(newFiles);

  const validFiles = newFilesArray.filter((file) => file instanceof Blob);

  let updatedFilesData = [];

  if (addOrUpload === 'Add') {
    const uniqueFiles = validFiles.filter((file) => {
      return !files.find((existingFile) => existingFile.name === file.name);
    });

    const updatedFiles = [...files, ...uniqueFiles];
    setFiles(updatedFiles);

    updatedFilesData = await processFiles(updatedFiles);

    const updatedAddDetails = { ...databaseInputs };
    updatedAddDetails.filesData = [...databaseInputs.filesData, ...updatedFilesData.filter(Boolean)];
    setDatabaseInputs(updatedAddDetails);
  } else if (addOrUpload === 'Update') {
    const newUniqueFiles = validFiles.filter((file) => {
      return !databaseInputs.filesData.find(
        (existingFile) => existingFile.name === file.name
      );
    });

    const updatedFiles = [...databaseInputs.filesData, ...newUniqueFiles];
    setDatabaseInputs({ ...databaseInputs, filesData: updatedFiles });

    updatedFilesData = await processFiles(updatedFiles);

    const updatedUpdateDeliveryInfo = { ...databaseInputs };
    updatedUpdateDeliveryInfo.filesData = [...databaseInputs.filesData, ...updatedFilesData.filter(Boolean)];
    setDatabaseInputs(updatedUpdateDeliveryInfo);
  }
};


const removeFile = (fileToRemove, addOrUpload) => {
  if (addOrUpload === 'Add') {
    const updatedFiles = files.filter((file) => file !== fileToRemove);
    setFiles(updatedFiles);

    const updatedFilesData = databaseInputs.filesData.filter(
      (fileData) => fileData.name !== fileToRemove.name
    );

    const updatedAddDetails = { ...databaseInputs };
    updatedAddDetails.filesData = updatedFilesData;
    setDatabaseInputs(updatedAddDetails);
  } else if (addOrUpload === 'Update') {
    const updatedFilesData = databaseInputs.filesData.filter(
      (fileData) => fileData.name !== fileToRemove.name
    );

    const updatedUpdateDeliveryInfo = { ...databaseInputs };
    updatedUpdateDeliveryInfo.filesData = updatedFilesData;
    setDatabaseInputs(updatedUpdateDeliveryInfo);
  }
};


const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (errorData) => reject(errorData);
  });
}; 
  
  const handleDeliveryInputChange = (value) => {
    setCurrDB(value);
    setSimilarInputs(currDB);
    setIsAirbyteBtn(false);
    setFiles([]);
    setDatabaseInputs({ ...resetValues, databaseName: value });
  };
  
  const handleDeliveryUpdateChange = (value) => {
    setPrevSimilarData(value);    
    setIsUpdatePress(false);
    setDatabaseInputs({ ...resetValues, databaseName: value });
  };

  const handleDeleteOnClick = () => {
    dispatch({ type: DELETE_DELIVERYDATA_REQUEST,payload:databaseInputs.subscriptionId });
  };


  const handleSaveOnClickAirbyte = () => {
    setIsAirbyteBtn(true);
    if(inputDetailsValidation(similarInputs,databaseInputs.inputsModal) && databaseInputs.databaseName!==""){
      dispatch({ type: SAVE_DELIVERYDATA_REQUEST, payload: {...databaseInputs,orderId:uId,email:false}});  
    }
  };
  
  

  const handleProceedOnClick = () => {
    dispatch({ type: SAVE_DELIVERYDATA_REQUEST, payload: ({...databaseInputs,orderId:uId,email:true}) });
  }

  const handleEditOnClick=()=>{
    setDatabaseInputs(deliveryDetails)
    setPrevSimilarData(deliveryDetails.databaseName);
    setFiles(deliveryDetails.filesData);
  }
  
  const handleUpdateOnClick = () => {
    setIsUpdatePress(true);
    if(databaseInputs.databaseName!=="" && inputDetailsValidation(prevSimilarData,databaseInputs.inputsModal)){
      dispatch({ type: UPDATE_DELIVERYDATA_REQUEST, payload: ({...databaseInputs,orderId:uId,}) });
    }
  };

  const handleCallBack = () => {
    setErrorMessage("");
    setSuccessMessage("");
    if(successMessage==="Delivery data saved successfully"){
      setSimilarInputs("");
      setCurrDB("");
      setSimilarInputs(currDB);    
      dispatch({type:SAVE_DELIVERYDATA_REQUEST_SUCCESS,payload:null});      
      dispatch({type:GET_DELIVERYDETAILS_REQUEST,payload:uId});
    }
    if(successMessage==='Delivery details deleted successfully'){
      setIsAirbyteBtn(false);
      setCurrDB("");
      setSimilarInputs("");
      setFiles([]);
      setDatabaseInputs(resetValues);
      setDatabaseInputs(resetValues);
      dispatch({type:DELETE_DELIVERYDATA_REQUEST_SUCCESS,payload:null});
      dispatch({type:GET_DELIVERYDETAILS_REQUEST,payload:uId});      
    }
    if(error==="Invalid OrderId"){
      navigate('/home');
    }
    dispatch({type:DELETE_DELIVERYDATA_REQUEST_FAIL,payload:null})
    dispatch({type:UPDATE_DELIVERYDATA_REQUEST_SUCCESS,payload:null});
    dispatch({type:SAVE_DELIVERYDATA_REQUEST_FAIL, payload:null});
    dispatch({type:UPDATE_DELIVERYDATA_REQUEST_FAIL, payload:null});
  };

  const handleSimilarNameOfDB=(dbselected,isSimilarInput)=>{
    for(let i=0;i<serverList?.length;i++){
      if(serverList[i].serverName===dbselected && isSimilarInput){
        setSimilarInputs(serverList[i].similarData);
      }
      else if(serverList[i].serverName===dbselected && !isSimilarInput){
        setPrevSimilarData(serverList[i].similarData);
      }
    }
  }
  function isInvalidChecker(btnPress,data){
    return !validateMessage(data) && btnPress?'is-invalid':"";
  }
  function isloaderActive(loader1,loader2){
    return loader1 || loader2;
  }
  function serverDetailsLengthCheck(data){    
    return validateMessage(data) && data.length>0;
  }
  function passwordVisibility(passcode){
    return passcode?"text" : "password";
  }
  function passwordIconType(passcode){
    return !passcode?<BsEyeSlashFill /> : <BsEyeFill />;
  }
  function serverListData(data){
    return serverDetailsLengthCheck(data) ? (
      data.map((server, index) => (
        <option id="as-mode-od-delivery" key={index} value={String(server.serverName)}>
          {String(server.serverName)}
        </option>
      ))
    ) : (
      <option selected value={""}>
        No Destination Database Available
      </option>
    );
  }

  function showPreviousDatabaseInputs(headingAndInputData) {
    if (validateMessage(headingAndInputData) && validateMessage(databaseInputs) && validateMessage(databaseInputs.inputsModal)) {
        return headingAndInputData.map((detailData, idx) => {
            if (validateMessage(deliveryDetails.inputsModal[detailData.airbyteInput])) {
                return (
                    <div className="url-ipaddress col-md-4 mb-3" key={idx}>
                        <label className="form-label attachment-document">
                            {detailData.heading}<span className="text-danger">*</span>
                        </label>
                        <input
                            disabled
                            id="as-input-field"
                            className={'form-control'}
                            aria-describedby="emailHelp"
                            value={deliveryDetails.inputsModal[detailData.airbyteInput]}
                        />
                    </div>
                );
            }
            return null;
        });
    }
    return null;
}


    function addAndUpdateDetails(similarData, btnPressData, dataName, heading, metaData) {
      if (similarData.includes(metaData) && metaData!=='password') {
          return (
              <div className="url-ipaddress col-md-4" key={metaData}>
                  <label className="form-label">
                      {heading}
                      <span className="text-danger"> *</span>
                  </label>
                  <input id="as-input-field"
                      aria-describedby="emailHelp"
                      className={`form-control ${btnPressData && !validateMessage(dataName) ? "is-invalid" : ""}`}
                      value={dataName}
                      onChange={(e) => {setDatabaseInputs((prevDetails) => ({...prevDetails,inputsModal: { ...prevDetails.inputsModal, [metaData]: e.target.value },}))}
                      }
                  />
                  {btnPressData && !validateMessage(dataName) && (
                      <div className="invalid-feedback">
                          Please enter {heading} field.
                      </div>
                  )}
              </div>
          );
      }
      return null; 
    }

    function listOfDataInServerDatabase(headingAndInputData) {
      if (validateMessage(headingAndInputData)) {
          return headingAndInputData.map((detailData) => {
              return addAndUpdateDetails(similarInputs,isAitbyteBtn,databaseInputs.inputsModal[detailData.airbyteInput],detailData.heading,detailData.airbyteInput);
          });
      }
      return null; 
    }
    function listOfUpdateServerDatabase(headingAndInputData){
      if (validateMessage(headingAndInputData)) {
        return headingAndInputData.map((detailData) => {
            return addAndUpdateDetails(prevSimilarData,isUpdatePress,databaseInputs.inputsModal[detailData.airbyteInput],detailData.heading,detailData.airbyteInput);
        });
    }
    }

    function isModalValid(inputsToVerify,details){
      return inputDetailsValidation(inputsToVerify,details)?"modal":"";
    }

  return (
    <div className="modeOfDelivery-container">
      <SuccessPopUp
        loader={isloaderActive(loading,deliveryLoader)}
        successMessage={successMessage}
        errorMessage={errorMessage}
        callback={handleCallBack}
      />
      <div className="container">
        {visibleTime && (
          <div className="payment-success-msg">
            <div
              className=" payment-success-alert alert alert-success"
              role="alert"
            >
              <div className="alert-heading">
                <VscPassFilled className="payment-success-icon" />
              </div>
              <div className="alert-heading">
                <h5>Your Payment Recieved Successfully</h5>
                <h6>ThankYou For Shopping With Us</h6>
              </div>
            </div>
          </div>
        )}

        <div className="modeOfDelivery-heading  mb-5">
          <h1>Mode Of Delivery</h1>
          <p>Please select your desired option for accessing the taxonomy</p>
        </div>        

        <div className="delivery-details-container mb-5">

          {deliveryDetails === null && (
            <div>
              <div className="AirByteServer ">
                <div className="row gy-4 mb-5">
                  <div className="databaseName col-md-4">
                    <label className="form-label" htmlFor="as-database-name-dropdown">
                      Select Destination Database<span className="text-danger">*</span>
                    </label>
                    <select
                      id="as-database-name-dropdown"
                      className={`form-select ${isInvalidChecker(isAitbyteBtn,databaseInputs.databaseName)}`}
                      value={databaseInputs.databaseName}
                      onChange={(e) => handleDeliveryInputChange(e.target.value)}
                    >
                      <option selected disabled={validateMessage(databaseInputs.databaseName)} value="">
                        Please select database field.
                      </option>
                      {serverListData(serverList)}
                    </select>
                      <div className="invalid-feedback">
                        Please select Destination Database.
                      </div>
                  </div>
                  {listOfDataInServerDatabase(headingList)}
                  {similarInputs.includes("password") &&<div className="password mod-password col-md-4">
                    <label className="form-label">
                      Password
                    </label>                    
                    <input
                      type={`${passwordVisibility(showPassword)}`}
                      id="as-inputPassword5"
                      className='form-control'
                      aria-describedby="passwordHelpBlock"
                      value={databaseInputs.inputsModal.password}
                      onChange={(e) => setDatabaseInputs(prevDetails => ({...prevDetails,inputsModal: {...prevDetails.inputsModal,password: e.target.value}}))}    
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className={`mod-show-password`}
                      id="as-show-password"
                      onChange={(e) => setDatabaseInputs(prevDetails => ({...prevDetails,inputsModal: {...prevDetails.inputsModal,password: e.target.value}}))}
                    >
                      { passwordIconType(showPassword)}
                    </span>
                  </div>}


                 <div className="mb-3">
                    <label className="form-label">Note</label>
                    <textarea id="as-input-field" value={databaseInputs.additionalData} onChange={(e) => setDatabaseInputs(prevDetails =>  ({...prevDetails, additionalData:e.target.value}))}className="form-control col-md-12"></textarea >
                  </div>
                 

                  <div className="col-md-6">
                  <label className="form-label" >Attach Documents:</label>
                    <input value="" type="file" id="as-input-field" className="form-control" onChange={e=>handleFileInputChange(e,"Add")}/>
                    <ul>
                      {files && files.length>0 && files.map((file, index) => (
                        <li key={index} >
                          {file.name}
                          <button className="btn remove-files-btn" id="as-remove-files-btn" onClick={() => removeFile(file,"Add")}><TiDeleteOutline /></button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}


          {deliveryDetails === null && (
            <div className="mode-of-delivery-btn mt-4 ">
              <button
                className=" delivery-save-btn btn"
                id="as-delivery-save-btn"
                onClick={handleSaveOnClickAirbyte}
              >
                Save
              </button>
            </div>
          )}

          {validateMessage(deliveryDetails) && (
            <div className="secound-time-purchase-Airbyte-screen">
              <div className="row">
                  <div className="databaseName col-md-4 mb-3">
                    <label className="form-label attachment-document">
                      Select Destination Database<span className="text-danger">*</span>
                    </label>
                    <select
                    disabled
                    id="as-dropdown"
                    className={'form-control'}
                      value={deliveryDetails.databaseName}
                    >
                      {serverListData(serverList)}
                    </select>
                  </div>

                  {showPreviousDatabaseInputs(headingList)}

                 {validateMessage(deliveryDetails.additionalData)&&<div className="mb-3">
                    <label className="form-label attachment-document">Note</label>
                    <textarea id="as-input-field" value={deliveryDetails.additionalData} disabled 
                    className="form-control col-md-12">
                    </textarea >
                  </div>}
                 
                  {validateMessage(deliveryDetails.filesData)&& deliveryDetails.filesData.length>0&&<div className="col-md-12 mb-3">
                  <label className="form-label attachment-document" >Attached Documents:</label>
                    <ul>
                      {databaseInputs.filesData.map((file, index) => (
                        <li key={index} className="mb-2" >
                          {file.name}                         
                        </li>
                      ))}
                    </ul>
                  </div>}
                </div>
              
              {validateMessage(databaseInputs) && databaseInputs.deliveryStatus!=="Delivered" && <div className="airbyte-s-btns  mt-2">
                <div className="edit-delete">
                  <button
                    className="btn btn-link edit-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#as-ShowAirbyteUpdate"
                    id="as-edit-btn"
                    onClick={handleEditOnClick}
                  >
                    EDIT
                  </button>{" "}
                  <span className="mx-2">|</span>
                  <button
                    data-testId="delete-button"
                    className="btn btn-link delete-btn "
                    id="as-delete-btn"
                    onClick={handleDeleteOnClick}
                  >
                    DELETE
                  </button>
                </div>
                <div className="proceed">
                  <button
                    className="btn delivery-Proceed-btn btn-secondary"
                    id="as-proceed-btn"
                    onClick={handleProceedOnClick}
                  >
                    PROCEED
                  </button>
                </div>
              </div>}
            </div>
          )}
        </div>
        {validateMessage(databaseInputs) && (
          <div
            className="modal fade"
            id="as-ShowAirbyteUpdate"            
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static" data-bs-keyboard="false"
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content">
                <button
                  type="button"
                  className="closebutton"
                  data-bs-dismiss="modal"
                  data-bs-keyboard="false"
                  aria-label="Close"
                  id="as-close-modal"
                >
                  <span className="red-btn position-absolute top-0 start-100 translate-middle ">
                    &times;
                  </span>
                </button>
                <div className={`modal-body `}>
                  <div className="modal-delivery-Details">
                    <div className="modal-heading">Edit Destination Database</div>
                    <hr></hr>
                    <div className="AirByteServer ">
                      <div className="row gy-4 mb-5">                        
                        <div className="databaseName col-md-4">
                          <label
                            className="form-label"
                          >
                            Select Destination Database<span className="text-danger">*</span>
                          </label>
                          <select
                          id="as-dropdown"
                            className="form-select"
                            value={
                              deliveryDetails !== null
                                ? databaseInputs.databaseName
                                : ""
                            }
                            onChange={(e) => handleDeliveryUpdateChange(e.target.value)}
                          >
                             {serverListData(serverList)}
                          </select>
                        </div>
                  {listOfUpdateServerDatabase(headingList)}
                  {prevSimilarData.includes("password") &&<div className="password mod-password col-md-4">
                    <label className="form-label">
                      Password
                    </label>                    
                    <input
                      className='form-control'
                      onChange={(e) => setDatabaseInputs(prevDetails => ({...prevDetails,inputsModal: {...prevDetails.inputsModal,password: e.target.value}}))}    
                      id="as-inputPassword5"
                      type={`${passwordVisibility(showPassword)}`}
                      value={validateMessage(databaseInputs.inputsModal.password)?databaseInputs.inputsModal.password:""}
                      aria-describedby="passwordHelpBlock"
                    />
                    <span
                      onChange={(e) => setDatabaseInputs(prevDetails => ({...prevDetails,inputsModal: {...prevDetails.inputsModal,password: e.target.value}}))}
                      id="as-show-password"
                      className={`mod-show-password`}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {passwordIconType(showPassword)}
                    </span>
                  </div>}


                 <div>
                    <label className="form-label">Note</label>
                    <textarea id="as-input-field" value={databaseInputs.additionalData!=='null'?databaseInputs.additionalData:""} onChange={(e) => setDatabaseInputs(prevDetails => ({...prevDetails,additionalData: e.target.value}))} className="form-control col-md-12"></textarea >
                  </div>
                 

                 <div className="col-md-6">
                  <label className="form-label ">Attach Documents: </label>
                    <input value="" type="file" className="form-control" onChange={e=>handleFileInputChange(e,"Update")} />
                    <ul>
                      {databaseInputs.filesData.map((file, index) => (
                        <li key={index} >
                          {file.name}
                          <button className="btn remove-files-btn" id="as-remove-files-btn" onClick={() => removeFile(file,"Update")}><TiDeleteOutline /></button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                    </div>
                    <hr></hr>
                    <div className="update-cancle-btn">
                      <div className="update-cancle">
                        <button
                          className="modal-cancle-btn closebutton btn  mx-3"
                          type="button"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          id="as-modal-close"
                        >
                          Cancel
                        </button>
                        <button
                          className="modal-update-btn btn"
                          data-bs-dismiss={isModalValid(prevSimilarData,databaseInputs.inputsModal)}
                          onClick={handleUpdateOnClick}
                          id="as-update-btn"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}        
      </div>
    </div>
  );
}
