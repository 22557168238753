import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import errorIcon from "../enrichImages/error-icon.webp";
import successIcon from "../enrichImages/success-icon.webp";

const loaderStyle = {
  position: "fixed",
  zIndex: "1",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const spinnerStyle = { width: "4rem", height: "4rem" };

const SuccessPopUp = (props) => {
  const verifyData = (data) => {
    return data !== undefined && data !== null && data !== "";
  };

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSucessMessage] = useState(null);

  useEffect(() => {
    if (props.loader) {
      setError(false);
      setSuccess(false);
    }
    setLoader(props.loader);
  }, [props.loader]);

  useEffect(() => {
    if (verifyData(props.errorMessage)) {
      setError(true);
      setSuccess(false);
      setErrorMessage(props.errorMessage);
    }
  }, [props.errorMessage]);

  useEffect(() => {
    if (verifyData(props.successMessage)) {
      setError(false);
      setSuccess(true);
      setSucessMessage(props.successMessage);
    }
  }, [props.successMessage]);

  const hideErrorModel = () => {
    setErrorMessage(null);
    if (verifyData(props.callback)) {
      props.callback();
    }
    setError(false);
  };
  const hideSuccessModel = () => {
    setSucessMessage(null);
    if (verifyData(props.callback)) {
      props.callback();
    }
    setSuccess(false);
  };
  return (
    <React.Fragment>
      {" "}
      {loader ? (
        <div className=" " style={loaderStyle}>
          <br></br>
          <br></br>

          <Spinner style={spinnerStyle} className="m-4 " color="primary" />
        </div>
      ) : (
        ""
      )}
      <Modal
        size="md modal-dialog-centered popup-container-success"
        show={error}
        onHide={hideErrorModel}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          className="border-bottom-0 popup-close-button-div"
          onClick={() => hideErrorModel()}
        >
          <button className="popup-close-button">
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="react-right-icon-container">
            <img
              src={errorIcon}
              alt="error-icon-img"
              className="react-wrong-icon"
            />
          </div>
          <h3 className="text-center h3 modal-hdr-text text-content">Error!</h3>
          <p className="text-center modal-mid-text">{errorMessage}</p>
          <div
            className="text-center modal-btns"
            onClick={() => hideErrorModel()}
          >
            <button className="btn btn-danger modal-button-ok">OK</button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        keyboard={false}
        size="md modal-dialog-centered"
        show={success}
        onHide={() => hideSuccessModel()}
      >
        <Modal.Header
          className="border-bottom-0 popup-close-button-div"
          onClick={() => hideSuccessModel()}
        >
          <button className="popup-close-button">
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="react-right-icon-container">
            <img
              src={successIcon}
              alt="sucess-icon-img"
              className="react-right-icon"
            />
          </div>
          <h3 className="text-center h3 modal-hdr-text text-content">
            Success!
          </h3>
          <p className="text-center modal-mid-text">{successMessage}</p>
          <div
            className="text-center modal-btns"
            onClick={() => hideSuccessModel()}
          >
            <button className="btn btn-primary modal-button-ok">OK</button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SuccessPopUp;
