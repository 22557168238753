import { FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS } from './ActionType';


const initialState = {
    loading: false,
    response: "",
    error: false
}

 const ForgotPasswordReducer = (state = initialState, action=action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST: return { ...state, loading: true, error: false, }
        case FORGOT_PASSWORD_SUCCESS: return { ...state, loading: false, error: false, response: action.payload }
        case FORGOT_PASSWORD_FAILURE: return { ...state, loading: false, error: true, response: action.payload }
        default: return state
    }
};
export default ForgotPasswordReducer;
