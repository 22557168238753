import { call, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_DELIVERYDATA_REQUEST,
  DELETE_DELIVERYDATA_REQUEST_FAIL,
  DELETE_DELIVERYDATA_REQUEST_SUCCESS,
  DELETE_SNOWFLAKEDELIVERYDATA_REQUEST,
  DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
  DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
  GET_DELIVERYDETAILS_REQUEST,
  GET_DELIVERYDETAILS_REQUEST_FAIL,
  GET_DELIVERYDETAILS_REQUEST_SUCCESS,
  GET_HEADING_DETAILS_FAIL,
  GET_HEADING_DETAILS_REQUEST,
  GET_HEADING_DETAILS_SUCCESS,
  GET_SERVERLIST_REQUEST,
  GET_SERVERLIST_REQUEST_FAIL,
  GET_SERVERLIST_REQUEST_SUCCESS,
  GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_FAIL,
  GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_SUCCESS,
  SAVE_DELIVERYDATA_REQUEST,
  SAVE_DELIVERYDATA_REQUEST_FAIL,
  SAVE_DELIVERYDATA_REQUEST_SUCCESS,
  SAVE_SNOWFLAKEDELIVERYDATA_REQUEST,
  SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
  SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
  UPDATE_DELIVERYDATA_REQUEST,
  UPDATE_DELIVERYDATA_REQUEST_FAIL,
  UPDATE_DELIVERYDATA_REQUEST_SUCCESS,
  UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST,
  UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
  UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
} from "./ActionTYpes";
import {
  deleteCall,
  getCall,
  postCall,
  putCall,
} from "../../apihelper/ApiHelper";
import {
  DELETE_DELIVERYDETAILS,
  DELETE_SNOWFLAKEDELIVERYDETAILS,
  GET_DELIVERYDETAILS,
  GET_HEADING_AND_INPUTS,
  GET_SERVERLIST,
  GET_SNOWFLAKEDELIVERYDETAILS,
  SAVE_DELIVERYDATA,
  SAVE_SNOWFLAKEDELIVERYDATA,
  UPDATE_DELIVERYDETAILS,
  UPDATE_SNOWFLAKEDELIVERYDETAILS,
} from "../../constants/ApiEndPoints";

export function* saveDeliverydata(action) {
  try {
    const response = yield call(postCall, `${SAVE_DELIVERYDATA}?email=${action.payload.email}`, action.payload);
    yield put({
      type: SAVE_DELIVERYDATA_REQUEST_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    yield put({ type: SAVE_DELIVERYDATA_REQUEST_FAIL, payload: error });
  }
}

export function* getServerList() {
  try {
    const response = yield call(getCall, GET_SERVERLIST);
    yield put({
      type: GET_SERVERLIST_REQUEST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({ type: GET_SERVERLIST_REQUEST_FAIL, payload: error });
  }
}

export function* getDeliveryDetails(action) {
  try {
    const response = yield call(getCall, `${GET_DELIVERYDETAILS}?orderId=${action.payload}`);
    yield put({ type: GET_DELIVERYDETAILS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_DELIVERYDETAILS_REQUEST_FAIL, payload: error });
  }
}

export function* updateDeliveryDetails(action) {
  try {
    const response = yield call(
      putCall,
      UPDATE_DELIVERYDETAILS,
      action.payload
    );
    yield put({ type: UPDATE_DELIVERYDATA_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: UPDATE_DELIVERYDATA_REQUEST_FAIL, payload: error });
  }
}

export function* deleteDeliveryDetails(action) {
  try {
    const response = yield call(deleteCall, `${DELETE_DELIVERYDETAILS}?orderId=${action.payload}`);
    yield put({ type: DELETE_DELIVERYDATA_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: DELETE_DELIVERYDATA_REQUEST_FAIL, payload: error });
  }
}

export function* saveSnowflakeDeliverydata(action) {
  try {
    const response = yield call(
      postCall,
      SAVE_SNOWFLAKEDELIVERYDATA,
      action.payload
    );

    yield put({
      type: SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    yield put({
      type: SAVE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
      payload: error,
    });
  }
}

export function* getSnowflakeDeliveryDetails() {
  try {
    const response = yield call(getCall, GET_SNOWFLAKEDELIVERYDETAILS);
    yield put({
      type: GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: GET_SNOWFLAKEDELIVERYDETAILS_REQUEST_FAIL,
      payload: error,
    });
  }
}

export function* getHeadingData(){
  try{
    const response=yield call(getCall,GET_HEADING_AND_INPUTS);
    yield put({
      type:GET_HEADING_DETAILS_SUCCESS,
      payload:response,
    })
  }
  catch(error){
    yield put({
      type:GET_HEADING_DETAILS_FAIL,
      payload:[],
    })
  }
}

export function* updateSnowflakeDeliveryDetails(action) {
  try {
    const response = yield call(
      putCall,
      UPDATE_SNOWFLAKEDELIVERYDETAILS,
      action.payload
    );
    yield put({
      type: UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
      payload: error,
    });
  }
}

export function* deleteSnowflakeDeliveryDetails() {
  try {
    const response = yield call(deleteCall, DELETE_SNOWFLAKEDELIVERYDETAILS);
    yield put({
      type: DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: DELETE_SNOWFLAKEDELIVERYDATA_REQUEST_FAIL,
      payload: error,
    });
  }
}
function* DeliverySaga() {
  yield takeEvery(SAVE_DELIVERYDATA_REQUEST, saveDeliverydata);
  yield takeEvery(GET_SERVERLIST_REQUEST, getServerList);
  yield takeEvery(GET_DELIVERYDETAILS_REQUEST, getDeliveryDetails);
  yield takeEvery(UPDATE_DELIVERYDATA_REQUEST, updateDeliveryDetails);
  yield takeEvery(DELETE_DELIVERYDATA_REQUEST, deleteDeliveryDetails);
  yield takeEvery(GET_HEADING_DETAILS_REQUEST,getHeadingData);
  yield takeEvery(
    SAVE_SNOWFLAKEDELIVERYDATA_REQUEST,
    saveSnowflakeDeliverydata
  );
  yield takeEvery(GET_SNOWFLAKEDELIVERYDETAILS, getSnowflakeDeliveryDetails);
  yield takeEvery(
    UPDATE_SNOWFLAKEDELIVERYDATA_REQUEST,
    updateSnowflakeDeliveryDetails
  );
  yield takeEvery(
    DELETE_SNOWFLAKEDELIVERYDATA_REQUEST,
    deleteSnowflakeDeliveryDetails
  );
}

export default DeliverySaga;
