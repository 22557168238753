import { LINKEDINAUTHENTICATIONFAILURE, LINKEDINAUTHENTICATIONREQUEST, LINKEDINAUTHENTICATIONSUCCESS } from "./ActionType";



const initialState = {
    error: false,
    loading: false,
    response: "",
    callType: ""
};

 const LinkedInCallBackReducer = (state = initialState, action=action) => {
    switch (action.type) {
        case LINKEDINAUTHENTICATIONREQUEST:
            return { ...state, loading: true, }
        case LINKEDINAUTHENTICATIONSUCCESS:
            return { ...state, error: false, loading: false, response: action.payload };
        case LINKEDINAUTHENTICATIONFAILURE:
            return { ...state, error: true, loading: false, response: action.payload };
        default: return state;
    }
};
export default LinkedInCallBackReducer;