import { call, put, takeEvery } from "redux-saga/effects";
import { LINKEDINAUTHENTICATIONFAILURE, LINKEDINAUTHENTICATIONREQUEST, LINKEDINAUTHENTICATIONSUCCESS, } from "./ActionType";
import { LINKEDIN_CALLBACK_REQUST } from "../../../../constants/ApiEndPoints";
import { postCall } from "../../../../apihelper/ApiHelper";
import { validateMessage } from "../../../../validationhelper/ValidationHelper";


export function* linkedInExchangeToken(action) {
    const reqBody = {
        code: action.payload.code,
        redirectUrl: action.payload.redirectUrl
    }
    try {
        const response = yield call(postCall, `${LINKEDIN_CALLBACK_REQUST}${action.payload.callType}`, reqBody);
        if(validateMessage(response) && validateMessage(response.accessToken) && validateMessage(response.name)){
            localStorage.setItem("authUser", JSON.stringify({ JWT: response.accessToken, name: response.name }));
            window.location.href = "/home";
        }
        yield put({ type: LINKEDINAUTHENTICATIONSUCCESS, payload: response });
    } catch (error) {
        yield put({ type: LINKEDINAUTHENTICATIONFAILURE, payload: error });
    }
}




function* LinkedInExchangeTokenSaga() {
    yield takeEvery(LINKEDINAUTHENTICATIONREQUEST, linkedInExchangeToken);
}

export default LinkedInExchangeTokenSaga;