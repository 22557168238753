import React, { useEffect, useState } from "react";
import img from "../enrichImages/Screenshot 2023-09-18 182219.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_CART, FETCH_WISHLISTCART_COUNT } from "../../store/wishlistAndCart/ActionType";
import PopUp from "../PopUp/SuccessPopUp";

export default function EmptyCart() {
  document.title = "Cart - www.enrich.io";
  const cartProducts = useSelector((state) => state.wishlistcart?.cartItem);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: FETCH_WISHLISTCART_COUNT });
    dispatch({ type: FETCH_CART })
  }, []);



  useEffect(() => {
    if (cartProducts && cartProducts.length !== 0) {
      setLoading(false);
      navigate("/enrichCart");
    }
    if (cartProducts && cartProducts.length === 0) {
      setLoading(false);
    }
  }, [cartProducts]);

  return (
    <div className="empty-wishlistcart">
      <PopUp
        loader={loading}
      />
      <div className="container">
        <div className={`empty-wishlistcart-content row ${loading ? "invisible" : ""}`}>
          <div className="empty-wishlistcart-img col-md-6">
            <img src={img} alt="img"></img>
          </div>
          <div className="empty-wishlistcart-text text-start col-md-6">
            <p>Your Cart is Currently Empty. Add Products to Shop Now!</p>
            <h1>
              <Link className="empty-wishlistcart-link btn btn-link" id="as-shop-link" aria-current="page" to="/shop">
                Continue shopping!
              </Link>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
