import React, { useEffect, useState } from "react";
import "./myaccount.css";
import productImg from "../enrichImages/education-Degree.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_ADDRESS_REQUEST,
  GETALL_ADDRESSES_REQUEST,
  SETDEFAULT_REQUEST,
  SET_DEFAULT_SUCCESS,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
} from "../../store/myaccount/ActionTypes";
import {
  GET_STATEREQUEST,
  SAVE_BILLINGINFO,
  SAVE_BILLINGINFO_FAIL,
  SAVE_BILLINGINFO_SUCCESS,
} from "../../store/payment/ActionTypes";
import {
  validateEmail,
  validateMessage,
} from "../../validationhelper/ValidationHelper";
import { GET_COUNTRY } from "../../constants/ApiEndPoints";
import { GET_ALLORDERS_REQUEST } from "../../store/orders/ActionsTypes";
import EmptyMyAccount from "./EmptyMyAccount";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import SuccessPopUp from "../PopUp/SuccessPopUp";
import PhoneInput from "react-phone-input-2";
import { validatePhoneNumber } from "../../constants/ValidationConst";

export default function MyAccount() {
  const billingAddrsses = useSelector((state) => state.myAccount.allAddresses);
  const message = useSelector((state) => state.myAccount.message);
  const svaeMessage = useSelector((state) => state.paymentInfo.message);
  const stateList = useSelector((state) => state.paymentInfo.stateList);
  const error = useSelector((state) => state.myAccount?.emailDomainError);
  const errorSave = useSelector((state) => state.paymentInfo?.emailDomainError);
  const countryList = useSelector((state) => state.paymentInfo.countryList);
  const [pressBtn, setPressBtn] = useState(false);
  const orderList = useSelector((state) => state.orders.orderList);
  const [DefaultSelected, setDefaultSelected] = useState(false);
  const addadressMessage = useSelector((state) => state.paymentInfo?.message);
  const navigate = useNavigate();
  const loading = useSelector((state) => state.myAccount.loading);
  const payLoading = useSelector((state) => state.paymentInfo.loading);
  const updateloading = useSelector((state) => state.myAccount.updateloading);
  const getloading = useSelector((state) => state.myAccount.getLoading);
  const [idToDelete, setIdToDelete] = useState(null);
  const [isSavePress, setIsSavePress] = useState(false);  
  const [country,setCountry]=useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [userInfo, setUserInfo] = useState({
    id: null,
    organizationName: organizationName,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    country: "",
    city: "",
    state: "",
    zipCode: null,
    defaultAddress: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const [addUserInfo,setAddUserInfo]=useState({
    id: null,
    organizationName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    country: "",
    city: "",
    state: "",
    zipCode: null,
    defaultAddress: false,
  });

  const user = JSON.parse(localStorage.getItem("authUser"));  
  useEffect(() => {
    if (!validateMessage(user)) {
      navigate("/login");
    }
  },[]);
  useEffect(()=>{
    dispatch({type:GETALL_ADDRESSES_REQUEST});
  },[addadressMessage])

  useEffect(() => {
    dispatch({ type: GETALL_ADDRESSES_REQUEST });
    dispatch({ type: GET_COUNTRY });
    dispatch({ type: GET_ALLORDERS_REQUEST });
  }, []);

  useEffect(()=>{
    if(country){
      dispatch({ type: GET_STATEREQUEST, payload: country });
    }
  },[country])

  useEffect(() => {
    if (validateMessage(billingAddrsses) && billingAddrsses.length > 0) {
      setOrganizationName(billingAddrsses[0].organizationName);
    }
  }, [billingAddrsses]);

  useEffect(()=>{
    if(validateMessage(svaeMessage)){
      setSuccessMessage(svaeMessage);  
      dispatch({type:SAVE_BILLINGINFO_SUCCESS,payload:null})
    }    
  },[svaeMessage])

  useEffect(()=>{ 
    if(validateMessage(message)){
      setSuccessMessage(message);      
      dispatch({type:UPDATE_ADDRESS_SUCCESS,payload:null})
      dispatch({type:SET_DEFAULT_SUCCESS,payload:null})
    }
  },[message])

  useEffect(()=>{  
    if(validateMessage(error) && validateMessage(error.message)){
      setErrorMessage(error.message);
      dispatch({type:UPDATE_ADDRESS_FAIL,payload:null})
    }
  },[error])

  useEffect(()=>{  
    if(validateMessage(errorSave) && validateMessage(errorSave.message)){
      setErrorMessage(errorSave.message);
      dispatch({type:SAVE_BILLINGINFO_FAIL,payload:null})
    }
  },[errorSave])

  const changeHandler = (e) => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      country: e.target.value,
    }));
    dispatch({ type: GET_STATEREQUEST, payload: e.target.value });
  };

  const changeAddHandler = (e) => {
    setAddUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      country: e.target.value,
    }));
    dispatch({ type: GET_STATEREQUEST, payload: e.target.value });
  };

  const handleEditOnClick = (address) => {
    setPressBtn(false);
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      id: address.id,
      organizationName: address.organizationName,
      firstName: address.firstName,
      lastName: address.lastName,
      email: address.email,
      phoneNumber: address.phoneNumber,
      address: address.address,
      country:address.country,
      city: address.city,
      state:address.state,
      zipCode: address.zipCode,
      defaultAddress: address.defaultAddress,
    }));
    setCountry(address.country);
  };

  const handleDeleteOnClick = (id) => {
    setIdToDelete(null);
    dispatch({ type: DELETE_ADDRESS_REQUEST, payload: id });
    if(validateMessage(billingAddrsses) && billingAddrsses.length>0){
      setOrganizationName("");
    }
  };

  const handleSetDefaultOnClick = (id) => {
    dispatch({ type: SETDEFAULT_REQUEST, payload: id });
  };

const userValidation=(userDetail)=>{
  if( (validateMessage(organizationName) || validateMessage(userDetail.organizationName)) && validateMessage(userDetail.firstName) && validateMessage(userDetail.lastName) &&
validateMessage(userDetail.address) && validateMessage(userDetail.city) && validateMessage(userDetail.country) && validateMessage(userDetail.state) && validatePhoneNumber(userDetail.phoneNumber) && validateMessage(userDetail.email) && validateEmail(userDetail.email) && validateMessage(userDetail.zipCode)){
  return true;
}
return false;
}

  const handleSubmitOnClick = (userAddress) => {    
    setIsSavePress(true)
    if (userValidation(userAddress)) {      
      dispatch({ type: UPDATE_ADDRESS_REQUEST, payload: userAddress });
    }      
  };  

  const handleAddUserOnSubmit=(userAddress)=>{
    setIsSavePress(true)
    if(userValidation(userAddress)===true){
      if (pressBtn && DefaultSelected) {   
        if(validateMessage(organizationName)){
          userAddress.organizationName=organizationName;
        }
        userAddress.defaultAddress = true;
        userAddress.id = null;
        setIsSavePress(false);
        dispatch({ type: SAVE_BILLINGINFO, payload: userAddress });
      } else if (pressBtn && !DefaultSelected) {        
        if(validateMessage(organizationName)){
          userAddress.organizationName=organizationName;
        }
        userAddress.id = null;
        setIsSavePress(false);
        dispatch({ type: SAVE_BILLINGINFO, payload: userAddress });

      }else if (!pressBtn && DefaultSelected) {
        userAddress.defaultAddress = true;
        setIsSavePress(false);
        dispatch({ type: SAVE_BILLINGINFO, payload: userAddress });
    }
  }
}

  const handleCallBack = () => {
    setErrorMessage("");
    if(successMessage==="Your details has been submitted successfully"){
      setSuccessMessage("");
      window.location.href='/MyAccount'
    }
    if(successMessage==="Successfully Deleted"){
      setSuccessMessage("");
      dispatch({type:GETALL_ADDRESSES_REQUEST})
    }
    if(successMessage==="Successfully Set Default") {
      dispatch({type:GETALL_ADDRESSES_REQUEST})
    }
    setSuccessMessage("");
  };

  const handleAddOnClick = () => {
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      organizationName: organizationName,
    }));
    setPressBtn(true);
  };
  const handleCancelOnClick = () => {
    setIsSavePress(false);
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      id: null,
      organizationName:
        billingAddrsses.length > 0 ? billingAddrsses[0].organizationName : "",
      firstName: "",
      lastName: "",
      phoneNumber: null,
      email: "",
      address: "",
      country: "",
      city: "",
      state: "",
      zipCode: null,
      defaultAddress: false,
    }));
  };
  const handlePhonenumberOnChange = (event) => {
    const inputNumber = event;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      phoneNumber: inputNumber,
    }));
  };
  const handleAddPhonenumberOnChange = (event) => {
    const inputNumber = event;
    setAddUserInfo((prevInfo) => ({
      ...prevInfo,
      phoneNumber: inputNumber,
    }));
  };

  const formatDate = (date) => {
    const backendDate = new Date(date);
    const day = backendDate.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      backendDate
    );
    const year = backendDate.getFullYear();
    return `${day} ${month} ${year}`;
  };

  function isLoaderTrue(){
    return loading || updateloading || getloading || payLoading;
  }
  function isDataInValid(btnPress,data){
    return !validateMessage(data) && btnPress ?"is-invalid":"";
  }
  function isDataInValidForNumber(data,btnPress){
    return btnPress && !validatePhoneNumber(data)?"is-invalid":"";
  }

  function closeModal(data){
    return userValidation(data)?"modal":"";
  }
  function toggleDefaultSelected() {
    DefaultSelected ? setDefaultSelected(false) : setDefaultSelected(true);
  }
  function checkIsBillingDataIsEmpty(billingData){
    return validateMessage(billingData) && billingData.length<1?<EmptyMyAccount/>:"";
  }
  function isBillingAddressValid(billingAddrssesData){
    if(validateMessage(billingAddrssesData) && billingAddrssesData!==null){
    return billingAddrssesData.filter((address) => address.defaultAddress === true).map((address) => (
      <>
        <div className="default-address">
          <div className="card shadow">
            <div className="card-body">
              <h5 className="billing-name card-title default-details">
                {address.firstName}
              </h5>
              <p className="organisation-name card-text default-details">
                {address.organizationName}
              </p>
              <p className="billing-address card-text default-details">
                {address.address}
              </p>
              <p className="billing-phoneNumber card-text default-details">
                {address.phoneNumber}
              </p>
              <p className="billing-email card-text default-details">
                {address.email}
              </p>
              <div className="billing-edit-delete">
                <button
                  className="billing-edit btn btn-link "
                  onClick={() => handleEditOnClick(address)}
                  id="as-edit-address"
                  data-bs-toggle="modal"
                  data-bs-target="#editAddress"
                >
                  Edit
                </button>
                |
                <button
                  className="billing-delete btn btn-link"
                  onClick={() => setIdToDelete(address.id)}
                  id="as-delete-address"
                  data-bs-toggle="modal"
                  data-bs-target="#modelForDelete"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    ))}
  }

  function isNonDefaultBillingAddressValid(billingAddrssesData){
    if(validateMessage(billingAddrssesData)){
    return billingAddrssesData.filter((address) => address.defaultAddress === false).map((address) => (
      <div className="col-sm-6 card-parent-body">
        <>
          <div className="card shadow">
            <div className="card-body">
              <h5 className="billing-name card-title details">
                {address.firstName}
              </h5>
              <p className="organisation-name card-text details">
                {address.organizationName}
              </p>
              <p className="billing-address card-text details">
                {address.address}
              </p>
              <p className="billing-phoneNumber card-text details">
                Phone Number: {address.phoneNumber}
              </p>
              <p className="billing-email card-text details">
                Email: {address.email}
              </p>

              <div className="billing-edit-delete-setDefault">
                <button
                  type="button"
                  className="billing-edit btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#editAddress"
                  onClick={() => handleEditOnClick(address)}
                  id="as-edit-address"
                >
                  Edit
                </button>
                |
                <button
                  className="billing-delete btn btn-link"
                  onClick={() => setIdToDelete(address.id)}
                  data-bs-toggle="modal"
                  data-bs-target="#modelForDelete"
                  id="as-delete-address"
                >
                  Delete
                </button>
                |
                <button
                  className="billing-setDefault btn btn-link"
                  id="as-set-detault"
                  onClick={() =>
                    handleSetDefaultOnClick(address.id)                                  
                  }
                >
                  Set Default
                </button>
              </div>
            </div>
          </div>
        </>
      </div>
    ))}
  }

function optionNotCheck(btnPressData, data){
  return btnPressData && !validateMessage(data)? "option-not-check": "";
}
function listOfCountryState(listData,data){
  if(validateMessage(listData)){
    return listData.map((countrieOrState, index) => (
      <option selected={data===countrieOrState} value={countrieOrState} key={index}>{countrieOrState}</option>
    ))}
}

  return (
    <>      
      <SuccessPopUp loader={isLoaderTrue}  successMessage={successMessage } errorMessage={errorMessage}
       callback={handleCallBack}/>      
      <div
        class="modal fade modal-for-delete"
        id="modelForDelete"
        tabindex="-1"
        aria-labelledby="modelForDelete"
        aria-hidden="true"
        data-bs-backdrop="static"        
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                className="btn-close make-me-invisible"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="as-close-modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              <RxCrossCircled className="react-cross-icon " />
              <h2>Delete</h2>
              <p>Are you sure you want to delete this address?</p>
            </div>
            <div class="modal-footer buttons-for-delete-confirm">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="as-cancel-btn"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="as-confirm-btn"
                onClick={() => handleDeleteOnClick(idToDelete)}
              >
                Yes, Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="My-Account-container">
        <div className="container">
          <div className="account-heading">
            <div className="account-address-heading">Billing Address</div>
            <div className="Add-address-to-account ">
              <button
                className="btn btn-dark"
                data-bs-toggle="modal"
                data-bs-target="#Addaddress"
                id="as-add-address"
                onClick={() => handleAddOnClick()}
              >
                ADD ADDRESS
              </button>
            </div>
          </div>
          {checkIsBillingDataIsEmpty(billingAddrsses)}
          {isBillingAddressValid(billingAddrsses)}

          <div className="multiple-Addresses">
            <div className="row mt-3">
              {isNonDefaultBillingAddressValid(billingAddrsses)}
            </div>
          </div>

          <hr className="mt-5 mb-5"></hr>
          <div className="subcription-orders">
            {validateMessage(orderList) && (
              <div className="subscription-heading">Subscriptions</div>
            )}
            <div className="subscription-orders-container">
              {validateMessage(orderList) &&
                orderList.map((orderItem, index) => (
                  <div
                    className="wishlist-items OrderItem shadow mt-4 mb-5"
                    key={index}
                  >
                    <div className="wishlist-products order-products">
                      <div className="wishlist-item-left order-products-left">
                        <img
                          className="wishlist-product-img"
                          src={productImg}
                          alt="product image"
                        ></img>
                        <div className="order-product-default-details">
                          <div className="order-product-name">
                            {orderItem.productName}
                          </div>
                          <div className="order-product-deliveryDate">
                            <div className="order-highlight">Ordered On</div>
                            {formatDate(orderItem.orderDate)}
                          </div>
                        </div>
                      </div>

                      <div className="wishlist-item-right order-products-right">
                        <div className="wishlist-product-totalprice order-price">
                          $ {orderItem.price}{" "}
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="order-delivery-expiry-default-details">                      
                        <div className="order-Number text-truncate">                          
                          Order ID # {orderItem.orderId}
                        </div>
                        {orderItem.deliveryStatus === "Completed" && (
                          <div className="delivery-date">
                            <span className="not-symbol">|</span>
                            Delivery Date: {formatDate(orderItem.deliveryDate)}
                          </div>
                        )}
                        {orderItem.deliveryStatus === "Completed" && (
                          <div className="expire-date">
                            <span className="not-symbol">|</span>
                            Expiry Date: {formatDate(orderItem.expireDate)}
                          </div>
                        )}                      
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div
            className="modal modal-address-class fade"
            id="editAddress"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        data-bs-backdrop="static"
          >
            <div
              className="modal-dialog modal-xl  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Edit Address
                  </h5>
                  <button
                    type="button"
                    className="btn-close make-me-invisible"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="as-close-btn"
                    onClick={() => handleCancelOnClick()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body myaccount-modal">
                  <div className="row gy-4">
                    <div className="organization-name col-md-4">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Organization Name<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,userInfo.organizationName)}`}
                        disabled={validateMessage(userInfo.organizationName)}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={userInfo.organizationName}
                        onChange={(e) =>
                          setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            organizationName: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter Organization Name.
                        </div>
                    </div>
                    <div className="firstName col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        First Name<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,userInfo.firstName)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={userInfo.firstName}
                        onChange={(e) =>
                          setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            firstName: e.target.value,
                          }))
                        }
                      />
                      <div className="invalid-feedback">
                        Please Enter First Name.
                      </div>
                    </div>
                    <div className="lastName col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Last Name<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,userInfo.lastName)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={userInfo.lastName}
                        onChange={(e) =>
                          setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            lastName: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter Last Name.
                        </div>
                    </div>
                    <div className="phone-number col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Phone Number<span className="text-danger"> *</span>
                      </label>
                      <PhoneInput
                        className={`${isDataInValidForNumber(userInfo.phoneNumber,isSavePress)}`}
                        country={"us"}
                        id="as-phone-number"
                        value={userInfo.phoneNumber}
                        onChange={(event) => {
                          handlePhonenumberOnChange(event);
                        }}
                      />
                      <div className="invalid-feedback">
                        Please Enter Phone Number.
                      </div>
                    </div>
                    <div className="email col-md-4 col-sm-6">
                      <label for="as-exampleInputEmail1" className="form-label">
                        Email<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${isDataInValid(isSavePress,userInfo.email)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={userInfo.email}
                        onChange={(e) =>
                          setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            email: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter valid Email.
                        </div>
                    </div>
                    <div className="addressinfo col-md-8 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Address<span className="text-danger"> *</span>
                      </label>
                      <input
                        className={`form-control ${isDataInValid(isSavePress,userInfo.address)}`}
                        id="as-address"
                        value={userInfo.address}
                        onChange={(e) =>
                          setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            address: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter Address.
                        </div>
                    </div>
                    <div className="country col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Country<span className="text-danger"> *</span>
                      </label>
                      <select
                        className={`form-select  countrySelect ${optionNotCheck(isSavePress,userInfo.country)}`}
                        id="as-country"
                        value={userInfo.country}
                        onChange={(e) => changeHandler(e)}
                      >
                        {listOfCountryState(countryList,userInfo.country)}                        
                      </select>
                      {isSavePress && !validateMessage(userInfo.country) && (
                        <div className="manual-feedback">
                          Please Select Country.
                        </div>
                      )}
                    </div>                    
                    <div className="state col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        State<span className="text-danger"> *</span>
                      </label>
                      <select
                        className={`form-select selectpicker ${optionNotCheck(isSavePress,userInfo.state)}`}
                        id="as-state"
                        aria-label="Default select example"
                        value={userInfo.state}
                        data-live-search="true"
                        onChange={(e) =>
                          setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            state: e.target.value,
                          }))
                        }
                      >                    
                      {listOfCountryState(stateList,userInfo.state)}                        
                      </select>
                      {isSavePress && !validateMessage(userInfo.state) && (
                        <div className="manual-feedback">
                          Please Select State.
                        </div>
                      )}
                    </div>
                    <div className="city col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        City<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,userInfo.city)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={userInfo.city}
                        onChange={(e) =>
                          setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            city: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter City.
                        </div>
                    </div>
                    <div className="zipcode col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Zip Code<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,userInfo.zipCode)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={userInfo.zipCode}
                        onChange={(e) =>
                          setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            zipCode: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter Zip Code.
                        </div>
                    </div>
                  </div>
                </div>

                
                <div className="billing-edit-modal-footer modal-footer mt-4">
                  <div className="setdefualt-checkbox">
                    {!userInfo.defaultAddress && !pressBtn && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="as-flexCheckDefault"
                          onChange={toggleDefaultSelected}
                        />
                        <label
                          className="setDefault-lable form-check-label"
                          for="as-flexCheckDefault"
                        >
                          Set as Default
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="Cancle-submit-btns">
                    <button
                      type="button"
                      className="btn btn-dark cancle-edit-btn"
                      data-bs-dismiss="modal"
                      id="as-cancel-btn"
                      onClick={() => handleCancelOnClick()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-info submit-edit-btn"
                      onClick={() => handleSubmitOnClick(userInfo)}
                      id="as-submit-btn"
                      data-bs-dismiss={`${closeModal(userInfo)}`}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal modal-address-class fade"
            id="Addaddress"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
          >
            <div
              className="modal-dialog modal-xl  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Add Address
                  </h5>
                  <button
                    type="button"
                    className="btn-close make-me-invisible"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="as-cancel-btn"
                    onClick={() => handleCancelOnClick()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body myaccount-modal">
                  <div className="row gy-4">
                    <div className="organization-name col-md-4">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Organization Name<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        disabled={validateMessage(organizationName)}
                        className={`form-control ${
                          isSavePress && (!validateMessage(organizationName) && !validateMessage(addUserInfo.organizationName) )
                            ? "is-invalid"
                            : ""
                        }`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          (validateMessage(billingAddrsses) && billingAddrsses.length>0)
                            ? organizationName
                            : addUserInfo.organizationName
                        }
                        onChange={(e) =>
                          !validateMessage(billingAddrsses) || billingAddrsses.length===0
                            ? setAddUserInfo((prevUserInfo) => ({
                                ...prevUserInfo,
                                organizationName: e.target.value,
                              }))
                            : billingAddrsses[0].organisationName
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter Organization Name.
                        </div>
                    </div>
                    <div className="firstName col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        First Name<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,addUserInfo.firstName)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) =>
                          setAddUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            firstName: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter First Name.
                        </div>
                    </div>
                    <div className="lastName col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Last Name<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,addUserInfo.lastName)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) =>
                          setAddUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            lastName: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter Last Name.
                        </div>
                    </div>
                    <div className="phone-number col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Phone Number<span className="text-danger"> *</span>
                      </label>
                      <PhoneInput
                        className={`${isDataInValidForNumber(addUserInfo.phoneNumber,isSavePress)}`}
                        country={"us"}
                        id="as-country"
                        value={addUserInfo.phoneNumber}
                        onChange={(e) => handleAddPhonenumberOnChange(e)}
                      />
                      <div className="invalid-feedback">
                        Please Enter Phone Number.
                      </div>
                    </div>
                    <div className="email col-md-4 col-sm-6">
                      <label for="as-exampleInputEmail1" className="form-label">
                        Email<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,addUserInfo.email)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) =>
                          setAddUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            email: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter valid Email.
                        </div>
                    </div>
                    <div className="addressinfo col-md-8 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Address<span className="text-danger"> *</span>
                      </label>
                      <input
                      id="as-address"
                        className={`form-control ${isDataInValid(isSavePress,addUserInfo.address)}`}
                        onChange={(e) =>
                          setAddUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            address: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter Address.
                        </div>
                    </div>
                    <div className="country col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Country<span className="text-danger"> *</span>
                      </label>
                      <select
                      id="as-country"
                        className={`form-select  countrySelect ${optionNotCheck(isSavePress,userInfo.country)}`}
                        onChange={(e) => changeAddHandler(e)}
                      >
                        <option selected value={""}>Please Select Country</option>        
                        {listOfCountryState(countryList,addUserInfo.country)}
                      </select>
                      {isSavePress && !validateMessage(addUserInfo.country) && (
                        <div className="manual-feedback">
                          Please Select Country.
                        </div>
                      )}
                    </div>                    
                    <div className="state col-md-4 col-sm-6">
                      {" "}
                      <label for="exampleInputEmail1" className="form-label">
                        State<span className="text-danger"> *</span>
                      </label>
                      <select
                      id="as-state"
                        className={`form-select selectpicker ${optionNotCheck(isSavePress,addUserInfo.state)}`}
                        aria-label="Default select example"
                        data-live-search="true"
                        onChange={(e) =>
                          setAddUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            state: e.target.value,
                          }))
                        }
                      > 
                      <option value={""} selected>Please Select State</option>     
                        {listOfCountryState(stateList,addUserInfo.state)}
                      </select>
                      {isSavePress && !validateMessage(addUserInfo.state) && (
                        <div className="manual-feedback">
                          Please Select State.
                        </div>
                      )}
                    </div>
                    <div className="city col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        City<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,addUserInfo.city)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) =>
                          setAddUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            city: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter City.
                        </div>
                    </div>
                    <div className="zipcode col-md-4 col-sm-6">
                      {" "}
                      <label for="as-exampleInputEmail1" className="form-label">
                        Zip Code<span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${isDataInValid(isSavePress,addUserInfo.zipCode)}`}
                        id="as-exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) =>
                          setAddUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            zipCode: e.target.value,
                          }))
                        }
                      />
                        <div className="invalid-feedback">
                          Please Enter Zip Code.
                        </div>
                    </div>
                  </div>
                </div>
                <div className="billing-edit-modal-footer modal-footer mt-4">
                  <div className="setdefualt-checkbox">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="as-flexCheckDefault"
                        onChange={toggleDefaultSelected}
                      />
                      <label
                        className="setDefault-lable form-check-label"
                        for="as-flexCheckDefault"
                      >
                        Set as Default
                      </label>
                    </div>
                  </div>
                  <div className="Cancle-submit-btns">
                    <button
                      type="button"
                      id="as-cancel-btn"
                      className="btn btn-dark cancle-edit-btn"
                      data-bs-dismiss="modal"
                      onClick={() => handleCancelOnClick()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      id="as-submit-btn"
                      className="btn btn-info submit-edit-btn"
                      data-bs-dismiss={`${closeModal(addUserInfo)}`}
                      onClick={() => handleAddUserOnSubmit(addUserInfo)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
