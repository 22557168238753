import { call, put, takeEvery } from "redux-saga/effects";
import { postCall } from "../../../../apihelper/ApiHelper";
import { GOOGLE_CALLBACK_REQUST } from "../../../../constants/ApiEndPoints";
import { GOOGLEAUTHENTICATIONFAILURE, GOOGLEAUTHENTICATIONREQUEST, GOOGLEAUTHENTICATIONSUCCESS } from "./ActionType";
import { validateMessage } from "../../../../validationhelper/ValidationHelper";


export function* googleAndFacebookExchangeToken(action) {    

    const value = {
        accessToken: action.payload.accessToken,
        callType: action.payload.callType,
        source: action.payload.source
    }
    try {
        const response = yield call(postCall, `${GOOGLE_CALLBACK_REQUST}${action.payload.callType}`, value)
        if(validateMessage(response) && validateMessage(response.accessToken) && validateMessage(response.name)){             
            localStorage.setItem("authUser", JSON.stringify({ JWT: response.accessToken, name: response.name }));
            window.location.href='/home';
        }
        yield put({ type: GOOGLEAUTHENTICATIONSUCCESS, payload: response });
    } catch (error) {
        yield put({ type: GOOGLEAUTHENTICATIONFAILURE, payload: error });
    }
}


function* GoogleExchangeTokenSaga() {
    yield takeEvery(GOOGLEAUTHENTICATIONREQUEST, googleAndFacebookExchangeToken);
}

export default GoogleExchangeTokenSaga;