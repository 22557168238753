export const FETCH_HOTPRODUCT="FETCH_HOTPRODUCT"
export const FETCH_HOTPRODUCT_SUCCESS="FETCH_HOTPRODUCT_SUCCESS"
export const FETCH_HOTPRODUCT_FAIL="FETCH_HOTPRODUCT_FAIL"
export const FETCH_PRODUCT="FETCH_PRODUCT"
export const FETCH_PRODUCT_SUCCESS="FECTCH_PRODUCT_SUCCESS"
export const FETCH_PRODUCT_FAIL="FETCH_PRODUCT_FAIL"
export const RESET_PRODUCT="RESET_PRODUCT"
export const FETCH_ONEPRODUCT="FETCH_ONEPRODUCT"
export const FETCH_ONEPRODUCT_SUCCESS="FETCH_ONEPRODUCT_SUCCESS"
export const FETCH_ONEPRODUCT_FAIL="FETCH_ONEPRODUCT_FAIL"
export const FETCH_SAMPLE_DATA_REQUEST="FETCH_SAMPLE_DATA_REQUEST"
export const FETCH_SAMPLE_DATA_SUCCESS="FETCH_SAMPLE_DATA_SUCCESS"
export const FETCH_SAMPLE_DATA_FAILURE="FETCH_SAMPLE_DATA_FAILURE"