import React from "react";
import "./privacypage.css";

const PrivacyPage = () => {
  document.title = "Privacy Page - www.enrich.io";
  return (
    <div className="privacyPolicy">
      <div className="container">
        <div className="privacyPolicyContainer shadow">
          <div className="privacyPolicyHead">
            <h2>PRIVACY POLICY</h2>
            <p>
              This Privacy Policy outlines the practices of Enrich.io ("we,"
              "us," or "our") regarding the collection, use, disclosure, and
              protection of personal information when you use our website and
              services related to taxonomies. We are committed to safeguarding
              your privacy and ensuring the security of your personal
              information. By accessing or using our website and services, you
              consent to the practices described in this Privacy Policy.
            </p>
          </div>
          <div className="listOfPolicy">
            <div className="policy1 policies">
              <div className="heading">1. Information We Collect</div>
              <ul>
                <li className="list">
                  <span>1.1. Personal Information</span>
                  <p className="paragraphHead">
                    We may collect personal information when you interact with
                    our website and services. This may include, but is not
                    limited to:
                  </p>
                  <p>- Name</p>
                  <p>- Email address</p>
                  <p>- Contact information</p>
                  <p>- Billing information</p>
                  <p>- Any other information you voluntarily provide to us</p>
                </li>
                <li className="list">
                  <span>1.2. Non-Personal Information</span>
                  <p className="paragraphHead">
                    We also collect non-personal information automatically when
                    you visit out website, such as:
                  </p>
                  <p>- IP addresses</p>
                  <p>- Browser type</p>
                  <p>- Device information</p>
                  <p>- Usage data</p>
                  <p>- Cookies and similar tracking technologies</p>
                </li>
              </ul>
            </div>
            <div className="policy2 policies">
              <div className="heading">2. How we use your information</div>
              <ul>
                <li className="list">
                  <span>2.1. Providing Services</span>
                  <p className="paragraphHead">
                    We use your personal information to provide, maintain, and
                    improve our services related to taxonomies, including
                    processing orders, responding to inquiries, and sending
                    important updates.
                  </p>
                </li>
                <li className="list">
                  <span>2.2. Communication</span>
                  <p className="paragraphHead">
                    We may use your email address to send you transactional
                    messages, promotional offers, newsletters, and other
                    relevant information. You can opt-out of marketing
                    communications at any time.
                  </p>
                </li>
                <li className="list">
                  <span>2.3. Analytics</span>
                  <p className="paragraphHead">
                    We collect non-personal information for analytical purposes
                    to understand how our website and services are used. This
                    helps us improve the user experience and tailor our
                    offerings to your needs.
                  </p>
                </li>
              </ul>
            </div>
            <div className="policy3 policies">
              <div className="heading">3. Sharing Your Information</div>
              <ul>
                <li className="list">
                  <span>3.1. Service Providers</span>
                  <p className="paragraphHead">
                    We may share your personal information with trusted
                    third-party service providers who assist us in delivering
                    our services, such as payment processors, hosting providers,
                    and customer support.
                  </p>
                </li>
                <li className="list">
                  <span>3.2. Legal Compliance</span>
                  <p className="paragraphHead">
                    We may disclose your information to comply with applicable
                    laws, regulations, or legal processes, or to protect our
                    rights, privacy, safety, or property.
                  </p>
                </li>
                <li className="list">
                  <span>3.3. Business Transfers</span>
                  <p className="paragraphHead">
                    If we are involved in a merger, acquisition, or sale of all
                    or part of our assets, your information may be transferred
                    as part of the transaction. We will notify you via email or
                    a prominent notice on our website in such cases.
                  </p>
                </li>
              </ul>
            </div>
            <div className="policy4 policies">
              <div className="heading">Data Security</div>
              <p>
                We implement industry-standard security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, or destruction. However, please be aware that no
                method of transmission over the internet or electronic storage
                is completely secure, and we cannot guarantee absolute security.
              </p>
            </div>
            <div className="policy5 policies">
              <div className="heading">5. Your Choices.</div>
              <ul>
                <li className="list">
                  <span>5.1. Access and Control</span>
                  <p className="paragraphHead">
                    You may have the right to access, correct, or delete your
                    personal information. You can exercise these rights by
                    contacting us through the information provided below.
                  </p>
                </li>
                <li className="list">
                  <span>5.2. Cookies</span>
                  <p className="paragraphHead">
                    You can manage your cookie preferences through your browser
                    settings. Please note that disabling cookies may affect your
                    experience on our website.
                  </p>
                </li>
              </ul>
            </div>
            <div className="policy6 policies">
              <div className="heading">6. Changes to this Privacy Policy</div>
              <p className="paragraphHead">
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for legal reasons. We will notify
                you of any significant updates via email or by posting a
                prominent notice on our website.
              </p>
            </div>
            <div className="policy7 policies">
              <div className="heading">7. Contact Us</div>
              <p className="paragraphHead">
                If you have any questions, concerns, or requests regarding this
                Privacy Policy or our data practices, please contact us at:{" "}
                <a href="mailto:support@enrich.io" className="mailTo">
                  support@enrich.io
                </a>
              </p>
              <p className="paragraphHead">
                Please review this Privacy Policy regularly to stay informed
                about how we handle your personal information. Your continued
                use of our website and services constitutes your acceptance of
                any changes to this policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
