import { IMPERSONATE_FAILURE, IMPERSONATE_REQUEST, IMPERSONATE_SUCCESS, VERIFICATIONFAILURE, VERIFICATIONREQUEST, VERIFICATIONSUCCESS } from "./ActionType";



const initialState = {
    loading: false,
    response: "",
    error: false,
    impersonateResponse:null,
    impersonateError:null,
}

 const CodeVerificationReducer = (state = initialState, action=action) => {    
    switch (action.type) {
        case VERIFICATIONREQUEST: return { ...state, loading: true, error: false, }
        case VERIFICATIONSUCCESS: return { ...state, loading: false, error: false, response: action.payload }
        case VERIFICATIONFAILURE: return { ...state, loading: false, error: true, response: action.payload }
        case IMPERSONATE_REQUEST: return { ...state, loading: false }
        case IMPERSONATE_SUCCESS: return { ...state, loading: false, impersonateResponse: action.payload }
        case IMPERSONATE_FAILURE: return { ...state, loading: false, impersonateError:action.payload }
        default: return state
    }
};
export default CodeVerificationReducer;
