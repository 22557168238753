import {
  DELETE_ADDRESS_FAIL,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  GETALL_ADDRESSES_FAIL,
  GETALL_ADDRESSES_REQUEST,
  GETALL_ADDRESSES_SUCCESS,
  SETDEFAULT_REQUEST,
  SET_DEFAULT_FAIL,
  SET_DEFAULT_SUCCESS,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
} from "./ActionTypes";

const initialState = {
  message: null,
  error: null,
  allAddresses: null,
  updateloading: false,
  getLoading: false,
  emailDomainError: null,
  loading: false,
};

function method1(stateData){
  return {
    ...stateData,
    message: null,
    loading: true,
  };
}
function method2(stateData,payloadData){
  return {
    ...stateData,
    message: payloadData,
    loading: false,
  };
}

const MyAccountReducer = (state = initialState, action=action) => {
  switch (action.type) {
    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        updateloading: true,
        emailDomainError: null,
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        message: action.payload,
        updateloading: false,
      };
    case UPDATE_ADDRESS_FAIL:
      return {
        ...state,
        emailDomainError: action.payload,
        updateloading: false,
      };
    case SETDEFAULT_REQUEST:return method1(state);
    case SET_DEFAULT_SUCCESS:return method2(state,action.payload);
    case SET_DEFAULT_FAIL:
      return {
        ...state,
        error: action.payload,
        emailDomainError: action.payload,
        loading: false,
      };
    case GETALL_ADDRESSES_REQUEST:
      return {
        ...state,
        getLoading: true,
      };
    case GETALL_ADDRESSES_SUCCESS:
      return {
        ...state,
        allAddresses: action.payload,
        getLoading: false,
      };

    case GETALL_ADDRESSES_FAIL:
      return {
        ...state,
        error: action.payload,
        getLoading: false,
      };

    case DELETE_ADDRESS_REQUEST:return method1(state);

    case DELETE_ADDRESS_SUCCESS:return method2(state,action.payload);
    case DELETE_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default MyAccountReducer;